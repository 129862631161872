import React, { useState } from "react";
import _ from "lodash";
import Loader from "ui/components/Loader";
import { useQuery } from "@apollo/client";
import {
  Grid,
  List,
  ListItem,
  ListItemText,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow
} from "@mui/material";
import CurrentSalespersonParcelAssessmentQuery from "ui/sites/acquisitions/queries/CurrentSalespersonParcelAssessmentQuery.graphql";

function AcquisitionsParcelAssessmentContainer({ parcelId }) {
  const [selectedSection, setSelectedSection] = useState("ownerInfo");
  const { data, loading } = useQuery(CurrentSalespersonParcelAssessmentQuery, {
    variables: { parcelId }
  });
  if (loading) {
    return <Loader />;
  }
  if (!data.currentSalesperson?.parcel?.assessment) {
    // todo, better error view
    return <p>No assessment found for parcel.</p>;
  }

  const { assessment } = data.currentSalesperson.parcel;

  const fields = _.omit(_.get(assessment, selectedSection, {}), ["__typename"]);

  const sections = _.keys(_.omit(assessment, ["id", "__typename"]));

  const retrievedFields = _.map(fields, (value, key) => ({
    key,
    value
  }));
  const [unpopulatedFields, populatedFields] = _.partition(
    retrievedFields,
    ({ value }) => _.isEmpty(value)
  );

  return (
    <Grid container>
      <Grid item xs={2}>
        <List>
          {sections.map(slug => (
            <ListItem
              button
              key={slug}
              onClick={() => setSelectedSection(slug)}
              selected={slug === selectedSection}
            >
              <ListItemText primary={_.startCase(slug)} />
            </ListItem>
          ))}
        </List>
      </Grid>
      <Grid item xs={10}>
        <Paper elevation={1}>
          <TableContainer>
            <Table>
              <TableBody>
                {_.map(_.sortBy(populatedFields, "key"), ({ value, key }) => (
                  <TableRow key={key}>
                    <TableCell>
                      <b>{_.startCase(key)}</b>
                    </TableCell>
                    <TableCell>{value}</TableCell>
                  </TableRow>
                ))}
                {_.map(_.sortBy(unpopulatedFields, "key"), ({ value, key }) => (
                  <TableRow key={key}>
                    <TableCell>
                      <b>{_.startCase(key)}</b>
                    </TableCell>
                    <TableCell>{value}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Grid>
    </Grid>
  );
}

export default AcquisitionsParcelAssessmentContainer;
