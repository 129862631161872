import { createTheme } from "@mui/material/styles";
import palette from "./palette";

/**
 * This is our core theme. Better to start with it than jerry-rig it after.
 */

export const themeConfig = {
  themeName: "Alinea Portal",
  palette
};

export const theme = createTheme(themeConfig);

const AlineaTheme = {
  ...theme
};

export default AlineaTheme;
