import React from "react";
import _ from "lodash";
import { DateTime } from "luxon";
import PropTypes from "prop-types";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper
} from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles(() => ({
  table: { width: "100%" }
}));

function AcquisitionsInboundLeadsUploadsTable({
  uploads,
  paginationProps = null
}) {
  const classes = useStyles();
  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} size="small" data-testid="uploads-table">
        <TableHead>
          <TableRow>
            <TableCell>Filename</TableCell>
            <TableCell>Created</TableCell>
            <TableCell>Modified</TableCell>
            <TableCell>Lines</TableCell>
            <TableCell>Succeeded</TableCell>
            <TableCell>Failed</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {_.map(uploads, upload => (
            <TableRow key={upload.id}>
              <TableCell>{upload.filename}</TableCell>
              <TableCell>
                {DateTime.fromISO(upload.created).toLocaleString(
                  DateTime.DATETIME_MED
                )}
              </TableCell>
              <TableCell>
                {DateTime.fromISO(upload.modified).toLocaleString(
                  DateTime.DATETIME_MED
                )}
              </TableCell>
              <TableCell>{upload.lines}</TableCell>
              <TableCell>{upload.succeeded}</TableCell>
              <TableCell>{upload.failed}</TableCell>
            </TableRow>
          ))}
          {paginationProps && (
            <TableRow>
              <TablePagination {...paginationProps} />
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

AcquisitionsInboundLeadsUploadsTable.propTypes = {
  uploads: PropTypes.arrayOf(PropTypes.object),
  paginationProps: PropTypes.object
};

AcquisitionsInboundLeadsUploadsTable.defaultProps = {
  uploads: []
};

export default AcquisitionsInboundLeadsUploadsTable;
