import React from "react";
import _ from "lodash";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import Loader from "ui/components/Loader";
import ParcelCursorContext from "ui/sites/acquisitions/contexts/ParcelCursorContext";
import CurrentSalespersonParcelCursorByCursorIdQuery from "ui/sites/acquisitions/queries/CurrentSalespersonParcelCursorByCursorIdQuery.graphql";
import AcquisitionsParcelsPage from "ui/sites/acquisitions/pages/AcquisitionsParcelsPage";

function AcquisitionsParcelCursorPage() {
  const { cursorId } = useParams();
  const { data, loading } = useQuery(
    CurrentSalespersonParcelCursorByCursorIdQuery,
    {
      variables: { cursorId }
    }
  );

  if (loading) {
    return <Loader />;
  }

  // fixes include_delinquent, include_claimed issues
  const cursorData = _.mapKeys(
    JSON.parse(_.get(data, "currentSalesperson.parcelCursorByCursorId.data")),
    (v, k) => _.camelCase(k)
  );

  // fixes spacing issue with the json
  if (cursorData.polygon) {
    cursorData.polygon = JSON.stringify(JSON.parse(cursorData.polygon));
  }

  cursorData.defaultCenter = cursorData.defaultCenter || {
    lat: 33.749,
    lng: -84.388
  };

  return (
    <ParcelCursorContext.Provider value={{ cursorId, ...cursorData }}>
      <AcquisitionsParcelsPage
        polygon={cursorData.polygon}
        defaultZoom={cursorData.defaultZoom}
        defaultCenter={cursorData.defaultCenter}
      />
    </ParcelCursorContext.Provider>
  );
}

export default AcquisitionsParcelCursorPage;
