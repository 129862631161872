import React from "react";
import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";
import { TableCell, Link } from "@mui/material";

function NotificationExport({ notification, site }) {
  return (
    <>
      <TableCell>An export is ready.</TableCell>
      <TableCell align="right">
        <Link component={RouterLink} to={`/exports`} underline="hover">
          View Exports
        </Link>
      </TableCell>
    </>
  );
}

NotificationExport.propTypes = {
  notification: PropTypes.object,
  site: PropTypes.string
};

export default NotificationExport;
