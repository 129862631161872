import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography
} from "@mui/material";

function InboundLeadInfo({ parcel }) {
  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography align="center" variant="h4">
          Inbound Lead Details
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Paper elevation={1}>
          <TableContainer>
            <Table>
              <TableBody>
                {_.map(
                  [
                    "submitter",
                    "addressRaw",
                    "noteRaw",
                    "respondentName",
                    "respondentPhone"
                  ],
                  field => (
                    <TableRow key={field}>
                      <TableCell>
                        <b>{_.startCase(field).replace("Raw", "")}</b>
                      </TableCell>
                      <TableCell>
                        {_.get(parcel, `inboundlead.${field}`)}
                      </TableCell>
                    </TableRow>
                  )
                )}
                <TableRow></TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Grid>
    </Grid>
  );
}

InboundLeadInfo.propTypes = {
  parcel: PropTypes.object.isRequired
};

export default InboundLeadInfo;
