import React from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import { useMutation } from "@apollo/client";
import FlipPurchaseValueWorksheetCreateOrUpdateMutation from "ui/sites/underwriting/queries/FlipPurchaseValueWorksheetCreateOrUpdateMutation.graphql";
import CurrentUnderwriterParcelDetailsQuery from "ui/sites/underwriting/queries/CurrentUnderwriterParcelDetailsQuery.graphql";
import FlipPurchaseValueWorksheetForm from "ui/sites/underwriting/components/FlipPurchaseValueWorksheetForm";

function FlipPurchaseValueWorksheetContainer({ parcel }) {
  const [mutate, { loading }] = useMutation(
    FlipPurchaseValueWorksheetCreateOrUpdateMutation,
    {
      refetchQueries: [
        {
          query: CurrentUnderwriterParcelDetailsQuery,
          variables: { parcelId: parcel.id }
        }
      ]
    }
  );
  const worksheet = _.get(parcel, "currentRequest.flippurchasevalueworksheet", {
    // todo: populate defaults from other parts of the parcel?
  });
  async function onSubmit(worksheetValues) {
    await mutate({
      variables: {
        input: {
          parcelId: parcel.id,
          worksheetValues
        }
      }
    });
  }
  return (
    <FlipPurchaseValueWorksheetForm
      loading={loading}
      worksheet={worksheet}
      onSubmit={onSubmit}
    />
  );
}

FlipPurchaseValueWorksheetContainer.propTypes = {
  parcel: PropTypes.object.isRequired
};

export default FlipPurchaseValueWorksheetContainer;
