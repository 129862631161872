import React from "react";
import _ from "lodash";
import { Grid, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useQuery } from "@apollo/client";
import Loader from "ui/components/Loader";
import { withUnderwritingActions } from "ui/sites/underwriting/contexts/UnderwritingActionsContext";
import RequestsTable from "ui/sites/underwriting/components/RequestsTable";
import OpenUnderwritingRequestsQuery from "ui/sites/underwriting/queries/OpenUnderwritingRequestsQuery.graphql";

const useStyles = makeStyles(theme => ({
  wrapper: {
    padding: theme.spacing(6, 3)
  },
  tableWrapper: {
    marginTop: theme.spacing(5)
  }
}));

function UnderwritingOpenRequestsPage() {
  const classes = useStyles();

  const { data, loading } = useQuery(OpenUnderwritingRequestsQuery);
  if (loading) {
    return <Loader />;
  }

  const totalRequests = _.get(
    data,
    "currentUnderwriter.openUnderwritingRequests.totalCount",
    0
  );
  const requests = _.map(
    _.get(data, "currentUnderwriter.openUnderwritingRequests.edges", []),
    ({ node }) => ({ ...node, assignedUnderwriter: null })
  );

  return (
    <Grid
      item
      xs={12}
      container
      className={classes.wrapper}
      data-testid="underwriting-index-page"
    >
      <Grid item xs={12}>
        <Typography variant="h4" align="center">
          Open Underwriting Requests
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {totalRequests === 0 && (
          <Typography variant="h5" align="center">
            No open underwriting requests.
          </Typography>
        )}
        {totalRequests > 0 && (
          <RequestsTable
            requests={requests}
            withPortfolioManagement
            canDelete
          />
        )}
      </Grid>
    </Grid>
  );
}

export default withUnderwritingActions(UnderwritingOpenRequestsPage);
