import React, { useContext } from "react";
import PropTypes from "prop-types";
import { useMutation } from "@apollo/client";
import { Button } from "@mui/material";
import { Cancel } from "@mui/icons-material";
import NotificationsContext from "ui/contexts/NotificationsContext";
import actAndNotify from "ui/lib/actAndNotify";
import ParcelRemoveCategoryMutation from "ui/queries/ParcelRemoveCategoryMutation.graphql";
import CurrentUserCategoryParcelsQuery from "ui/queries/CurrentUserCategoryParcelsQuery.graphql";

function ParcelRemoveCategoryAction({ parcelId, categoryId }) {
  const { notify } = useContext(NotificationsContext);
  const [mutate] = useMutation(ParcelRemoveCategoryMutation, {
    variables: { categoryId, parcelId },
    refetchQueries: [
      { query: CurrentUserCategoryParcelsQuery, variables: { categoryId } }
    ]
  });

  return (
    <Button
      color="secondary"
      onClick={actAndNotify(mutate, "parcelRemoveCategory", {
        notify,
        mutateOptions: {
          variables: { categoryId, parcelId }
        },
        successMessage: "Removed Parcel from Category"
      })}
    >
      <Cancel />
    </Button>
  );
}

ParcelRemoveCategoryAction.propTypes = {
  parcelId: PropTypes.string,
  categoryId: PropTypes.string
};

export default ParcelRemoveCategoryAction;
