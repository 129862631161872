// palette definition wasn't behaving for some reason.
const palette = {
  primary: {
    main: "#3f51b5"
  },
  secondary: {
    main: "#f50057"
  }
};

export default palette;
