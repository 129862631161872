import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { DateTime } from "luxon";
import { IconButton, Grid, Link, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { GetApp, HighlightOff, Description } from "@mui/icons-material";
import actAndNotify from "ui/lib/actAndNotify";
import Loader from "ui/components/Loader";
import NotificationsContext from "ui/contexts/NotificationsContext";

const useStyles = makeStyles(theme => ({
  descriptionText: {
    fontSize: theme.typography.fontSize * 0.8
  },
  filenameText: {
    color: "rgba(0, 0, 0, 0.7)",
    fontSize: theme.typography.fontSize * 0.6,
    fontStyle: "italic"
  },
  img: {
    maxHeight: "10vh"
  },
  parcelDocument: {
    paddingBottom: theme.spacing(2),
    marginBottom: theme.spacing(2),
    borderBottom: "1px dashed #ccc"
  },
  docPreview: {
    fontSize: theme.typography.fontSize * 3
  }
}));

function ParcelDocument({ parcelDocument, parcelId, deleteMutation }) {
  const classes = useStyles();
  const { notify } = useContext(NotificationsContext);
  const [loading, setLoading] = useState(false);
  const { id: parcelDocumentId, s3Url, description, filename } = parcelDocument;

  if (loading) {
    return (
      <Grid item xs={12}>
        <Loader />
      </Grid>
    );
  }

  const deleteParcelDocument = async () => {
    setLoading(true);
    await actAndNotify(deleteMutation, "parcelDocumentDelete", {
      notify,
      mutateOptions: {
        variables: { parcelDocumentId },
        successMessage: `Successfully deleted file "${filename}"`
      }
    })();
    setLoading(false);
  };

  return (
    <Grid item xs={12} container className={classes.parcelDocument}>
      <Grid item xs={12} container>
        <Grid item xs={1}>
          <Link href={s3Url} target="_blank" underline="hover">
            <IconButton size="large">
              <GetApp />
            </IconButton>
          </Link>
        </Grid>
        <Grid item xs={1}>
          <IconButton onClick={deleteParcelDocument} size="large">
            <HighlightOff />
          </IconButton>
        </Grid>
        <Grid item xs={1}>
          <Description className={classes.docPreview} />
        </Grid>
        <Grid item xs={9} container>
          <Grid item xs={12}>
            <Typography className={classes.descriptionText}>
              {description}
            </Typography>
            <Typography className={classes.filenameText}>{filename}</Typography>
            <Typography className={classes.filenameText}>
              {`${DateTime.fromISO(
                parcelDocument.created
              ).toRelative()} by ${_.get(parcelDocument, "author.email", "?")}`}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

ParcelDocument.propTypes = {
  parcelId: PropTypes.string,
  parcelDocument: PropTypes.object
};

export default ParcelDocument;
