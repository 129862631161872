import React, { useContext } from "react";
import PropTypes from "prop-types";
import { useMutation } from "@apollo/client";
import { Button } from "@mui/material";
import { Cancel } from "@mui/icons-material";
import NotificationsContext from "ui/contexts/NotificationsContext";
import actAndNotify from "ui/lib/actAndNotify";
import ParcelRemoveFromPortfolioMutation from "ui/queries/ParcelRemoveFromPortfolioMutation.graphql";
import CurrentUserPortfolioParcelsQuery from "ui/queries/CurrentUserPortfolioParcelsQuery.graphql";

function ParcelRemoveFromPortfolioAction({ parcelId, portfolioId }) {
  const { notify } = useContext(NotificationsContext);
  const [mutate] = useMutation(ParcelRemoveFromPortfolioMutation, {
    variables: { portfolioId, parcelId },
    refetchQueries: [
      { query: CurrentUserPortfolioParcelsQuery, variables: { portfolioId } }
    ]
  });

  return (
    <Button
      color="secondary"
      onClick={actAndNotify(mutate, "parcelRemoveFromPortfolio", {
        notify,
        mutateOptions: {
          variables: { portfolioId, parcelId }
        },
        successMessage: "Removed Parcel from Portfolio"
      })}
    >
      <Cancel />
    </Button>
  );
}

ParcelRemoveFromPortfolioAction.propTypes = {
  parcelId: PropTypes.string,
  portfolioId: PropTypes.string
};

export default ParcelRemoveFromPortfolioAction;
