import { useContext } from "react";
import { useMutation } from "@apollo/client";
import actAndNotify from "ui/lib/actAndNotify";
import NotificationsContext from "ui/contexts/NotificationsContext";
import CurrentUserNotificationsQuery from "ui/queries/CurrentUserNotificationsQuery.graphql";
import CurrentUserNotificationsCountQuery from "ui/queries/CurrentUserNotificationsCountQuery.graphql";
import NotificationMarkAsReadMutation from "ui/queries/NotificationMarkAsReadMutation.graphql";
import NotificationMarkAllAsReadMutation from "ui/queries/NotificationMarkAllAsReadMutation.graphql";

export default function useNotificationsPageMutations(variables) {
  const { notify } = useContext(NotificationsContext);
  const refetchQueries = [
    {
      query: CurrentUserNotificationsQuery,
      variables
    },
    {
      query: CurrentUserNotificationsCountQuery
    }
  ];
  const [mutateMarkAsRead] = useMutation(NotificationMarkAsReadMutation, {
    refetchQueries
  });
  const [mutateMarkAllAsRead] = useMutation(NotificationMarkAllAsReadMutation, {
    refetchQueries
  });
  const markAsRead = notificationId =>
    actAndNotify(mutateMarkAsRead, "notificationMarkAsRead", {
      notify,
      mutateOptions: { variables: { notificationId } }
    });
  const markAllAsRead = actAndNotify(
    mutateMarkAllAsRead,
    "notificationMarkAllAsRead",
    { notify, successMessage: "Marked All As Read" }
  );
  return { markAllAsRead, markAsRead };
}
