import React from "react";
import PropTypes from "prop-types";
import GoogleMapReact from "google-maps-react-markers";
import ParcelMarker from "ui/components/ParcelMarker";

function AcquisitionsParcelPageMap({ parcel }) {
  const [lng, lat] = parcel.coordinates.coordinates;
  return (
    <div style={{ height: "50vh", width: "100%" }}>
      <GoogleMapReact
        apiKey={process.env.GOOGLE_API_KEY}
        libraries={["places"]}
        defaultCenter={{ lat, lng }}
        defaultZoom={16}
        options={{ streetViewControl: true }}
      >
        <ParcelMarker lat={lat} lng={lng} parcel={parcel} />
      </GoogleMapReact>
    </div>
  );
}

AcquisitionsParcelPageMap.propTypes = {
  parcel: PropTypes.object
};

export default AcquisitionsParcelPageMap;
