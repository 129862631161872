import React, { useContext } from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { loginRequired } from "ui/providers/CurrentUserProvider";
import { withNotifications } from "ui/contexts/NotificationsContext";
import { SiteWrapper } from "ui/contexts/SiteContext";
import CurrentUserContext from "ui/contexts/CurrentUserContext";
import DynamicSidenav from "ui/components/DynamicSidenav";
import { SidenavWrapper } from "ui/contexts/SidenavContext";
import LendersList from "./LendersList";

const useStyles = makeStyles(theme => ({
  container: {
    height: "100%"
  },
  tab: {
    minWidth: 100
  }
}));

function LendersPage() {
  const classes = useStyles();
  const { user } = useContext(CurrentUserContext);

  return (
    <Grid container className={classes.container} data-testid="lenders-page">
      <SiteWrapper>
        <SidenavWrapper
          Component={props => (
            <DynamicSidenav showLogout={user !== null} {...props} />
          )}
        >
          <Grid item xs container>
            <LendersList />
          </Grid>
        </SidenavWrapper>
      </SiteWrapper>
    </Grid>
  );
}

LendersPage.propTypes = {
  site: PropTypes.string
};

export default loginRequired(withNotifications(LendersPage));
