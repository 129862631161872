import React, { useContext } from "react";
import { useMutation } from "@apollo/client";
import { Grid, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { EmptySidenav } from "ui/components/GlobalSidenav";
import PasswordResetRequestForm from "ui/components/PasswordResetRequestForm";
import NotificationsContext, {
  withNotifications
} from "ui/contexts/NotificationsContext";
import actAndNotify from "ui/lib/actAndNotify";
import PasswordResetRequestMutation from "ui/queries/PasswordResetRequestMutation.graphql";

const useStyles = makeStyles(theme => ({
  resetPassword: {
    marginTop: theme.spacing(10)
  }
}));

function PasswordResetRequestPage() {
  const classes = useStyles();
  const { notify } = useContext(NotificationsContext);
  const [mutate] = useMutation(PasswordResetRequestMutation);

  async function handleSubmit({ email }) {
    await actAndNotify(mutate, "passwordResetRequest", {
      notify,
      mutateOptions: {
        variables: { email }
      },
      successMessage:
        "A password reset email was sent to the address in question if we have a matching user."
    })();
  }

  return (
    <Grid item xs={12} container alignItems="flex-start">
      <EmptySidenav />
      <Grid item xs container alignItems="flex-start">
        <Grid item xs={12} className={classes.resetPassword}>
          <Typography align="center" variant="h4">
            Reset Your Password
          </Typography>
        </Grid>
        <Grid item container xs={12} align="center" justifyContent="center">
          <Grid item xs={4}>
            <PasswordResetRequestForm handleSubmit={handleSubmit} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default withNotifications(PasswordResetRequestPage);
