import React, { useContext } from "react";
import _ from "lodash";
import SiteContext from "ui/contexts/SiteContext";
import GlobalSidenav from "ui/components/GlobalSidenav";
import AcquisitionsSidenav from "ui/sites/acquisitions/components/AcquisitionsSidenav";
import UnderwritingSidenav from "ui/sites/underwriting/components/UnderwritingSidenav";

const mapping = {
  acquisitions: AcquisitionsSidenav,
  underwriting: UnderwritingSidenav
};

function DynamicSidenav(props) {
  const site = useContext(SiteContext)?.site;
  const Component = _.get(mapping, site, GlobalSidenav);
  return <Component {...props} />;
}

export default DynamicSidenav;
