import React, { useState } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import currency from "currency.js";
import { Link as RouterLink } from "react-router-dom";
import { AssignmentReturned } from "@mui/icons-material";
import {
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableFooter,
  Paper,
  TablePagination,
  Checkbox,
  Button
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Loader from "ui/components/Loader";

const useStyles = makeStyles(() => ({
  table: { width: "100%" },
  actionCell: { textAlign: "center" }
}));

function ParcelInfoTableInner({
  loading,
  parcels,
  paginationProps,
  openInNewTab = false,
  withExportSelection = false,
  Action = null,
  handleRequestCsvUpload = () => {}
}) {
  const classes = useStyles();
  const [parcelIds, setParcelIds] = useState([]);
  return (
    <TableContainer component={Paper} data-testid="parcel-info-table">
      <Table
        className={classes.table}
        size="small"
        data-testid="parcel-info-table-inner"
      >
        <TableHead>
          <TableRow>
            {withExportSelection && <TableCell></TableCell>}
            <TableCell>Address</TableCell>
            <TableCell>City</TableCell>
            <TableCell>State</TableCell>
            <TableCell>County</TableCell>
            <TableCell>Zip</TableCell>
            <TableCell>Last Sale Price</TableCell>
            <TableCell>NOD Unpaid Balance</TableCell>
            {Action && <TableCell />}
          </TableRow>
        </TableHead>
        <TableBody>
          {_.map(parcels, parcel => (
            <TableRow key={parcel.id}>
              {withExportSelection && (
                <TableCell>
                  <Checkbox
                    checked={_.includes(parcelIds, parcel.id)}
                    onChange={() => setParcelIds(_.xor(parcelIds, [parcel.id]))}
                  />
                </TableCell>
              )}
              <TableCell>
                <Link
                  target={openInNewTab ? "_blank" : null}
                  component={RouterLink}
                  to={`/acquisitions/parcel/${parcel.id}`}
                  underline="hover"
                >
                  {_.get(parcel, "addressFull", "?")}
                </Link>
              </TableCell>
              <TableCell>{_.get(parcel, "city", "?")}</TableCell>
              <TableCell>{_.get(parcel, "state", "?")}</TableCell>
              <TableCell>{_.get(parcel, "county.name", "?")}</TableCell>
              <TableCell>{_.get(parcel, "zipcode", "?")}</TableCell>
              <TableCell>
                {currency(_.get(parcel, "lastSalePrice", "?"), {
                  precision: 0
                }).format()}
              </TableCell>
              <TableCell>
                {currency(_.get(parcel, "unpaidBalance", 0), {
                  precision: 0
                }).format()}
              </TableCell>
              {Action && (
                <TableCell className={classes.actionCell}>
                  <Action parcel={parcel} />
                </TableCell>
              )}
            </TableRow>
          ))}
          <TableRow>
            {loading && (
              <TableCell colSpan={9}>
                {" "}
                <Loader />
              </TableCell>
            )}
            {!loading && paginationProps && (
              <TablePagination {...paginationProps} />
            )}
          </TableRow>
        </TableBody>
        {withExportSelection && (
          <TableFooter>
            <TableRow>
              <TableCell colSpan={11} align="right">
                <Button
                  variant="contained"
                  onClick={handleRequestCsvUpload(parcelIds)}
                >
                  <AssignmentReturned /> Export
                </Button>
              </TableCell>
            </TableRow>
          </TableFooter>
        )}
      </Table>
    </TableContainer>
  );
}

ParcelInfoTableInner.propTypes = {
  parcels: PropTypes.arrayOf(PropTypes.object).isRequired,
  paginationProps: PropTypes.object
};

export default ParcelInfoTableInner;
