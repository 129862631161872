import React, { useContext } from "react";
import _ from "lodash";
import { Link as RouterLink } from "react-router-dom";
import { Card, CardContent, Grid, Typography, Chip, Link } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import SiteContext from "ui/contexts/SiteContext";

const useStyles = makeStyles(theme => ({
  categories: {
    marginTop: theme.spacing(1.5)
  },
  categoryChip: {
    marginRight: theme.spacing(1)
  },
  colRight: {
    textAlign: "right"
  },
  cardContent: {
    paddingBottom: `${theme.spacing(1)} !important`
  }
}));

function getLastSalePriceBlurb(parcel) {
  let lastSalePriceBlurb = "";
  if (
    parcel.lastSalePrice !== "0" &&
    parcel.lastSalePrice !== null &&
    parcel.lastSalePrice !== "Unknown Last Sale Price" &&
    parcel.lastSalePrice !== ""
  ) {
    lastSalePriceBlurb = "Last sold";
    if (parcel.lastSalePrice !== "Unknown" && parcel.lastSalePrice !== "0") {
      lastSalePriceBlurb += ` for ${new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        useGrouping: true,
        currencyDisplay: "narrowSymbol"
      }).format(parcel.lastSalePrice)}`;
    }
    if (parcel.lastSaleDate !== "Unknown Last Sale Date") {
      lastSalePriceBlurb += ` on ${parcel.lastSaleDate}`;
    }
  }
  return lastSalePriceBlurb;
}

function getUnpaidBalanceBlurb(parcel) {
  let unpaidParcelBlurb = "";

  if (parcel.unpaidBalance !== 0 && parcel.unpaidBalance !== null) {
    unpaidParcelBlurb += `Unpaid Balance: ${new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      useGrouping: true,
      currencyDisplay: "narrowSymbol"
    }).format(parcel.unpaidBalance)}`;
  }

  return unpaidParcelBlurb;
}

function ParcelCard({ parcel }) {
  const { site } = useContext(SiteContext);
  const classes = useStyles();

  return (
    <Card data-testid="parcel-card" id={`parcel-${parcel.id}`}>
      <CardContent className={classes.cardContent}>
        <Grid container>
          <Grid item xs={12}>
            <Link
              to={`/${site}/parcel/${parcel.id}`}
              underline="hover"
              component={RouterLink}
            >
              <Typography variant="body1">{parcel.addressFull}</Typography>
            </Link>
          </Grid>
          <Grid item xs={6} container>
            <Grid item xs={12}>
              <Typography variant="body2">
                {parcel.city}, {parcel.state} {parcel.zipcode}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2">
                {getLastSalePriceBlurb(parcel)}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2">
                {getUnpaidBalanceBlurb(parcel)}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={6} className={classes.colRight}>
            <Grid item xs={12}>
              <Typography variant="body2">
                {parcel.assessorFacts.join(" • ")}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2">
                {parcel.propertyType}
                {" • "}
                {parcel.ownerOccupied ? "Owner Occupied" : "Absentee Owner"}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2">Owner: {parcel.ownerName}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2">{parcel.ownerAddress}</Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} className={classes.categories}>
            {_.map(
              _.uniqBy(_.get(parcel, "categories.edges", []), "node.id"),
              ({ node }) => (
                <Chip
                  className={classes.categoryChip}
                  key={`${parcel.id}-${node.id}`}
                  label={node.name}
                />
              )
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default ParcelCard;
