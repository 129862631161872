import * as Yup from "yup";
import _ from "lodash";

export const fieldsToLabel = {
  askingPrice: "Asking Price",
  approvedPurchasePrice: "Approved Purchase Price",
  budget: "Rehab Budget",
  expenseRatio: "Expense Ratio",
  taxRate: "Tax Rate",
  rentalIncreaseRate: "Rental Increase Rate",
  rent: "Monthly Rent",
  estimatedSalePrice: "Estimated Sale Price"
};

export default Yup.object().shape(
  _.fromPairs(
    _.map(_.toPairs(fieldsToLabel), ([field, label]) => [
      field,
      field === "rentalIncraseRate"
        ? Yup.number().label(label)
        : Yup.number().required("Required Field").label(label)
    ])
  )
);
