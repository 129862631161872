import React, { useState } from "react";

import { Grid } from "@mui/material";

const SidenavContext = React.createContext();

export function SidenavWrapper({
  children,
  Component,
  defaultExpanded = null
}) {
  const [expanded, setExpandedState] = useState(
    defaultExpanded || localStorage.getItem("expanded") === "true"
  );
  function setExpanded(val) {
    setExpandedState(val);
    localStorage.setItem("expanded", val);
  }
  return (
    <SidenavContext.Provider
      value={{
        expanded,
        setExpanded
      }}
    >
      <Grid container sx={{ flexWrap: "nowrap" }}>
        <Grid item xs style={{ flexShrink: 2, flexGrow: 0 }}>
          <Component setExpanded={setExpanded} expanded={expanded} />
        </Grid>
        <Grid item xs>
          {children}
        </Grid>
      </Grid>
    </SidenavContext.Provider>
  );
}

export default SidenavContext;
