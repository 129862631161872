import React, { useState } from "react";
import {
  Grid,
  TextField,
  Autocomplete,
  IconButton,
  Typography
} from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import { greyText } from "ui/themes/AlineaTheme/colors";
import Loader from "ui/components/Loader";

const useStyles = makeStyles(theme => ({
  categoryChip: {
    margin: theme.spacing(0.5)
  },
  filterWrapper: {
    maxHeight: "75vh",
    overflowY: "hidden",
    textAlign: "center",
    paddingRight: "17px"
  },
  autocompleteInput: {
    backgroundColor: "white",
    margin: theme.spacing(1),
    borderRadius: "5px"
  },
  filterHeading: {
    color: "#fff"
  },
  filterHeadingWrapper: {
    textAlign: "center"
  },
  drawerButton: {
    color: greyText
  }
}));

function MultiFilter({
  loading,
  autocompleteOptions,
  onChange,
  isOptionEqualToValue,
  title,
  selectedValues,
  toggleSelectedValue,
  renderChips,
  defaultOpen = true,
  collapsible = true,
  xs = 12,
  size = "small"
}) {
  const classes = useStyles();
  const [open, setOpen] = useState(defaultOpen);
  const titleLower = title.toLowerCase();

  return (
    <Grid item container xs={xs} alignItems="center">
      {collapsible && (
        <>
          <Grid item xs={2}>
            {open && (
              <IconButton
                className={classes.drawerButton}
                onClick={() => setOpen(false)}
              >
                <ExpandLess />
              </IconButton>
            )}
            {!open && (
              <IconButton
                className={classes.drawerButton}
                onClick={() => setOpen(true)}
              >
                <ExpandMore />
              </IconButton>
            )}
          </Grid>
          <Grid item xs={8} className={classes.filterHeadingWrapper}>
            <Typography variant="overline" className={classes.filterHeading}>
              {title}
            </Typography>
          </Grid>
        </>
      )}
      {open && (
        <Grid item xs={12} className={classes.filterWrapper}>
          {loading && <Loader testid={`${titleLower}Loading`} />}
          {!loading && (
            <Grid item xs={12}>
              <Autocomplete
                size={size}
                disablePortal
                options={autocompleteOptions}
                onChange={onChange}
                isOptionEqualToValue={isOptionEqualToValue}
                renderInput={params => (
                  <TextField
                    {...params}
                    label={title}
                    className={classes.autocompleteInput}
                  />
                )}
              />
            </Grid>
          )}
          {!loading && (
            <Grid item xs={12}>
              {renderChips()}
            </Grid>
          )}
        </Grid>
      )}
    </Grid>
  );
}

export default MultiFilter;
