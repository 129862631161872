import React from "react";
import _ from "lodash";
import { useQuery } from "@apollo/client";
import { useParams, useNavigate } from "react-router-dom";
import { Grid, AppBar, Tabs, Tab, Chip } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Loader from "ui/components/Loader";
import RequestsTable from "ui/sites/underwriting/components/RequestsTable";
import CurrentUnderwriterParcelDetailsQuery from "ui/sites/underwriting/queries/CurrentUnderwriterParcelDetailsQuery.graphql";
import { withUnderwritingActions } from "ui/sites/underwriting/contexts/UnderwritingActionsContext";
import UnderwritingParcelNotesContainer from "ui/sites/underwriting/containers/UnderwritingParcelNotesContainer";
import UnderwritingParcelPageMapContainer from "ui/sites/underwriting/containers/UnderwritingParcelPageMapContainer";
import UnderwritingParcelDocumentsContainer from "ui/sites/underwriting/containers/UnderwritingParcelDocumentsContainer";
import UnderwritingParcelImagesContainer from "ui/sites/underwriting/containers/UnderwritingParcelImagesContainer";
import UnderwritingParcelAuditlogContainer from "ui/sites/underwriting/containers/UnderwritingParcelAuditlogContainer";
import UnderwritingParcelAssessmentContainer from "ui/sites/underwriting/containers/UnderwritingParcelAssessmentContainer";
import UnderwritingRequestPurchaseValueContainer from "ui/sites/underwriting/containers/UnderwritingRequestPurchaseValueContainer";
import UnderwritingComparablesContainer from "ui/sites/underwriting/containers/UnderwritingComparablesContainer";

const useStyles = makeStyles(theme => ({
  tab: {
    minWidth: 100
  },
  categoryChipWrapper: {
    marginRight: theme.spacing(1)
  }
}));

function UnderwritingParcelPage() {
  const classes = useStyles();
  const navigate = useNavigate();
  const params = useParams();
  const { parcelId } = params;
  const { data, loading } = useQuery(CurrentUnderwriterParcelDetailsQuery, {
    variables: { parcelId }
  });

  if (loading) {
    return <Loader />;
  }

  if (!data.currentUnderwriter?.parcel) {
    // todo, better error view
    return <p>There was an error.</p>;
  }

  const subPages = [
    {
      label: "Map",
      slug: "map",
      Component: () => (
        <Grid item xs={6}>
          <UnderwritingParcelPageMapContainer parcel={parcel} />
        </Grid>
      )
    },
    {
      label: "Notes",
      slug: "notes",
      Component: () => (
        <Grid item xs={12}>
          <UnderwritingParcelNotesContainer parcelId={parcel.id} />
        </Grid>
      )
    },
    {
      label: "Documents",
      slug: "documents",
      Component: () => (
        <Grid item xs={12}>
          <UnderwritingParcelDocumentsContainer parcelId={parcel.id} />
        </Grid>
      )
    },
    {
      label: "Images",
      slug: "images",
      Component: () => (
        <Grid item xs={12}>
          <UnderwritingParcelImagesContainer parcelId={parcel.id} />
        </Grid>
      )
    },
    {
      label: "History",
      slug: "history",
      Component: () => (
        <Grid item xs={12}>
          <UnderwritingParcelAuditlogContainer parcelId={parcel.id} />
        </Grid>
      )
    },
    {
      label: "Assessor",
      slug: "assessor",
      Component: () => (
        <Grid item xs={12}>
          <UnderwritingParcelAssessmentContainer parcelId={parcel.id} />
        </Grid>
      )
    },
    {
      label: "Comparables",
      slug: "comparables",
      Component: () => (
        <Grid item xs={12}>
          <UnderwritingComparablesContainer parcel={parcel} defaultZoom={14} />
        </Grid>
      )
    },
    {
      label: "Purchase Value",
      slug: "purchase-value",
      Component: () => (
        <Grid item xs={12}>
          <UnderwritingRequestPurchaseValueContainer parcel={parcel} />
        </Grid>
      )
    }
  ];
  const tabValue = _.findIndex(
    subPages,
    ({ slug }) => slug === _.get(params, "subpage", "map")
  );

  const { parcel } = data.currentUnderwriter;

  const onTabChange = (e, newValue) =>
    navigate(`/underwriting/parcel/${parcelId}/${subPages[newValue].slug}`);

  const Subpage = subPages[tabValue].Component;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <AppBar
          position="fixed"
          sx={{ top: "auto", bottom: 0, alignItems: "center" }}
        >
          <Tabs
            value={tabValue}
            onChange={onTabChange}
            textColor="inherit"
            variant="scrollable"
            scrollButtons="auto"
          >
            {subPages.map(({ label }, i) => (
              <Tab label={label} key={i} className={classes.tab} />
            ))}
          </Tabs>
        </AppBar>
      </Grid>
      <Grid item xs={12}>
        <RequestsTable
          requests={[
            {
              ...parcel.currentRequest,
              lead: { ...parcel.currentLead, parcel }
            }
          ]}
          noLinks
          withPortfolioManagement
          canDelete
        />
      </Grid>
      <Grid
        item
        container
        xs={12}
        className={classes.categories}
        justifyContent="flex-end"
      >
        {_.map(
          _.uniqBy(_.get(parcel, "categories.edges", []), "node.id"),
          ({ node }) => (
            <Grid item className={classes.categoryChipWrapper}>
              <Chip key={`${parcel.id}-${node.id}`} label={node.name} />
            </Grid>
          )
        )}
      </Grid>
      <Grid item xs={12} container justifyContent="center">
        <Subpage />
      </Grid>
    </Grid>
  );
}

export default withUnderwritingActions(UnderwritingParcelPage);
