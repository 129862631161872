import React, { useState } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { Link as RouterLink } from "react-router-dom";
import { useQuery } from "@apollo/client";
import {
  Grid,
  Chip,
  AppBar,
  Button,
  Toolbar,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableContainer,
  TableRow,
  TableSortLabel,
  TableFooter,
  TablePagination,
  TextField,
  InputAdornment,
  LinearProgress,
  Link
} from "@mui/material";
import { Clear, Search } from "@mui/icons-material";
import makeStyles from "@mui/styles/makeStyles";
import { greyText } from "ui/themes/AlineaTheme/colors";
import MultiFilter from "ui/components/MultiFilter";
import useCursors from "ui/hooks/useCursors";
import usePagination from "ui/hooks/usePagination";
import {
  getJSONArrayFromLocalStorage,
  getStringFromLocalStorage
} from "ui/lib/localStorage";
import CurrentUserAllLendersQuery from "ui/queries/CurrentUserAllLendersQuery.graphql";
import CurrentUserAllCategoriesQuery from "ui/queries/CurrentUserAllCategoriesQuery.graphql";
import CurrentUserAllCategoryGroupingsQuery from "ui/queries/CurrentUserAllCategoryGroupingsQuery.graphql";
import LenderFilteredTotalCount from "./LenderFilteredTotalCount";

const useStyles = makeStyles(theme => ({
  table: { width: "100%" },
  toolbarGrid: { height: theme.spacing(12.5) },
  search: {
    color: greyText,
    width: "100%"
  },
  searchLabel: {
    color: greyText
  },
  searchInput: {
    background: "#fff",
    borderRadius: "5px",
    margin: theme.spacing(1)
  },
  clearButton: {
    marginTop: theme.spacing(1)
  }
}));

function LendersList() {
  const classes = useStyles();
  const cursors = useCursors(25, "lendersList");
  const [[sort, order], setSort] = useState(["parcel_count", "desc"]);
  const [selectedCategories, setSelectedCategories] = useState(
    getJSONArrayFromLocalStorage("lendersListSelectedCategories")
  );
  const [selectedCategoryGroupings, setSelectedCategoryGroupings] = useState(
    getJSONArrayFromLocalStorage("lendersListSelectedCategoryGroupings")
  );
  const [search, setSearch] = useState(
    getStringFromLocalStorage("lendersListSearch")
  );
  const variables = {
    categories: selectedCategories,
    categoryGroupings: selectedCategoryGroupings,
    orderBy: `${order === "desc" ? "-" : ""}${sort}`,
    name: search,
    includeParcelCount: !(
      _.isEmpty(selectedCategories) && _.isEmpty(selectedCategoryGroupings)
    ),
    ..._.pick(cursors, ["first", "after"])
  };

  const { data, loading } = useQuery(CurrentUserAllLendersQuery, {
    variables,
    fetchPolicy: "cache-and-network"
  });

  const { data: categoriesData, loading: categoriesLoading } = useQuery(
    CurrentUserAllCategoriesQuery,
    { variables: { first: 5000 } }
  );
  const { data: categoryGroupingsData, loading: categoryGroupingsLoading } =
    useQuery(CurrentUserAllCategoryGroupingsQuery, {
      variables: { first: 5000 }
    });

  const toggleSelectedCategory = function (name) {
    return () => {
      const sc = _.xor(selectedCategories, [name]);
      localStorage.setItem("lendersListSelectedCategories", JSON.stringify(sc));
      setSelectedCategories(sc);
    };
  };

  const toggleSelectedCategoryGrouping = function (name) {
    return () => {
      const scg = _.xor(selectedCategoryGroupings, [name]);
      localStorage.setItem(
        "lendersListSelectedCategoryGroupings",
        JSON.stringify(scg)
      );
      setSelectedCategoryGroupings(scg);
    };
  };

  const onSearchChange = e => {
    setSearch(e.target.value);
    localStorage.setItem("lendersListSearch", e.target.value);
  };

  const alphabeticalCategories = _.map(
    _.sortBy(
      _.map(
        _.get(categoriesData, "currentUser.allCategories.edges", []),
        "node"
      ),
      ({ name }) => _.lowerCase(name)
    ),
    ({ name }) => ({
      label: name
    })
  );

  const alphabeticalCategoryGroupings = _.map(
    _.sortBy(
      _.map(
        _.get(
          categoryGroupingsData,
          "currentUser.allCategoryGroupings.edges",
          []
        ),
        "node"
      ),
      ({ name }) => _.lowerCase(name)
    ),
    ({ name }) => ({
      label: name
    })
  );

  const count = _.get(data, `currentUser.allLenders.totalCount`, 0);

  const { paginationProps, setPage } = usePagination({
    localStorageSlug: "lendersList",
    nextAfter: _.get(data, `currentUser.allLenders.pageInfo.endCursor`),
    count,
    ...cursors
  });

  const sortables = [
    { id: "parcel_count", label: "# Parcels" },
    { id: "troubled_apartment_parcel_count", label: "# Troubled Apartments" },
    { id: "troubled_apartment_percentage", label: "% Troubled Apartments" },
    { id: "troubled_commercial_count", label: "# Troubled Commercial" },
    { id: "troubled_commercial_percentage", label: "% Troubled Comercial" },
    { id: "troubled_parcel_count", label: "# Troubled Parcels" },
    { id: "troubled_percentage", label: "% Troubled Parcels" }
  ];

  function handleSortClick(id) {
    setSort([id, id !== sort || order === "asc" ? "desc" : "asc"]);
  }

  function clearSettings() {
    _.forEach(_.keys(localStorage), key => {
      if (key.includes("lendersList")) {
        localStorage.removeItem(key);
      }
    });
    setSelectedCategories([]);
    setSelectedCategories([]);
    setSearch("");
    cursors.setFirst(25);
    cursors.setAfter("");
    setPage(0);
  }

  return (
    <Grid item xs={12}>
      <AppBar position="static">
        <Toolbar>
          <Grid
            container
            alignItems="flex-start"
            justifyContent="space-evenly"
            direction="row"
            className={classes.toolbarGrid}
          >
            <Grid item xs={1}>
              <Button
                className={classes.clearButton}
                onClick={clearSettings}
                endIcon={<Clear />}
                variant="contained"
                color="secondary"
              >
                Reset
              </Button>
            </Grid>
            <Grid item xs={3}>
              <TextField
                className={classes.search}
                size="small"
                value={search || ""}
                placeholder="Search"
                onChange={onSearchChange}
                inputProps={{ "data-testid": "search-input" }}
                InputLabelProps={{ className: classes.searchLabel }}
                InputProps={{
                  className: classes.searchInput,
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  )
                }}
              />
            </Grid>
            <MultiFilter
              xs={4}
              size="small"
              autocompleteOptions={alphabeticalCategories}
              loading={categoriesLoading}
              title="Categories"
              onChange={(event, newValue) => {
                if (newValue.label) {
                  toggleSelectedCategory(newValue.label)();
                }
              }}
              isOptionEqualToValue={(option, value) => option.label === value}
              selectedValues={selectedCategories}
              toggleSelectedValue={toggleSelectedCategory}
              collapsible={false}
              renderChips={() =>
                _.map(selectedCategories, name => (
                  <Chip
                    key={name}
                    label={`${name}`}
                    variant="filled"
                    color="warning"
                    data-testid={`categories-${name}`}
                    onDelete={toggleSelectedCategory(name)}
                    deleteIcon={<Clear />}
                    className={classes.categoryChip}
                  />
                ))
              }
            />
            <MultiFilter
              xs={4}
              size="small"
              autocompleteOptions={alphabeticalCategoryGroupings}
              loading={categoryGroupingsLoading}
              title="Property Type"
              onChange={(event, newValue) => {
                if (newValue.label) {
                  toggleSelectedCategoryGrouping(newValue.label)();
                }
              }}
              isOptionEqualToValue={(option, value) => {
                return option.label === value.label;
              }}
              selectedValues={selectedCategoryGroupings}
              toggleSelectedValue={toggleSelectedCategoryGrouping}
              collapsible={false}
              renderChips={() =>
                _.map(selectedCategoryGroupings, name => (
                  <Chip
                    key={name}
                    label={`${name}`}
                    variant="filled"
                    color="warning"
                    data-testid={`categorygrouping-${name}`}
                    onDelete={toggleSelectedCategoryGrouping(name)}
                    deleteIcon={<Clear />}
                    className={classes.categoryChip}
                  />
                ))
              }
            />
          </Grid>
        </Toolbar>
      </AppBar>
      <TableContainer component={Paper}>
        <Table
          className={classes.table}
          size="small"
          data-testid="lenders-table"
        >
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              {_.isEmpty(selectedCategories) &&
              _.isEmpty(selectedCategoryGroupings) ? (
                _.map(sortables, ({ id, label }) => (
                  <TableCell
                    key={id}
                    sortDirection={sort === id ? order : false}
                    align="right"
                  >
                    <TableSortLabel
                      active={sort === id}
                      direction={sort === id ? order : "asc"}
                      onClick={() => handleSortClick(id)}
                    >
                      {label}
                    </TableSortLabel>
                  </TableCell>
                ))
              ) : (
                <TableCell align="right">Parcels</TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {!loading && count === 0 && (
              <TableRow>
                <TableCell align="center" colSpan={10}>
                  <b>No Lenders</b>
                </TableCell>
              </TableRow>
            )}
            {_.map(_.get(data, `currentUser.allLenders.edges`), ({ node }) => (
              <TableRow key={node.id}>
                <TableCell>
                  <Link
                    component={RouterLink}
                    to={`/lender/${node.id}`}
                    underline="hover"
                  >
                    {node.name}
                  </Link>
                </TableCell>
                <TableCell align="right">
                  {_.isEmpty(selectedCategories) &&
                  _.isEmpty(selectedCategoryGroupings) ? (
                    _.get(node, "parcelCount", 0)
                  ) : (
                    <LenderFilteredTotalCount
                      categories={selectedCategories}
                      categoryGroupings={selectedCategoryGroupings}
                      lender={node}
                    />
                  )}
                </TableCell>
                {_.isEmpty(selectedCategories) &&
                _.isEmpty(selectedCategoryGroupings) ? (
                  <>
                    <TableCell align="right">
                      {_.get(node, "troubledApartmentParcelCount", 0)}
                    </TableCell>
                    <TableCell align="right">
                      {_.get(
                        node,
                        "troubledApartmentPercentage",
                        0
                      ).toLocaleString("en", {
                        style: "percent",
                        minimumFractionDigits: 2
                      })}
                    </TableCell>
                  </>
                ) : null}
                {_.isEmpty(selectedCategories) &&
                _.isEmpty(selectedCategoryGroupings) ? (
                  <>
                    <TableCell align="right">
                      {_.get(node, "troubledCommercialCount", 0)}
                    </TableCell>
                    <TableCell align="right">
                      {_.get(
                        node,
                        "troubledCommercialPercentage",
                        0
                      ).toLocaleString("en", {
                        style: "percent",
                        minimumFractionDigits: 2
                      })}
                    </TableCell>
                  </>
                ) : null}
                {_.isEmpty(selectedCategories) &&
                _.isEmpty(selectedCategoryGroupings) ? (
                  <>
                    <TableCell align="right">
                      {_.get(node, "troubledParcelCount", 0)}
                    </TableCell>
                    <TableCell align="right">
                      {_.get(
                        node,
                        "troubledParcelPercentage",
                        0
                      ).toLocaleString("en", {
                        style: "percent",
                        minimumFractionDigits: 2
                      })}
                    </TableCell>
                  </>
                ) : null}
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              {loading && (
                <TableCell colSpan={10}>
                  <LinearProgress data-testid="loading" />
                </TableCell>
              )}
              {!loading && paginationProps && (
                <TablePagination {...paginationProps} />
              )}
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </Grid>
  );
}

LendersList.propTypes = {
  query: PropTypes.object,
  slug: PropTypes.string
};

export default LendersList;
