import React from "react";
import { useQuery } from "@apollo/client";
import { Assignment, AssignmentInd } from "@mui/icons-material";
import GlobalSidenav from "ui/components/GlobalSidenav";
import CurrentUnderwriterQuery from "ui/sites/underwriting/queries/CurrentUnderwriterQuery.graphql";

function UnderwritingSidenav({ expanded = false, setExpanded = () => {} }) {
  const { data } = useQuery(CurrentUnderwriterQuery);
  const hasUnderwriter = data?.currentUnderwriter !== null;
  const links = hasUnderwriter
    ? [
        {
          Icon: AssignmentInd,
          path: "/underwriting",
          text: "My Requests"
        },
        {
          Icon: Assignment,
          path: "/underwriting/requests/open",
          text: "Open Requests"
        }
      ]
    : [];
  return (
    <GlobalSidenav
      links={links}
      showLogout={hasUnderwriter}
      expanded={expanded}
      setExpanded={setExpanded}
    />
  );
}

export default UnderwritingSidenav;
