import React, { useState } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { Link as RouterLink } from "react-router-dom";
import { useQuery } from "@apollo/client";
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableContainer,
  TableRow,
  TableFooter,
  TablePagination,
  TableSortLabel,
  LinearProgress,
  Link,
  TextField,
  InputAdornment,
  AppBar,
  Toolbar
} from "@mui/material";
import { Search } from "@mui/icons-material";
import makeStyles from "@mui/styles/makeStyles";
import { greyText } from "ui/themes/AlineaTheme/colors";
import useCursors from "ui/hooks/useCursors";
import usePagination from "ui/hooks/usePagination";
import CurrentUserAllOwnersQuery from "ui/queries/CurrentUserAllOwnersQuery.graphql";

const useStyles = makeStyles(theme => ({
  table: { width: "100%" },
  toolbarGrid: { height: theme.spacing(6) },
  search: {
    color: greyText,
    width: "100%"
  },
  searchLabel: {
    color: greyText
  },
  searchInput: {
    background: "#fff",
    borderRadius: "5px",
    margin: theme.spacing(1)
  }
}));

function OwnersList() {
  const classes = useStyles();
  const cursors = useCursors();
  const [[sort, order], setSort] = useState(["parcel_count", "desc"]);
  const [search, setSearch] = useState(null);
  const variables = _.pick(cursors, ["first", "after"]);

  variables.orderBy = `${order === "desc" ? "-" : ""}${sort}`;
  variables.name = search;
  const { data, loading } = useQuery(CurrentUserAllOwnersQuery, {
    variables,
    fetchPolicy: "cache-and-network"
  });

  const count = _.get(data, `currentUser.allMultiOwners.totalCount`, 0);

  const { paginationProps } = usePagination({
    nextAfter: _.get(data, `currentUser.allMultiOwners.pageInfo.endCursor`),
    count,
    ...cursors
  });

  const sortables = [
    { id: "parcel_count", label: "# Parcels" },
    { id: "troubled_apartment_parcel_count", label: "# Troubled Apartments" },
    { id: "troubled_apartment_percentage", label: "% Troubled Apartments" },
    { id: "troubled_commercial_count", label: "# Troubled Commercial" },
    { id: "troubled_commercial_percentage", label: "% Troubled Commercial" },
    { id: "troubled_parcel_count", label: "# Troubled Parcel" },
    { id: "troubled_parcel_percentage", label: "% Troubled Parcel" }
  ];

  function handleSortClick(id) {
    setSort([id, id !== sort || order === "asc" ? "desc" : "asc"]);
  }

  return (
    <Grid item xs={12}>
      <AppBar position="static">
        <Toolbar>
          <Grid
            container
            alignItems="flex-start"
            justifyContent="flex-start"
            direction="row"
            className={classes.toolbarGrid}
          >
            <Grid xs={4}>
              <TextField
                className={classes.search}
                size="small"
                value={search}
                placeholder="Search"
                onChange={e => setSearch(e.target.value)}
                inputProps={{ "data-testid": "search-input" }}
                InputLabelProps={{ className: classes.searchLabel }}
                InputProps={{
                  className: classes.searchInput,
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  )
                }}
              />
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <TableContainer component={Paper}>
        <Table
          className={classes.table}
          size="small"
          data-testid="owners-table"
        >
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              {_.map(sortables, ({ id, label }) => (
                <TableCell
                  key={id}
                  sortDirection={sort === id ? order : false}
                  align="right"
                >
                  <TableSortLabel
                    active={sort === id}
                    direction={sort === id ? order : "asc"}
                    onClick={() => handleSortClick(id)}
                  >
                    {label}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {!loading && count === 0 && (
              <TableRow>
                <TableCell align="center" colSpan={10}>
                  <b>No Owners</b>
                </TableCell>
              </TableRow>
            )}
            {_.map(
              _.get(data, `currentUser.allMultiOwners.edges`),
              ({ node }) => (
                <TableRow key={node.id}>
                  <TableCell>
                    <Link
                      component={RouterLink}
                      to={`/owner/${node.id}`}
                      underline="hover"
                    >
                      {node.name}
                    </Link>
                  </TableCell>
                  <TableCell align="right">
                    {_.get(node, "parcelCount", 0)}
                  </TableCell>
                  <TableCell align="right">
                    {_.get(node, "troubledApartmentParcelCount", 0)}
                  </TableCell>
                  <TableCell align="right">
                    {_.get(
                      node,
                      "troubledApartmentPercentage",
                      0
                    ).toLocaleString("en", {
                      style: "percent",
                      minimumFractionDigits: 2
                    })}
                  </TableCell>
                  <TableCell align="right">
                    {_.get(node, "troubledCommercialCount", 0)}
                  </TableCell>
                  <TableCell align="right">
                    {_.get(
                      node,
                      "troubledCommercialPercentage",
                      0
                    ).toLocaleString("en", {
                      style: "percent",
                      minimumFractionDigits: 2
                    })}
                  </TableCell>
                  <TableCell align="right">
                    {_.get(node, "troubledParcelCount", 0)}
                  </TableCell>
                  <TableCell align="right">
                    {_.get(node, "troubledPercentage", 0).toLocaleString("en", {
                      style: "percent",
                      minimumFractionDigits: 2
                    })}
                  </TableCell>
                </TableRow>
              )
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              {loading && (
                <TableCell colSpan={10}>
                  <LinearProgress data-testid="loading" />
                </TableCell>
              )}
              {!loading && paginationProps && (
                <TablePagination {...paginationProps} />
              )}
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </Grid>
  );
}

OwnersList.propTypes = {
  query: PropTypes.object,
  slug: PropTypes.string
};

export default OwnersList;
