import React from "react";
import { Routes as Switch, Route } from "react-router-dom";
import UnderwritingIndexPage from "./pages/UnderwritingIndexPage";
import UnderwritingOpenRequestsPage from "./pages/UnderwritingOpenRequestsPage";
import UnderwritingParcelPage from "./pages/UnderwritingParcelPage";

function Routes() {
  return (
    <Switch>
      <Route
        path="/parcel/:parcelId/:subpage/:subsection"
        element={<UnderwritingParcelPage />}
      />
      <Route
        path="/parcel/:parcelId/:subpage"
        element={<UnderwritingParcelPage />}
      />
      <Route path="/parcel/:parcelId" element={<UnderwritingParcelPage />} />
      <Route path="/requests/open" element={<UnderwritingOpenRequestsPage />} />
      <Route path="/" element={<UnderwritingIndexPage />} />
    </Switch>
  );
}

export default Routes;
