import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { useMutation } from "@apollo/client";
import {
  Grid,
  Typography,
  List,
  ListItem,
  Collapse,
  ListItemText
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import PurchaseValue from "ui/components/PurchaseValue";
import NotificationsContext from "ui/contexts/NotificationsContext";
import actAndNotify from "ui/lib/actAndNotify";
import { useUnderwritingActions } from "ui/sites/underwriting/contexts/UnderwritingActionsContext";
import UnderwritingRequestPurchaseValueEnterMutation from "ui/sites/underwriting/queries/UnderwritingRequestPurchaseValueEnterMutation.graphql";
import UnderwritingParcelComparablesContainer from "ui/sites/underwriting/containers/UnderwritingParcelComparablesContainer";
import FlipPurchaseValueWorksheetContainer from "ui/sites/underwriting/containers/FlipPurchaseValueWorksheetContainer";
import WholesalePurchaseValueWorksheetContainer from "ui/sites/underwriting/containers/WholesalePurchaseValueWorksheetContainer";
import RentalPurchaseValueWorksheetContainer from "ui/sites/underwriting/containers/RentalPurchaseValueWorksheetContainer";
import MiniBudgetWidget from "ui/sites/underwriting/components/MiniBudgetWidget";

const useStyles = makeStyles(theme => ({
  nested: {
    paddingLeft: theme.spacing(4)
  }
}));

const compTypesToText = {
  AS_IS: "Wholesale",
  LISTED: "Flip",
  RENTAL: "Rental"
};

const compTypesToWorksheet = {
  AS_IS: WholesalePurchaseValueWorksheetContainer,
  LISTED: FlipPurchaseValueWorksheetContainer,
  RENTAL: RentalPurchaseValueWorksheetContainer
};

function UnderwritingRequestPurchaseValueContainer({ parcel }) {
  const classes = useStyles();
  const [compType, setCompType] = useState("AS_IS");
  const [subview, setSubview] = useState("Comparables");
  const { refetchQueries } = useUnderwritingActions(parcel.id);
  const [mutate] = useMutation(UnderwritingRequestPurchaseValueEnterMutation, {
    refetchQueries
  });
  const { notify } = useContext(NotificationsContext);
  const onSubmit = async values => {
    actAndNotify(mutate, "underwritingRequestPurchaseValueEnter", {
      notify,
      mutateOptions: {
        variables: {
          ...values,
          underwritingRequestId: parcel.currentRequest.id
        }
      },
      successMessage: "Successfully updated purchase value!"
    })();
  };
  const PurchaseValueWorksheet = compTypesToWorksheet[compType];
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h3" align="center">
          Purchase Value
        </Typography>
      </Grid>
      <Grid item xs={12} style={{ margin: 10 }}>
        <PurchaseValue
          onSubmit={onSubmit}
          purchaseValue={_.get(parcel, "currentRequest.purchasevalue")}
        />
      </Grid>
      <Grid item xs={12} container>
        <Grid item xs={2}>
          <List component="nav">
            {_.map(compTypesToText, (name, cType) => (
              <React.Fragment key={cType}>
                <ListItem button onClick={() => setCompType(cType)}>
                  <ListItemText primary={name} />
                  {compType === { compType } ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={compType === cType} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {["Comparables", "Budget", "Purchase Value"].map(
                      section => (
                        <ListItem
                          key={section}
                          button
                          className={classes.nested}
                          selected={subview === section}
                          onClick={() => setSubview(section)}
                        >
                          <ListItemText primary={section} />
                        </ListItem>
                      )
                    )}
                  </List>
                </Collapse>
              </React.Fragment>
            ))}
          </List>
        </Grid>
        <Grid item xs={10} container>
          {subview === "Comparables" && (
            <UnderwritingParcelComparablesContainer
              compType={compType}
              parcel={parcel}
            />
          )}
          {subview === "Budget" && (
            <MiniBudgetWidget parcel={parcel} compType={compType} />
          )}
          {subview === "Purchase Value" && (
            <PurchaseValueWorksheet parcel={parcel} />
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}

UnderwritingRequestPurchaseValueContainer.propTypes = {
  parcel: PropTypes.object
};

export default UnderwritingRequestPurchaseValueContainer;
