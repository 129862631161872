import React, { useContext, useState } from "react";
import _ from "lodash";
import { Grid, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useQuery, useMutation } from "@apollo/client";
import actAndNotify from "ui/lib/actAndNotify";
import { withMapContext } from "ui/contexts/MapContext";
import NotificationsContext from "ui/contexts/NotificationsContext";
import usePagination from "ui/hooks/usePagination";
import useCursors from "ui/hooks/useCursors";
import Loader from "ui/components/Loader";
import CurrentSalespersonLeadsQuery from "ui/sites/acquisitions/queries/CurrentSalespersonLeadsQuery.graphql";
import ParcelExportFromSalespersonLeadsMutation from "ui/sites/acquisitions/queries/ParcelExportFromSalespersonLeadsMutation.graphql";
import ParcelExportByIdMutation from "ui/queries/ParcelExportByIdMutation.graphql";
import { withLeadActions } from "ui/sites/acquisitions/contexts/LeadActionsContext";
import LeadsTable from "ui/sites/acquisitions/components/LeadsTable";
import AcquisitionsIndexPageLeadsMap from "./AcquisitionsIndexPageLeadsMap";

const useStyles = makeStyles(theme => ({
  wrapper: {
    padding: theme.spacing(3, 3)
  },
  tableWrapper: {
    marginTop: theme.spacing(5)
  },
  title: {
    marginBottom: theme.spacing(2.5)
  }
}));

function AcquisitionsIndexPage() {
  const classes = useStyles();
  const { notify } = useContext(NotificationsContext);
  const cursors = useCursors();
  const [selectedParcel, setSelectedParcel] = useState(null);
  const [exportById] = useMutation(ParcelExportByIdMutation);
  const [exportAll] = useMutation(ParcelExportFromSalespersonLeadsMutation);
  const { data, loading } = useQuery(CurrentSalespersonLeadsQuery);
  const { paginationProps } = usePagination({
    nextAfter: _.get(data, "currentSalesperson.leads.pageInfo.endCursor"),
    count: _.get(data, "currentSalesperson.leads.totalCount"),
    ...cursors
  });
  if (loading) {
    return <Loader />;
  }

  function handleRequestCsvUpload(parcelIds) {
    const mutate = _.isEmpty(parcelIds) ? exportAll : exportById;
    const slug = _.isEmpty(parcelIds)
      ? "parcelExportFromSalespersonLeads"
      : "parcelExportById";
    return actAndNotify(mutate, slug, {
      notify,
      mutateOptions: { variables: { parcelIds } },
      successMessage:
        "Requested CSV export. You will be notified when it's ready."
    });
  }

  const leads = _.map(
    _.get(data, "currentSalesperson.leads.edges", []),
    ({ node }) => ({
      ...node,
      salesperson: {
        id: data.currentSalesperson.id,
        user: data.currentSalesperson.user
      }
    })
  );

  const parcels = _.map(leads, "parcel");

  return (
    <Grid
      item
      xs={12}
      container
      className={classes.wrapper}
      data-testid="acquisitions-index-page"
    >
      <Grid item xs={12} className={classes.title}>
        <Typography variant="h4" align="center">
          My Leads
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <AcquisitionsIndexPageLeadsMap
          selectedParcel={selectedParcel}
          setSelectedParcel={setSelectedParcel}
          loading={loading}
          parcels={parcels}
        />
      </Grid>
      <Grid container item xs={12} className={classes.tableWrapper}>
        <LeadsTable
          leads={leads}
          handleRequestCsvUpload={handleRequestCsvUpload}
          paginationProps={paginationProps}
          withPortfolioManagement
          withExportSelection
        />
      </Grid>
    </Grid>
  );
}

export default withMapContext(withLeadActions(AcquisitionsIndexPage));
