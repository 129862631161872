import React from "react";
import _ from "lodash";
import { useQuery } from "@apollo/client";
import { Grid, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Loader from "ui/components/Loader";
import Notifications from "ui/components/Notifications";
import DynamicSidenav from "ui/components/DynamicSidenav";
import { loginRequired } from "ui/providers/CurrentUserProvider";
import { SiteWrapper } from "ui/contexts/SiteContext";
import { withNotifications } from "ui/contexts/NotificationsContext";
import { SidenavWrapper } from "ui/contexts/SidenavContext";
import useCursors from "ui/hooks/useCursors";
import usePagination from "ui/hooks/usePagination";
import CurrentUserNotificationsQuery from "ui/queries/CurrentUserNotificationsQuery.graphql";
import useNotificationsPageMutations from "./useNotificationsPageMutations";

const useStyles = makeStyles(theme => ({
  container: {
    height: "100%"
  }
}));

function NotificationsPage() {
  const classes = useStyles();
  const cursors = useCursors();
  const variables = _.pick(cursors, ["first", "after"]);
  const { loading, data } = useQuery(CurrentUserNotificationsQuery, {
    variables
  });
  const { paginationProps } = usePagination({
    nextAfter: _.get(data, "currentUser.allNotifications.pageInfo.endCursor"),
    count: _.get(data, "currentUser.allNotifications.totalCount"),
    ...cursors
  });
  const { markAsRead, markAllAsRead } =
    useNotificationsPageMutations(variables);

  return (
    <Grid
      container
      className={classes.container}
      data-testid="notifications-page"
    >
      <SiteWrapper>
        <SidenavWrapper
          Component={props => (
            <DynamicSidenav
              showLogout={data?.currentUser !== null}
              {...props}
            />
          )}
        >
          <Grid item xs container>
            {loading && <Loader />}
            {!loading && (
              <Grid item xs={12}>
                <Typography align="center" variant="h2">
                  Notifications
                </Typography>

                <Notifications
                  notifications={_.map(
                    _.get(data, "currentUser.allNotifications.edges", []),
                    "node"
                  )}
                  markAsRead={markAsRead}
                  markAllAsRead={markAllAsRead}
                  paginationProps={paginationProps}
                />
              </Grid>
            )}
          </Grid>
        </SidenavWrapper>
      </SiteWrapper>
    </Grid>
  );
}

export default loginRequired(withNotifications(NotificationsPage));
