import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
  Button,
  TextField
} from "@mui/material";
import actAndNotify from "ui/lib/actAndNotify";
import NotificationsContext from "ui/contexts/NotificationsContext";
import { useLeadActions } from "ui/sites/acquisitions/contexts/LeadActionsContext";

function PromoteLeadAction({ parcelId }) {
  const { notify } = useContext(NotificationsContext);
  const { promoteLead, refetchQueries } = useLeadActions(parcelId);
  const [open, setOpen] = useState(false);
  const [askingPrice, setAskingPrice] = useState("");
  const handleClickOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  async function handleSubmit(e) {
    e.preventDefault();
    await actAndNotify(promoteLead, "leadPromote", {
      notify,
      mutateOptions: {
        variables: { askingPrice, parcelId },
        refetchQueries
      },
      successMessage: "Successfully submitted lead to underwriting!"
    })();
    handleClose();
  }
  return (
    <>
      <Button
        variant="outlined"
        color="primary"
        onClick={handleClickOpen}
        data-testid="promote-to-underwriting"
      >
        Promote to Underwriting
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          Promote to Underwriting
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter an asking price for your lead.
          </DialogContentText>
          <form onSubmit={handleSubmit}>
            <TextField
              autoFocus
              margin="dense"
              id="asking-price"
              data-testid="asking-price"
              label="Asking Price"
              type="number"
              onChange={e => setAskingPrice(e.target.valueAsNumber)}
              fullWidth
            />
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleSubmit}
            color="primary"
            disabled={!askingPrice}
            data-testid="submit-promote"
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

PromoteLeadAction.propTypes = {
  parcelId: PropTypes.string.isRequired,
  promoteLead: PropTypes.func
};

PromoteLeadAction.defaultProps = {
  promoteLead: () => {}
};

export default PromoteLeadAction;
