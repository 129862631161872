import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { useParams } from "react-router-dom";
import { Grid, Typography, Chip, AppBar, Toolbar } from "@mui/material";
import { Clear } from "@mui/icons-material";
import makeStyles from "@mui/styles/makeStyles";
import { useQuery, useMutation } from "@apollo/client";
import actAndNotify from "ui/lib/actAndNotify";
import useCursors from "ui/hooks/useCursors";
import usePagination from "ui/hooks/usePagination";
import { SiteWrapper } from "ui/contexts/SiteContext";
import NotificationsContext from "ui/contexts/NotificationsContext";
import { withNotifications } from "ui/contexts/NotificationsContext";
import { SidenavWrapper } from "ui/contexts/SidenavContext";
import MultiFilter from "ui/components/MultiFilter";
import CurrentUserOwnerParcelsQuery from "ui/queries/CurrentUserOwnerParcelsQuery.graphql";
import ParcelExportFromOwnerMutation from "ui/sites/acquisitions/queries/ParcelExportFromOwnerMutation.graphql";
import ParcelExportByIdMutation from "ui/queries/ParcelExportByIdMutation.graphql";
import DynamicSidenav from "ui/components/DynamicSidenav";
import ParcelInfoTableInner from "ui/components/ParcelInfoTable/ParcelInfoTableInner";
import CurrentUserAllCategoriesQuery from "ui/queries/CurrentUserAllCategoriesQuery.graphql";
import CurrentUserAllCategoryGroupingsQuery from "ui/queries/CurrentUserAllCategoryGroupingsQuery.graphql";

const useStyles = makeStyles(theme => ({
  container: {
    height: "100%"
  },
  toolbarGrid: { height: theme.spacing(12.5) },
  appBarWrapper: { maxHeight: theme.spacing(12.5) },
  heightFix: { maxHeight: theme.spacing(12.5) }
}));

function OwnerPage({ site }) {
  const classes = useStyles();
  const { ownerId } = useParams();
  const cursors = useCursors();
  const { notify } = useContext(NotificationsContext);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedCategoryGroupings, setSelectedCategoryGroupings] = useState(
    []
  );
  const variables = {
    categories: selectedCategories,
    categoryGroupings: selectedCategoryGroupings,
    ownerId,
    ..._.pick(cursors, ["first", "after"])
  };
  const { data, loading } = useQuery(CurrentUserOwnerParcelsQuery, {
    variables
  });
  const count = _.get(data, `currentUser.multiOwner.parcels.totalCount`, 0);

  const { paginationProps } = usePagination({
    nextAfter: _.get(data, `currentUser.multiOwner.parcels.pageInfo.endCursor`),
    count,
    ...cursors
  });

  const [exportById] = useMutation(ParcelExportByIdMutation);
  const [exportAll] = useMutation(ParcelExportFromOwnerMutation, {
    variables: { ownerId }
  });

  const parcels = _.map(
    _.get(data, "currentUser.multiOwner.parcels.edges"),
    ({ node }) => node
  );

  function handleRequestCsvUpload(parcelIds) {
    const mutateOptions = {};
    if (!_.isEmpty(parcelIds)) {
      mutateOptions.variables = { parcelIds };
    }
    const mutate = _.isEmpty(parcelIds) ? exportAll : exportById;
    const slug = _.isEmpty(parcelIds)
      ? "parcelExportFromOwner"
      : "parcelExportById";
    return actAndNotify(mutate, slug, {
      notify,
      mutateOptions,
      successMessage:
        "Requested CSV export. You will be notified when it's ready."
    });
  }

  const { data: categoriesData, loading: categoriesLoading } = useQuery(
    CurrentUserAllCategoriesQuery,
    { variables: { first: 5000 } }
  );
  const { data: categoryGroupingsData, loading: categoryGroupingsLoading } =
    useQuery(CurrentUserAllCategoryGroupingsQuery, {
      variables: { first: 5000 }
    });

  const toggleSelectedCategory = function (name) {
    return () => setSelectedCategories(_.xor(selectedCategories, [name]));
  };

  const toggleSelectedCategoryGrouping = function (name) {
    return () =>
      setSelectedCategoryGroupings(_.xor(selectedCategoryGroupings, [name]));
  };

  const alphabeticalCategories = _.map(
    _.sortBy(
      _.map(
        _.get(categoriesData, "currentUser.allCategories.edges", []),
        "node"
      ),
      ({ name }) => _.lowerCase(name)
    ),
    ({ name }) => ({
      label: name
    })
  );

  const alphabeticalCategoryGroupings = _.map(
    _.sortBy(
      _.map(
        _.get(
          categoryGroupingsData,
          "currentUser.allCategoryGroupings.edges",
          []
        ),
        "node"
      ),
      ({ name }) => _.lowerCase(name)
    ),
    ({ name }) => ({
      label: name
    })
  );

  return (
    <Grid container className={classes.container} data-testid="owner-parcels">
      <SiteWrapper>
        <SidenavWrapper
          Component={props => (
            <DynamicSidenav
              showLogout={_.get(data, "currentUser", null) !== null}
              {...props}
            />
          )}
        >
          <Grid item xs container className={classes.heightFix}>
            <Grid item xs={12} className={classes.appBarWrapper}>
              <AppBar position="static">
                <Toolbar>
                  <Grid
                    container
                    alignItems="flex-start"
                    justifyContent="space-evenly"
                    direction="row"
                    className={classes.toolbarGrid}
                  >
                    <MultiFilter
                      xs={6}
                      size="small"
                      autocompleteOptions={alphabeticalCategories}
                      loading={categoriesLoading}
                      title="Categories"
                      onChange={(event, newValue) => {
                        if (newValue.label) {
                          toggleSelectedCategory(newValue.label)();
                        }
                      }}
                      isOptionEqualToValue={(option, value) =>
                        option.label === value.label
                      }
                      selectedValues={selectedCategories}
                      toggleSelectedValue={toggleSelectedCategory}
                      collapsible={false}
                      renderChips={() =>
                        _.map(selectedCategories, name => (
                          <Chip
                            key={name}
                            label={`${name}`}
                            variant="filled"
                            color="warning"
                            data-testid={`categories-${name}`}
                            onDelete={toggleSelectedCategory(name)}
                            deleteIcon={<Clear />}
                            className={classes.categoryChip}
                          />
                        ))
                      }
                    />
                    <MultiFilter
                      xs={6}
                      size="small"
                      autocompleteOptions={alphabeticalCategoryGroupings}
                      loading={categoryGroupingsLoading}
                      title="Property Type"
                      onChange={(event, newValue) => {
                        if (newValue.label) {
                          toggleSelectedCategoryGrouping(newValue.label)();
                        }
                      }}
                      isOptionEqualToValue={(option, value) => {
                        return option.label === value.label;
                      }}
                      selectedValues={selectedCategoryGroupings}
                      toggleSelectedValue={toggleSelectedCategoryGrouping}
                      collapsible={false}
                      renderChips={() =>
                        _.map(selectedCategoryGroupings, name => (
                          <Chip
                            key={name}
                            label={`${name}`}
                            variant="filled"
                            color="warning"
                            data-testid={`categorygrouping-${name}`}
                            onDelete={toggleSelectedCategoryGrouping(name)}
                            deleteIcon={<Clear />}
                            className={classes.categoryChip}
                          />
                        ))
                      }
                    />
                  </Grid>
                </Toolbar>
              </AppBar>
            </Grid>
            <Grid item xs={12} container alignItems="flex-start">
              <Grid item xs={12}>
                <Typography variant="h4" align="center">
                  {_.get(data, "currentUser.multiOwner.name")}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <ParcelInfoTableInner
                  parcels={parcels}
                  loading={loading}
                  paginationProps={paginationProps}
                  handleRequestCsvUpload={handleRequestCsvUpload}
                  withExportSelection
                />
              </Grid>
            </Grid>
          </Grid>
        </SidenavWrapper>
      </SiteWrapper>
    </Grid>
  );
}

OwnerPage.propTypes = {
  site: PropTypes.string
};

export default withNotifications(OwnerPage);
