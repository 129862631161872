import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  FormControl,
  Button,
  Select,
  MenuItem,
  Grid
} from "@mui/material";

function ComparableParcelAddDialog({ open, onClose, perform }) {
  const [compType, setCompType] = useState("");
  const onSubmit = async e => {
    e.preventDefault();
    await perform(compType);
    onClose();
  };
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      data-testid="add-comparable"
      fullWidth
    >
      <DialogTitle id="add-comparable-title">Add Comparable</DialogTitle>
      <DialogContent>
        <form onSubmit={onSubmit}>
          <FormControl fullWidth>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Select
                  labelId="select-comp-type"
                  data-testid="change-comp-type"
                  id="select-comp-type"
                  value={compType}
                  onChange={e => setCompType(e.target.value)}
                  label="Select a comp type"
                  variant="standard"
                  fullWidth
                >
                  <MenuItem value="AS_IS">AS-IS</MenuItem>
                  <MenuItem value="RENTAL">RENTAL</MenuItem>
                  <MenuItem value="LISTED">LISTED</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={4} />
              <Grid item xs={4}>
                <Button variant="contained" fullWidth onClick={onClose}>
                  Cancel
                </Button>
              </Grid>
              <Grid item xs={4}>
                <Button
                  data-testid="submit-add-comparable"
                  type="submit"
                  variant="contained"
                  disabled={compType === ""}
                  fullWidth
                  color="primary"
                >
                  Add
                </Button>
              </Grid>
            </Grid>
          </FormControl>
        </form>
      </DialogContent>
    </Dialog>
  );
}

ComparableParcelAddDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  perform: PropTypes.func,
  titleText: PropTypes.string
};

export default ComparableParcelAddDialog;
