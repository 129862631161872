import React, { useState, useEffect } from "react";
import _ from "lodash";
import { Grid, Typography, Paper, IconButton } from "@mui/material";
import { HighlightOff } from "@mui/icons-material";
import {
  incomeBrackets,
  summarizableDemographics,
  p8Data,
  p7Data
} from "ui/lib/demographics";

function IncomeBracketLegend() {
  return (
    <>
      {_.map(incomeBrackets, ({ title, color }) => (
        <Grid item container xs={12} key={title}>
          <Grid item xs={1} sx={{ background: color }}>
            &nbsp;&nbsp;&nbsp;&nbsp;
          </Grid>
          <Grid item xs={11}>
            <Typography variant="caption">&nbsp;&nbsp;{title}</Typography>
          </Grid>
        </Grid>
      ))}
    </>
  );
}

function DemographicSummaryLegend() {
  return (
    <>
      {_.map(
        _.pick({ ...p8Data, ...p7Data }, summarizableDemographics),
        ({ title, color }) => (
          <Grid item container xs={12} key={title}>
            <Grid item xs={1} sx={{ background: color }}>
              &nbsp;&nbsp;&nbsp;&nbsp;
            </Grid>
            <Grid item xs={11}>
              <Typography variant="caption">&nbsp;&nbsp;{title}</Typography>
            </Grid>
          </Grid>
        )
      )}
    </>
  );
}

function Legend({ demographicSelection }) {
  const [showLegend, setShowLegend] = useState(true);

  useEffect(() => {
    setShowLegend(true);
  }, [demographicSelection]);

  return _.includes(["S1901_C01_012E", "summary"], demographicSelection) ? (
    <Grid
      container
      id="maplegend"
      sx={{
        visibility: showLegend ? "visible" : "hidden",
        position: "absolute",
        bottom: 0,
        right: 0,
        maxWidth: 300,
        paddingRight: "5px",
        zIndex: 100000
      }}
    >
      <Paper elevation={16} sx={{ width: "100%", padding: "5px" }}>
        <Grid
          item
          xs={12}
          container
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={10}>
            <Typography variant="subtitle" sx={{ paddingTop: "2px" }}>
              Legend
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <IconButton onClick={() => setShowLegend(false)}>
              <HighlightOff />
            </IconButton>
          </Grid>
        </Grid>
        {demographicSelection === "S1901_C01_012E" && <IncomeBracketLegend />}
        {demographicSelection === "summary" && <DemographicSummaryLegend />}
      </Paper>
    </Grid>
  ) : null;
}

export default Legend;
