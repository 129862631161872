import React from "react";
import _ from "lodash";
import { useQuery } from "@apollo/client";
import { useParams, useNavigate } from "react-router-dom";
import { Grid, Typography, AppBar, Tabs, Tab, Chip } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Loader from "ui/components/Loader";
import NearbyPlaces from "ui/components/NearbyPlaces/NearbyPlaces";
import LeadsTable from "ui/sites/acquisitions/components/LeadsTable";
import InboundLeadInfo from "ui/sites/acquisitions/components/InboundLeadInfo";
import CurrentSalespersonParcelDetailsQuery from "ui/sites/acquisitions/queries/CurrentSalespersonParcelDetailsQuery.graphql";
import { withLeadActions } from "ui/sites/acquisitions/contexts/LeadActionsContext";
import AcquisitionsParcelAssessmentContainer from "ui/sites/acquisitions/containers/AcquisitionsParcelAssessmentContainer";
import AcquisitionsParcelDeedsContainer from "ui/sites/acquisitions/containers/AcquisitionsParcelDeedsContainer";
import AcquisitionsParcelNodsContainer from "ui/sites/acquisitions/containers/AcquisitionsParcelNodsContainer";
import AcquisitionsParcelMortgagesContainer from "ui/sites/acquisitions/containers/AcquisitionsParcelMortgagesContainer";
import AcquisitionsParcelNotesContainer from "ui/sites/acquisitions/containers/AcquisitionsParcelNotesContainer";
import AcquisitionsParcelDocumentsContainer from "ui/sites/acquisitions/containers/AcquisitionsParcelDocumentsContainer";
import AcquisitionsParcelImagesContainer from "ui/sites/acquisitions/containers/AcquisitionsParcelImagesContainer";
import AcquisitionsParcelAuditlogContainer from "ui/sites/acquisitions/containers/AcquisitionsParcelAuditlogContainer";
import AcquisitionsParcelPageMap from "./AcquisitionsParcelPageMap";

const useStyles = makeStyles(theme => ({
  tab: {
    minWidth: 100
  },
  categoryChipWrapper: {
    marginRight: theme.spacing(1)
  }
}));

function AcquisitionsParcelPage() {
  const classes = useStyles();
  const navigate = useNavigate();
  const params = useParams();
  const { parcelId } = params;

  const { data, loading } = useQuery(CurrentSalespersonParcelDetailsQuery, {
    variables: { parcelId }
  });

  if (loading) {
    return <Loader />;
  }

  if (!data.currentSalesperson?.parcel) {
    // todo, better error view
    return <p>There was an error.</p>;
  }

  const { parcel } = data.currentSalesperson;

  const subPages = [
    {
      label: "Map",
      slug: "map",
      Component: () => (
        <Grid item xs={6}>
          <AcquisitionsParcelPageMap parcel={parcel} />
        </Grid>
      )
    },
    {
      label: "Notes",
      slug: "notes",
      Component: () => (
        <Grid item xs={12}>
          <AcquisitionsParcelNotesContainer parcelId={parcel.id} />
        </Grid>
      )
    },
    {
      label: "Documents",
      slug: "documents",
      Component: () => (
        <Grid item xs={12}>
          <AcquisitionsParcelDocumentsContainer parcelId={parcel.id} />
        </Grid>
      )
    },
    {
      label: "Images",
      slug: "images",
      Component: () => (
        <Grid item xs={12}>
          <AcquisitionsParcelImagesContainer parcelId={parcel.id} />
        </Grid>
      )
    },
    {
      label: "Auditlog",
      slug: "auditlog",
      Component: () => (
        <Grid item xs={12}>
          <AcquisitionsParcelAuditlogContainer parcelId={parcel.id} />
        </Grid>
      )
    },
    {
      label: "Assessor",
      slug: "assessor",
      Component: () => (
        <Grid item xs={12}>
          <AcquisitionsParcelAssessmentContainer parcelId={parcel.id} />
        </Grid>
      )
    },
    {
      label: "Mortgage",
      slug: "mortage",
      Component: () => (
        <Grid item xs={12}>
          <AcquisitionsParcelMortgagesContainer parcelId={parcel.id} />
        </Grid>
      )
    },
    {
      label: "Deed",
      slug: "deed",
      Component: () => (
        <Grid item xs={12}>
          <AcquisitionsParcelDeedsContainer parcelId={parcel.id} />
        </Grid>
      )
    },
    {
      label: "NOD",
      slug: "nod",
      Component: () => (
        <Grid item xs={12}>
          <AcquisitionsParcelNodsContainer parcelId={parcel.id} />
        </Grid>
      )
    },
    {
      label: "Nearby Places",
      slug: "nearby-places",
      Component: () => (
        <Grid item xs={12}>
          <NearbyPlaces parcel={parcel} />
        </Grid>
      )
    }
  ];

  if (parcel.inboundlead) {
    subPages.push({
      label: "Inbound Lead",
      slug: "inbound-lead",
      Component: () => (
        <Grid item xs={12}>
          <InboundLeadInfo parcel={parcel} />
        </Grid>
      )
    });
  }

  const tabValue = _.findIndex(
    subPages,
    ({ slug }) => slug === _.get(params, "subpage", "map")
  );

  const leadSalesperson = parcel?.currentLead?.salesperson;
  const onTabChange = (e, newValue) =>
    navigate(`/acquisitions/parcel/${parcelId}/${subPages[newValue].slug}`);

  const { Component } = subPages[tabValue];

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <AppBar
          position="fixed"
          sx={{ top: "auto", bottom: 0, alignItems: "center" }}
        >
          <Tabs
            value={tabValue}
            onChange={onTabChange}
            textColor="inherit"
            variant="scrollable"
            scrollButtons="auto"
          >
            {subPages.map(({ label }, i) => (
              <Tab label={label} key={`subpage-${i}`} className={classes.tab} />
            ))}
          </Tabs>
        </AppBar>
      </Grid>
      <Grid item xs={12}>
        <LeadsTable
          leads={[{ ...parcel.currentLead, parcel }]}
          noLinks
          withPortfolioManagement
        />
      </Grid>
      <Grid
        item
        container
        xs={12}
        className={classes.categories}
        justifyContent="flex-end"
      >
        {_.map(
          _.uniqBy(_.get(parcel, "categories.edges", []), "node.id"),
          ({ node }) => (
            <Grid
              item
              className={classes.categoryChipWrapper}
              key={`${parcel.id}-${node.id}`}
            >
              <Chip label={node.name} />
            </Grid>
          )
        )}
      </Grid>
      {leadSalesperson && leadSalesperson.id !== data.currentSalesperson.id && (
        <Grid item xs={12}>
          <Typography align="center">
            Currently claimed by {leadSalesperson.user.email}
          </Typography>
        </Grid>
      )}
      {!leadSalesperson && (
        <Grid item xs={12}>
          <Typography align="center">
            You can claim this lead by selecting a lead status.
          </Typography>
        </Grid>
      )}
      <Grid
        item
        xs={12}
        container
        justifyContent="center"
        className={classes.subsections}
      >
        <Component />
      </Grid>
    </Grid>
  );
}

export default withLeadActions(AcquisitionsParcelPage);
