import React, { useContext } from "react";
import PropTypes from "prop-types";
import { IconButton } from "@mui/material";
import { HighlightOff } from "@mui/icons-material";
import actAndNotify from "ui/lib/actAndNotify";
import NotificationsContext from "ui/contexts/NotificationsContext";
import { useLeadActions } from "ui/sites/acquisitions/contexts/LeadActionsContext";

function RemoveLeadAction({ parcelId }) {
  const { notify } = useContext(NotificationsContext);
  const { removeLead, refetchQueries } = useLeadActions(parcelId);
  async function handleSubmit() {
    await actAndNotify(removeLead, "leadRemove", {
      notify,
      mutateOptions: { variables: { parcelId }, refetchQueries },
      successMessage: "Successfully removed lead!"
    })();
  }
  return (
    <IconButton
      color="secondary"
      aria-label="delete"
      data-testid="remove-lead"
      onClick={handleSubmit}
      size="large"
    >
      <HighlightOff />
    </IconButton>
  );
}

RemoveLeadAction.propTypes = {
  parcelId: PropTypes.string.isRequired
};

export default RemoveLeadAction;
