import makeStyles from "@mui/styles/makeStyles";
import { navBackground } from "ui/themes/AlineaTheme/colors";
export default makeStyles(theme => ({
  sidenav: {
    backgroundColor: navBackground,
    height: "100%",
    minHeight: "150vh"
  },
  itemIcon: {
    color: "rgba(230, 230, 230, 0.7)"
  },
  itemText: {
    color: "rgba(230, 230, 230, 0.7)"
  }
}));
