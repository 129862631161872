import React, { useState } from "react";
import _ from "lodash";
import Loader from "ui/components/Loader";
import { useQuery } from "@apollo/client";
import { Link as RouterLink } from "react-router-dom";
import {
  Grid,
  Link,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography
} from "@mui/material";
import CurrentSalespersonParcelDeedsQuery from "ui/sites/acquisitions/queries/CurrentSalespersonParcelDeedsQuery.graphql";

function AcquisitionsParcelDeedsContainer({ parcelId }) {
  const [selectedDeed, setSelectedDeed] = useState(0);
  const { data, loading } = useQuery(CurrentSalespersonParcelDeedsQuery, {
    variables: { parcelId }
  });
  if (loading) {
    return <Loader />;
  }

  const deeds = _.reverse(
    _.sortBy(
      _.map(_.get(data, "currentSalesperson.parcel.deedSet.edges", []), "node"),
      "originalDateOfContract"
    )
  );

  if (_.isEmpty(deeds)) {
    // todo, better error view
    return <p>No deeds found for parcel.</p>;
  }

  const deed = deeds[selectedDeed];

  const fields = _.omit(deed, ["__typename", "lender", "id"]);

  const retrievedFields = _.map(fields, (value, key) => ({
    key,
    value
  }));
  const [unpopulatedFields, populatedFields] = _.partition(
    retrievedFields,
    ({ value }) => _.isEmpty(value)
  );

  return (
    <Grid container>
      <Grid item xs={12}>
        <Paper elevation={1}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Date</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>Sale Price</TableCell>
                  <TableCell>Lender</TableCell>
                  <TableCell>Buyer Address</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {_.map(deeds, (node, i) => (
                  <TableRow
                    key={i}
                    selected={i === selectedDeed}
                    onClick={() => setSelectedDeed(i)}
                  >
                    <TableCell>{node.originalDateOfContract}</TableCell>
                    <TableCell>{node.loanType}</TableCell>
                    <TableCell>
                      {new Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: "USD",
                        useGrouping: true,
                        currencyDisplay: "narrowSymbol"
                      }).format(node.salesPrice)}
                    </TableCell>
                    <TableCell>
                      <Link
                        component={RouterLink}
                        to={`/lender/${_.get(node, "lender.id")}`}
                        underline="hover"
                      >
                        {node.lenderName}
                      </Link>
                    </TableCell>
                    <TableCell>{node.buyerMailAddress}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Grid>
      <Grid item xs={12} sx={{ marginTop: 5, marginBottom: 2 }}>
        <Typography align="center" variant="h4">
          {deed.originalDateOfContract}
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ paddingBottom: 10 }}>
        <Paper elevation={1}>
          <TableContainer>
            <Table>
              <TableBody>
                {_.map(_.sortBy(populatedFields, "key"), ({ value, key }) => (
                  <TableRow key={key}>
                    <TableCell>
                      <b>{_.startCase(key)}</b>
                    </TableCell>
                    <TableCell>{value}</TableCell>
                  </TableRow>
                ))}
                {_.map(_.sortBy(unpopulatedFields, "key"), ({ value, key }) => (
                  <TableRow key={key}>
                    <TableCell>
                      <b>{_.startCase(key)}</b>
                    </TableCell>
                    <TableCell>{value}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Grid>
    </Grid>
  );
}

export default AcquisitionsParcelDeedsContainer;
