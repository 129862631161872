import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import { useParams, useNavigate } from "react-router-dom";
import { Grid, AppBar, Tabs, Tab } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { loginRequired } from "ui/providers/CurrentUserProvider";
import { withNotifications } from "ui/contexts/NotificationsContext";
import { SiteWrapper } from "ui/contexts/SiteContext";
import CurrentUserContext from "ui/contexts/CurrentUserContext";
import DynamicSidenav from "ui/components/DynamicSidenav";
import { SidenavWrapper } from "ui/contexts/SidenavContext";
import CurrentUserAllCategoriesQuery from "ui/queries/CurrentUserAllCategoriesQuery.graphql";
import CurrentUserMyCategoriesQuery from "ui/queries/CurrentUserMyCategoriesQuery.graphql";
import CategoriesList from "./CategoriesList";

const useStyles = makeStyles(theme => ({
  container: {
    height: "100%"
  },
  tab: {
    minWidth: 100
  }
}));

function CategoriesPage() {
  const classes = useStyles();
  const navigate = useNavigate();
  const params = useParams();
  const [tabValue, setTabValue] = useState(
    params.categoryType === "all" ? 1 : 0
  );
  const { user } = useContext(CurrentUserContext);

  const subPages = [
    {
      slug: "mine",
      query: CurrentUserMyCategoriesQuery,
      accessor: "myCategories"
    },
    {
      slug: "all",
      query: CurrentUserAllCategoriesQuery,
      accessor: "allCategories"
    }
  ];

  const onTabChange = (e, newValue) => {
    navigate(`/categories/${subPages[newValue].slug}`);
    setTabValue(newValue);
  };

  return (
    <Grid
      container
      className={classes.container}
      data-testid="notifications-page"
    >
      <SiteWrapper>
        <SidenavWrapper
          Component={props => (
            <DynamicSidenav showLogout={user !== null} {...props} />
          )}
        >
          <Grid item xs container>
            <Grid item xs={12}>
              <AppBar position="static">
                <Tabs
                  value={tabValue}
                  onChange={onTabChange}
                  textColor="inherit"
                  variant="scrollable"
                  scrollButtons="auto"
                >
                  <Tab label="My Categories" className={classes.tab} />
                  <Tab label="All Categories" className={classes.tab} />
                </Tabs>
              </AppBar>
              <CategoriesList {...subPages[tabValue]} />
            </Grid>
          </Grid>
        </SidenavWrapper>
      </SiteWrapper>
    </Grid>
  );
}

CategoriesPage.propTypes = {
  site: PropTypes.string
};

export default loginRequired(withNotifications(CategoriesPage));
