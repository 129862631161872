import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  TablePagination,
  LinearProgress,
  Button,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { MailOutline } from "@mui/icons-material";
import NotificationRow from "./NotificationRow";

const useStyles = makeStyles(() => ({
  table: { width: "100%" }
}));

function Notifications({
  loading,
  markAsRead,
  markAllAsRead,
  notifications,
  paginationProps,
  showLinks
}) {
  const classes = useStyles();
  return (
    <TableContainer
      component={Paper}
      data-testid="notifications-table-container"
    >
      <Table
        className={classes.table}
        size="small"
        data-testid="notifications-table"
      >
        <TableBody>
          <TableRow>
            <TableCell colSpan={4} align="right">
              <Button
                color="secondary"
                variant="contained"
                size="small"
                startIcon={<MailOutline />}
                onClick={markAllAsRead}
              >
                Mark All as Read
              </Button>
            </TableCell>
          </TableRow>
          {_.map(notifications, notification => (
            <NotificationRow
              notification={notification}
              markAsRead={markAsRead}
              key={notification.id}
            />
          ))}
          <TableRow>
            {loading && (
              <TableCell colSpan={4}>
                <LinearProgress data-testid="loading" />
              </TableCell>
            )}
            {!loading && paginationProps && (
              <TablePagination {...paginationProps} />
            )}
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}

Notifications.propTypes = {
  notifications: PropTypes.arrayOf(PropTypes.object).isRequired,
  paginationProps: PropTypes.object,
  markAsRead: PropTypes.func
};

export default Notifications;
