import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import currency from "currency.js";
import { useMutation } from "@apollo/client";
import {
  Grid,
  Typography,
  TextField,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableFooter,
  Button,
  IconButton,
  LinearProgress
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { RemoveCircle, AddCircle } from "@mui/icons-material";
import actAndNotify from "ui/lib/actAndNotify";
import NotificationsContext from "ui/contexts/NotificationsContext";
import CurrentUnderwriterParcelDetailsQuery from "ui/sites/underwriting/queries/CurrentUnderwriterParcelDetailsQuery.graphql";
import AsIsBudgetWorksheetStoreMutation from "ui/sites/underwriting/queries/AsIsBudgetWorksheetStoreMutation.graphql";
import ListedBudgetWorksheetStoreMutation from "ui/sites/underwriting/queries/ListedBudgetWorksheetStoreMutation.graphql";
import RentalBudgetWorksheetStoreMutation from "ui/sites/underwriting/queries/RentalBudgetWorksheetStoreMutation.graphql";
import DEFAULT_PRICING from "./pricing";

const useStyles = makeStyles({
  settings: {
    paddingRight: 10,
    paddingTop: 10
  },
  table: {
    minWidth: 350
  },
  header: {
    marginBottom: 10
  }
});

const asCurrency = val => currency(val, { precision: 2 }).format();

const compTypesToBudgetConfigs = {
  AS_IS: {
    Mutation: AsIsBudgetWorksheetStoreMutation,
    mutationSlug: "asIsBudgetWorksheetStore",
    slug: "asisbudgetworksheet"
  },
  LISTED: {
    Mutation: ListedBudgetWorksheetStoreMutation,
    mutationSlug: "listedBudgetWorksheetStore",
    slug: "listedbudgetworksheet"
  },
  RENTAL: {
    slug: "rentalbudgetworksheet",
    Mutation: RentalBudgetWorksheetStoreMutation,
    mutationSlug: "rentalBudgetWorksheetStore"
  }
};

function MiniBudgetWidget({ parcel, compType }) {
  const classes = useStyles();
  const defaultSqft = _.toNumber(
    _.get(JSON.parse(parcel.currentAssessment.data), "AreaBuilding", 0)
  );
  const { slug, mutationSlug, Mutation } = compTypesToBudgetConfigs[compType];
  const lineItemsOrDefaultPricing = _.get(
    parcel,
    `currentRequest.${slug}.lineItems`,
    DEFAULT_PRICING
  ).map(({ __typename = null, ...val }) => ({
    ...val,
    qty:
      (val.qty === null || val.qty === undefined) && val.unit === "SQFT"
        ? defaultSqft
        : val.qty
  }));
  const [lineItems, setLineItems] = useState(lineItemsOrDefaultPricing);
  const { notify } = useContext(NotificationsContext);
  const [mutate, { loading }] = useMutation(Mutation, {
    variables: {
      parcelId: parcel.id,
      lineItems: lineItems.map(({ creating = false, ...vals }) => vals)
    },
    refetchQueries: [
      {
        query: CurrentUnderwriterParcelDetailsQuery,
        variables: { parcelId: parcel.id }
      }
    ]
  });

  let lowTotal = 0;
  let highTotal = 0;

  return (
    <Grid item xs={12} container>
      <Grid item xs={12}>
        <Typography align="center" variant="h4" className={classes.header}>
          Budget
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <TableContainer component={Paper}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell>Line Item</TableCell>
                <TableCell>Unit</TableCell>
                <TableCell>Cost / Unit (low)</TableCell>
                <TableCell>Cost / Unit (high)</TableCell>
                <TableCell align="right">Quantity</TableCell>
                <TableCell align="right">Total (low)</TableCell>
                <TableCell align="right">Total (high)</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {_.map(
                lineItems,
                ({ label, unit, qty = 0, creating = false, ...costs }, i) => {
                  const lowLineTotal = (costs.low || costs.high) * qty;
                  const highLineTotal = (costs.high || costs.low) * qty;
                  lowTotal += lowLineTotal;
                  highTotal += highLineTotal;
                  return (
                    <TableRow
                      key={i}
                      data-testid={creating ? "new-row" : `row-${i}`}
                    >
                      <TableCell>
                        <IconButton
                          onClick={() =>
                            setLineItems([
                              ...lineItems.slice(0, i),
                              ...lineItems.slice(i + 1, lineItems.length)
                            ])
                          }
                          size="large"
                        >
                          <RemoveCircle color="secondary" />
                        </IconButton>
                      </TableCell>
                      <TableCell>
                        {!creating && label}
                        {creating && (
                          <TextField
                            value={label}
                            onChange={e => {
                              let lineItemsCopy = [...lineItems];
                              lineItemsCopy[i] = {
                                label: e.target.value,
                                unit,
                                creating,
                                qty,
                                ...costs
                              };
                              setLineItems(lineItemsCopy);
                            }}
                          />
                        )}
                      </TableCell>
                      <TableCell>
                        {!creating && unit}
                        {creating && (
                          <TextField
                            value={unit}
                            onChange={e => {
                              let lineItemsCopy = [...lineItems];
                              lineItemsCopy[i] = {
                                label,
                                unit: e.target.value,
                                creating,
                                qty,
                                ...costs
                              };
                              setLineItems(lineItemsCopy);
                            }}
                          />
                        )}
                      </TableCell>
                      <TableCell>
                        {!creating && asCurrency(costs.low)}
                        {creating && (
                          <TextField
                            value={costs.low}
                            onChange={e => {
                              let lineItemsCopy = [...lineItems];
                              lineItemsCopy[i] = {
                                label,
                                unit,
                                creating,
                                qty,
                                ...costs,
                                low: e.target.value
                              };
                              setLineItems(lineItemsCopy);
                            }}
                          />
                        )}
                      </TableCell>
                      <TableCell>
                        {!creating && asCurrency(costs.high)}
                        {creating && (
                          <TextField
                            value={costs.high}
                            onChange={e => {
                              let lineItemsCopy = [...lineItems];
                              lineItemsCopy[i] = {
                                label,
                                unit,
                                creating,
                                qty,
                                ...costs,
                                high: e.target.value
                              };
                              setLineItems(lineItemsCopy);
                            }}
                          />
                        )}
                      </TableCell>
                      <TableCell align="right">
                        <TextField
                          inputProps={{ style: { textAlign: "right" } }}
                          value={qty || ""}
                          onChange={e => {
                            let lineItemsCopy = [...lineItems];
                            lineItemsCopy[i] = {
                              label,
                              unit,
                              creating,
                              qty: _.toNumber(e.target.value),
                              ...costs
                            };
                            setLineItems(lineItemsCopy);
                          }}
                        />
                      </TableCell>
                      <TableCell align="right">
                        {asCurrency(lowLineTotal)}
                      </TableCell>
                      <TableCell align="right">
                        {asCurrency(highLineTotal)}
                      </TableCell>
                    </TableRow>
                  );
                }
              )}
              <TableRow>
                <TableCell colSpan={8}>
                  <IconButton
                    data-testid="add-row"
                    onClick={() =>
                      setLineItems([
                        ...lineItems,
                        {
                          label: "",
                          unit: "",
                          low: "",
                          high: "",
                          qty: "",
                          creating: true
                        }
                      ])
                    }
                    size="large"
                  >
                    <AddCircle color="primary" />
                  </IconButton>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={6} align="right">
                  <b>Total</b>
                </TableCell>
                <TableCell>{asCurrency(lowTotal)}</TableCell>
                <TableCell>{asCurrency(highTotal)}</TableCell>
              </TableRow>
            </TableBody>
            <TableFooter align="right">
              <TableRow>
                <TableCell colSpan={8} align="right">
                  {loading && <LinearProgress />}
                  {!loading && (
                    <Button
                      size="large"
                      variant="contained"
                      color="primary"
                      onClick={actAndNotify(mutate, mutationSlug, {
                        notify,
                        successMessage: "Worksheet Saved!"
                      })}
                      data-testid="save-button"
                    >
                      Save
                    </Button>
                  )}
                </TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
}

MiniBudgetWidget.propTypes = {
  parcel: PropTypes.object.isRequired
};

export default MiniBudgetWidget;
