import React from "react";
import { Routes as Switch, Route } from "react-router-dom";
import AcquisitionsIndexPage from "ui/sites/acquisitions/pages/AcquisitionsIndexPage";
import AcquisitionsParcelsPage from "ui/sites/acquisitions/pages/AcquisitionsParcelsPage";
import AcquisitionsParcelsCursorPage from "ui/sites/acquisitions/pages/AcquisitionsParcelsCursorPage";
import AcquisitionsParcelPage from "ui/sites/acquisitions/pages/AcquisitionsParcelPage";
import AcquisitionsTeamLeadsPage from "ui/sites/acquisitions/pages/AcquisitionsTeamLeadsPage";
import AcquisitionsInboundLeadsPage from "ui/sites/acquisitions/pages/AcquisitionsInboundLeadsPage";

function Routes() {
  return (
    <Switch>
      <Route
        path="/parcel/:parcelId/:subpage/:subsection"
        element={<AcquisitionsParcelPage />}
      />
      <Route
        path="/parcel/:parcelId/:subpage"
        element={<AcquisitionsParcelPage />}
      />
      <Route path="/parcel/:parcelId" element={<AcquisitionsParcelPage />} />
      <Route path="/team-leads" element={<AcquisitionsTeamLeadsPage />} />
      <Route path="/inbound-leads" element={<AcquisitionsInboundLeadsPage />} />
      <Route
        path="/inbound-leads/uploads"
        element={<AcquisitionsInboundLeadsPage />}
      />
      <Route
        path="/parcels/:cursorId"
        element={<AcquisitionsParcelsCursorPage />}
      />
      <Route path="/parcels" element={<AcquisitionsParcelsPage />} />
      <Route path="/parcels/*" element={<AcquisitionsParcelsPage />} />
      <Route path="/" element={<AcquisitionsIndexPage />} />
    </Switch>
  );
}

export default Routes;
