import React from "react";
import _ from "lodash";
import { useQuery, useLazyQuery } from "@apollo/client";
import { Grid, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Loader from "ui/components/Loader";
import { SiteWrapper } from "ui/contexts/SiteContext";
import DynamicSidenav from "ui/components/DynamicSidenav";
import { loginRequired } from "ui/providers/CurrentUserProvider";
import useCursors from "ui/hooks/useCursors";
import usePagination from "ui/hooks/usePagination";
import { SidenavWrapper } from "ui/contexts/SidenavContext";
import CurrentUserExportsQuery from "ui/queries/CurrentUserExportsQuery.graphql";
import CurrentUserExportQuery from "ui/queries/CurrentUserExportQuery.graphql";
import ExportsList from "./ExportsList";

const useStyles = makeStyles(theme => ({
  container: {
    height: "100%"
  }
}));

function ExportsPage() {
  const classes = useStyles();
  const cursors = useCursors();
  const variables = _.pick(cursors, ["first", "after"]);
  const [runQuery] = useLazyQuery(CurrentUserExportQuery);
  const { loading, data } = useQuery(CurrentUserExportsQuery, {
    variables
  });
  const { paginationProps } = usePagination({
    nextAfter: _.get(data, "currentUser.exports.pageInfo.endCursor"),
    count: _.get(data, "currentUser.exports.pageInfo.totalCount"),
    ...cursors
  });
  const exports = _.map(_.get(data, "currentUser.exports.edges", []), "node");

  function getDownloadUrl(exportId) {
    return async () => {
      const { data } = await runQuery({ variables: { exportId } });
      const ele = document.createElement("a");
      ele.href = _.get(data, "currentUser.export.downloadUrl");
      ele.download = true;
      document.body.appendChild(ele);
      ele.click();
      document.body.removeChild(ele);
    };
  }

  return (
    <Grid container className={classes.container} data-testid="exports-page">
      <SiteWrapper>
        <SidenavWrapper
          Component={props => (
            <DynamicSidenav
              showLogout={data?.currentUser !== null}
              {...props}
            />
          )}
        >
          <Grid item xs container>
            {loading && <Loader />}
            {!loading && (
              <Grid item xs={12}>
                <Typography align="center" variant="h2">
                  Exports
                </Typography>
                <ExportsList
                  loading={loading}
                  exports={exports}
                  paginationProps={paginationProps}
                  getDownloadUrl={getDownloadUrl}
                />
              </Grid>
            )}
          </Grid>
        </SidenavWrapper>
      </SiteWrapper>
    </Grid>
  );
}

export default loginRequired(ExportsPage);
