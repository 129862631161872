const DEFAULT_PRICING = [
  { label: "Demo", unit: "SQFT", high: 1.25 },
  { label: "Framing", unit: "SQFT", high: 4 },
  { label: "Roofing", unit: "SQFT", high: 28 },
  { label: "Siding", unit: "SQFT", high: 33 },
  { label: "Decks", unit: "SQFT", high: 8.5 },
  { label: "Electric", unit: "SQFT", high: 3, low: 2.5 },
  { label: "Tile", unit: "SQFT", high: 3.75, low: 3.35 },
  { label: "Flooring", unit: "SQFT", high: 1.25, low: 1 },
  { label: "Countertops (Labor + Materials)", unit: "SQFT", high: 26 },
  { label: "Ext. Doors/Windows", unit: "door", high: 125, low: 100 },
  { label: "Plumbing (Labor + Materials)", unit: "fixture", high: 350 },
  { label: "HVAC (Labor + Materials)", unit: "ton", high: 2200, low: 1600 },
  { label: "Insulation", unit: "house", high: 3200 },
  { label: "Drywall", unit: "board", high: 21, low: 18 },
  { label: "Trim and Doors", unit: "door", high: 100, low: 75 },
  { label: "Cabinets", unit: "cabinet", high: 45, low: 35 },
  { label: "Appliances", unit: "house", high: 2200 }
];

export default DEFAULT_PRICING;
