import React from "react";
import {
  BrowserRouter as Router,
  Routes as Switch,
  Route
} from "react-router-dom";
import MainLayout from "ui/layouts/MainLayout";
import AcquisitionsSite from "ui/sites/acquisitions/AcquisitionsSite";
import UnderwritingSite from "ui/sites/underwriting/UnderwritingSite";
import SiteSelectionPage from "ui/pages/SiteSelectionPage";
import ActivityPage from "ui/pages/ActivityPage";
import NotificationsPage from "ui/pages/NotificationsPage";
import PasswordResetRequestPage from "ui/pages/PasswordResetRequestPage";
import PasswordResetPerformPage from "ui/pages/PasswordResetPerformPage";
import PortfoliosPage from "ui/pages/PortfoliosPage";
import PortfolioPage from "ui/pages/PortfolioPage";
import ExportsPage from "ui/pages/ExportsPage";
import CategoriesPage from "ui/pages/CategoriesPage";
import CategoryPage from "ui/pages/CategoryPage";
import LendersPage from "ui/pages/LendersPage";
import LenderPage from "ui/pages/LenderPage";
import OwnersPage from "ui/pages/OwnersPage";
import OwnerPage from "ui/pages/OwnerPage";
import DemographicMapPage from "ui/pages/DemographicMapPage";

function Routes() {
  return (
    <MainLayout>
      <Router>
        <Switch>
          <Route path="/acquisitions/*" element={<AcquisitionsSite />} />

          <Route path="/underwriting/*" element={<UnderwritingSite />} />
          <Route path="/activity" element={<ActivityPage />} />
          <Route path="/notifications" element={<NotificationsPage />} />
          <Route
            path="/password-reset"
            element={<PasswordResetRequestPage />}
          />
          <Route
            path="/reset-password/:token"
            element={<PasswordResetPerformPage />}
          />
          <Route path="/portfolio/:portfolioId" element={<PortfolioPage />} />
          <Route
            path="/portfolios/:portfolioType"
            element={<PortfoliosPage />}
          />
          <Route path="/portfolios/*" element={<PortfoliosPage />} />
          <Route path="/category/:categoryId" element={<CategoryPage />} />
          <Route
            path="/categories/:categoryType"
            element={<CategoriesPage />}
          />
          <Route path="/categories/*" element={<CategoriesPage />} />
          <Route path="/lenders/*" element={<LendersPage />} />
          <Route path="/lender/:lenderId" element={<LenderPage />} />
          <Route path="/owners/*" element={<OwnersPage />} />
          <Route path="/owner/:ownerId" element={<OwnerPage />} />
          <Route path="/exports/*" element={<ExportsPage />} />
          <Route path="/map" element={<DemographicMapPage />} />
          <Route path="/" element={<SiteSelectionPage />} />
        </Switch>
      </Router>
    </MainLayout>
  );
}

export default Routes;
