import React, { useState } from "react";
import { Dialog, DialogTitle, DialogContent, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import AddressSearchForm from "./AddressSearchForm";
import ParcelFindResultPane from "./ParcelFindResultPane";

const useStyles = makeStyles(theme => ({
  findAddressButton: {
    margin: theme.spacing(1)
  }
}));

function FindOrCreateParcelButton({ expanded }) {
  const [open, setOpen] = useState(false);
  const [step, setStep] = useState(0);
  const [address, setAddress] = useState("");
  const classes = useStyles();

  function onClose() {
    setOpen(false);
    setAddress("");
    setStep(0);
  }

  function onSubmit(e) {
    e.preventDefault();
    setStep(1);
  }

  return (
    <>
      <Button
        className={classes.findAddresButton}
        variant="contained"
        color="info"
        onClick={() => setOpen(true)}
        data-testid="find-create-parcel-button"
        size="small"
        fullWidth
      >
        Find {expanded ? "By Address" : ""}
      </Button>
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth="sm"
        data-testid="find-create-parcel-dialog"
        fullWidth
      >
        <DialogTitle id="find-create-parcel-title">
          Find Parcel by Address
        </DialogTitle>
        <DialogContent>
          {step === 0 && (
            <AddressSearchForm
              onSubmit={onSubmit}
              address={address}
              setAddress={setAddress}
            />
          )}
          {step === 1 && <ParcelFindResultPane address={address} />}
        </DialogContent>
      </Dialog>
    </>
  );
}
export default FindOrCreateParcelButton;
