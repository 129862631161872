export default function showOwnerOccupiedSummary(
  tract,
  surveyData,
  infoWindow,
  map,
  setTract
) {
  return event => {
    // Since this polygon has only one path, we can call getPath() to return the
    // MVCArray of LatLngs.
    // @ts-ignore

    let contentString =
      "<b>Owner-Occupation Data</b><br>" +
      `Census Tract: ${tract.name} <br>` +
      `Owner Occupied: ${surveyData.totalOwnerOccupied} (${Math.floor(
        surveyData.pctOwnerOccupied * 100
      )} %)<br> ` +
      `Renter Occupied: ${surveyData.totalRenterOccupied} (${Math.floor(
        surveyData.pctRenterOccupied * 100
      )} %)<br> `;

    contentString += `<br><a id="view-tract-${tract.id}" href="#">See More</a>`;

    // Replace the info window's content and position.
    infoWindow.setContent(contentString);
    infoWindow.setPosition(event.latLng);
    infoWindow.setZIndex(100000);
    infoWindow.open(map);

    setTimeout(() => {
      document.getElementById(`view-tract-${tract.id}`).onclick = e => {
        e.preventDefault();
        setTract(tract);
      };
    }, 500);
  };
}
