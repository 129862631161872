import React, { useContext } from "react";
import _ from "lodash";
import { Select, MenuItem } from "@mui/material";
import actAndNotify from "ui/lib/actAndNotify";
import NotificationsContext from "ui/contexts/NotificationsContext";
import { useLeadActions } from "ui/sites/acquisitions/contexts/LeadActionsContext";
import CurrentSalespersonContext from "ui/sites/acquisitions/contexts/CurrentSalespersonContext";

function AssignLeadAction({ lead }) {
  const { currentSalesperson } = useContext(CurrentSalespersonContext);
  const { notify } = useContext(NotificationsContext);
  const { assignLead, refetchQueries } = useLeadActions(lead.parcel.id);

  if (
    !lead.salesperson ||
    !currentSalesperson ||
    !currentSalesperson.isSalesManager ||
    (lead.salesperson.id !== currentSalesperson.id &&
      _.get(lead, "salesperson.salesManager.id", null) !==
        currentSalesperson.id)
  ) {
    return _.get(lead, "salesperson.user.email", "");
  }

  function onChangeSalesperson(e) {
    actAndNotify(assignLead, "leadAssign", {
      notify,
      mutateOptions: {
        variables: {
          assignedSalespersonId: e.target.value,
          parcelId: lead.parcel.id
        },
        refetchQueries
      },
      successMessage: "Successfully assigned lead."
    })();
  }

  return (
    <Select
      labelId="select-salesperson"
      data-testid="change-salesperson"
      id="select-salesperson"
      value={lead.salesperson.id}
      onChange={onChangeSalesperson}
      label="Assign a salesperson..."
      variant="standard"
      fullWidth
    >
      <MenuItem value={currentSalesperson.id}>Me</MenuItem>
      {_.map(
        _.get(currentSalesperson, "salespersonSet.edges", []),
        ({ node }) => (
          <MenuItem key={node.id} value={node.id}>
            {node.user.email}
          </MenuItem>
        )
      )}
    </Select>
  );
}

export default AssignLeadAction;
