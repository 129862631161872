import React, { useContext } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { CircularProgress } from "@mui/material";
import GoogleMapReact from "google-maps-react-markers";
import useMapCentering from "ui/hooks/useMapCentering";
import MapContext from "ui/contexts/MapContext";
import ParcelMarker from "ui/components/ParcelMarker";

function AcquisitionsIndexPageLeadsMap({
  selectedParcel,
  setSelectedParcel,
  loading,
  parcels
}) {
  const mapContext = useContext(MapContext);
  const { onGoogleApiLoaded, defaultCenter, center } = mapContext;
  useMapCentering(mapContext, parcels);

  return (
    <div style={{ height: "40vh", width: "100%" }}>
      <GoogleMapReact
        apiKey={process.env.GOOGLE_API_KEY}
        libraries={["places"]}
        defaultCenter={defaultCenter}
        center={center}
        defaultZoom={12}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={onGoogleApiLoaded}
        options={{ streetViewControl: true }}
      >
        {loading && (
          <CircularProgress
            lat={_.get(center, "latitude", defaultCenter.lat)}
            lng={_.get(center, "longitude", defaultCenter.lng)}
          />
        )}
        {_.map(parcels, parcel => {
          const {
            id,
            coordinates: {
              coordinates: [lng, lat]
            }
          } = parcel;
          return (
            <ParcelMarker
              key={id}
              lng={lng}
              lat={lat}
              parcel={parcel}
              open={id === selectedParcel}
              setOpen={x => setSelectedParcel(x)}
            />
          );
        })}
      </GoogleMapReact>
    </div>
  );
}

AcquisitionsIndexPageLeadsMap.propTypes = {
  selectedParcel: PropTypes.object,
  setSelectedParcel: PropTypes.func,
  loading: PropTypes.bool,
  parcels: PropTypes.arrayOf(PropTypes.object)
};

export default AcquisitionsIndexPageLeadsMap;
