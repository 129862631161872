import React, { useEffect } from "react";

import { Grid, Typography, Paper } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { SiteWrapper } from "ui/contexts/SiteContext";
import { SidenavWrapper } from "ui/contexts/SidenavContext";
import { withMapContext } from "ui/contexts/MapContext";
import { withMapLayerContext } from "ui/contexts/MapLayerContext";

import DynamicSidenav from "ui/components/DynamicSidenav";
import MapLayers from "ui/components/MapLayers";
import MapOverlaySelector from "ui/components/MapOverlaySelector";
import DemographicMap from "./DemographicMap";

const useStyles = makeStyles(theme => ({
  container: {
    height: "100%"
  }
}));

function DemographicMapPage() {
  const classes = useStyles();

  useEffect(() => {
    // initialize portal demographics and map overlays
    window.portalDemographics = {};
  });

  return (
    <Grid container className={classes.container} data-testid="demographicmap">
      <SiteWrapper>
        <SidenavWrapper
          Component={props => <DynamicSidenav showLogout {...props} />}
        >
          <Grid item xs container>
            <Grid item xs={12}>
              <Typography align="center" variant="h2">
                Demographics Map
              </Typography>
              <Grid item xs={12}>
                <MapOverlaySelector hideIgnoreMapChanges />
              </Grid>
              <Grid item xs={12}>
                <Paper style={{ height: "100%" }}>
                  <MapLayers>
                    <DemographicMap />
                  </MapLayers>
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        </SidenavWrapper>
      </SiteWrapper>
    </Grid>
  );
}

export default withMapContext(withMapLayerContext(DemographicMapPage));
