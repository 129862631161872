export default function actAndNotify(
  mutate,
  accessor,
  { notify, mutateOptions = {}, successMessage = null, callback = () => {} }
) {
  return async function () {
    try {
      const {
        data: {
          [accessor]: { ok, reason, ...content }
        }
      } = await mutate(mutateOptions);
      if (!ok) {
        throw new Error(reason);
      }
      if (successMessage) {
        notify({ variant: "success", message: successMessage });
      }
      callback(content);
    } catch (e) {
      notify({ variant: "error", message: e.message });
    }
  };
}
