import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import currency from "currency.js";
import { DateTime } from "luxon";
import { Link as RouterLink } from "react-router-dom";
import { Link, TableCell, TableRow } from "@mui/material";
import LeadStatusChip from "ui/components/LeadStatusChip";
import ParcelAddToPorfolioAction from "ui/components/ParcelAddToPorfolioAction";
import ClaimRequestAction from "./ClaimRequestAction";
import DeleteRequestAction from "./DeleteRequestAction";

function RequestRow({
  request,
  noLinks,
  withPortfolioManagement = false,
  canDelete = false
}) {
  const {
    lead: {
      salesperson,
      status,
      expiresOn,
      parcel: { id: parcelId, addressFull, city, state, zipcode, lastSalePrice }
    },
    assignedUnderwriter,
    askingPrice
  } = request;

  const expired = DateTime.fromISO(expiresOn) <= DateTime.local();

  console.log("canDelete is", canDelete);

  return (
    <TableRow>
      <TableCell>
        <LeadStatusChip status={expired ? "EXPIRED" : status} />
      </TableCell>
      <TableCell>{_.get(salesperson, "user.email", "?")}</TableCell>
      <TableCell>
        {noLinks && addressFull}
        {!noLinks && (
          <Link
            component={RouterLink}
            to={`/underwriting/parcel/${parcelId}`}
            underline="hover"
          >
            {addressFull}
          </Link>
        )}
      </TableCell>
      <TableCell>{city}</TableCell>
      <TableCell>{state}</TableCell>
      <TableCell>{zipcode}</TableCell>
      <TableCell>
        {currency(lastSalePrice, { precision: 0 }).format()}
      </TableCell>
      <TableCell>{currency(askingPrice, { precision: 0 }).format()}</TableCell>
      <TableCell>
        {withPortfolioManagement && (
          <ParcelAddToPorfolioAction parcel={request.parcel} />
        )}
        {assignedUnderwriter === null && (
          <ClaimRequestAction request={request} />
        )}
        {canDelete && <DeleteRequestAction request={request} />}
      </TableCell>
    </TableRow>
  );
}

RequestRow.propTypes = {
  noLinks: PropTypes.bool,
  lead: PropTypes.object,
  assignedUnderwriter: PropTypes.object,
  withPortfolioManagement: PropTypes.bool
};

export default RequestRow;
