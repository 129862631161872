import React from "react";
import PropTypes from "prop-types";
import { Paper, Table, TableBody, TableCell, TableHead, TableContainer, TableRow } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { Rating } from '@mui/material';

const useStyles = makeStyles(() => ({
  selected: {
    backgroundColor: "#FFFEE0"
  }
}));

function NearbyPlaceList({ places, selectedPlace }) {
  const classes = useStyles();
  return (
    <TableContainer component={Paper}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>
              <b>Name</b>
            </TableCell>
            <TableCell>
              <b>Address</b>
            </TableCell>
            <TableCell align="right">
              <b>Rating</b>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {places.map(place => (
            <TableRow
              key={place.place_id}
              className={
                place.place_id === selectedPlace ? classes.selected : ""
              }
            >
              <TableCell>{place.name}</TableCell>
              <TableCell>{place.vicinity}</TableCell>
              <TableCell align="right">
                <Rating
                  value={place.rating}
                  readOnly
                  name={`${place.name} Rating`}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

NearbyPlaceList.propTypes = {
  places: PropTypes.arrayOf(PropTypes.object),
  selectedPlace: PropTypes.string
};

export default NearbyPlaceList;
