import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { Link as RouterLink } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";
import {
  Paper,
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableContainer,
  TableRow,
  TableFooter,
  TablePagination,
  LinearProgress,
  Link
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Cancel } from "@mui/icons-material";
import actAndNotify from "ui/lib/actAndNotify";
import NotificationsContext from "ui/contexts/NotificationsContext";
import useCursors from "ui/hooks/useCursors";
import usePagination from "ui/hooks/usePagination";
import CurrentUserAllPortfoliosQuery from "ui/queries/CurrentUserAllPortfoliosQuery.graphql";
import CurrentUserMyPortfoliosQuery from "ui/queries/CurrentUserMyPortfoliosQuery.graphql";
import PortfolioCreateMutation from "ui/queries/PortfolioCreateMutation.graphql";
import PortfolioDeleteMutation from "ui/queries/PortfolioDeleteMutation.graphql";
import CreatePortfolioDialog from "./CreatePortfolioDialog";

const useStyles = makeStyles(() => ({
  table: { width: "100%" }
}));

function PortfoliosList({ query, accessor }) {
  const classes = useStyles();
  const cursors = useCursors();
  const { notify } = useContext(NotificationsContext);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const variables = _.pick(cursors, ["first", "after"]);

  const refetchQueries = [
    { query: CurrentUserAllPortfoliosQuery },
    { query: CurrentUserMyPortfoliosQuery }
  ];

  const { data, loading: queryLoading } = useQuery(query, {
    variables,
    fetchPolicy: "cache-and-network"
  });
  const [portfolioCreate] = useMutation(PortfolioCreateMutation);
  const [portfolioDelete] = useMutation(PortfolioDeleteMutation);
  const count = _.get(data, `currentUser.${accessor}.totalCount`, 0);
  const owned = accessor === "portfolioSet";

  const { paginationProps } = usePagination({
    nextAfter: _.get(data, `currentUser.${accessor}.pageInfo.endCursor`),
    count,
    ...cursors
  });

  async function performCreatePortfolio(name) {
    setLoading(true);
    await actAndNotify(portfolioCreate, "portfolioCreate", {
      notify,
      mutateOptions: { variables: { name }, refetchQueries },
      successMessage: "Portfolio Created!"
    })();
    setLoading(false);
  }

  async function performDeletePortfolio(portfolioId) {
    setLoading(true);
    await actAndNotify(portfolioDelete, "portfolioDelete", {
      notify,
      mutateOptions: { variables: { portfolioId }, refetchQueries },
      successMessage: "Portfolio Deleted!"
    })();
    setLoading(false);
  }

  return (
    <TableContainer component={Paper}>
      <Table
        className={classes.table}
        size="small"
        data-testid="portfolios-table"
      >
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell align="right"># Parcels</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {!loading && count === 0 && (
            <TableRow>
              <TableCell align="center" colSpan={3}>
                <b>No Portfolios!</b>
              </TableCell>
            </TableRow>
          )}
          {_.map(_.get(data, `currentUser.${accessor}.edges`), ({ node }) => (
            <TableRow key={node.id}>
              <TableCell>
                <Link
                  component={RouterLink}
                  to={`/portfolio/${node.id}`}
                  underline="hover"
                >
                  {node.name}
                </Link>
              </TableCell>
              <TableCell align="right">
                {_.get(node, "parcels.totalCount", 0)}
              </TableCell>
              <TableCell align="right">
                {owned && (
                  <IconButton
                    color="secondary"
                    onClick={() => performDeletePortfolio(node.id)}
                    size="large"
                  >
                    <Cancel />
                  </IconButton>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          {owned && (
            <TableRow>
              <TableCell colSpan={3} align="right">
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => setDialogOpen(true)}
                >
                  Create Portfolio
                </Button>
              </TableCell>
            </TableRow>
          )}
          <TableRow>
            {(loading || queryLoading) && (
              <TableCell colSpan={3}>
                <LinearProgress data-testid="loading" />
              </TableCell>
            )}
            {!loading && !queryLoading && paginationProps && (
              <TablePagination {...paginationProps} />
            )}
          </TableRow>
        </TableFooter>
      </Table>
      <CreatePortfolioDialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        perform={performCreatePortfolio}
      />
    </TableContainer>
  );
}

PortfoliosList.propTypes = {
  query: PropTypes.object,
  slug: PropTypes.string
};

export default PortfoliosList;
