import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  FormControl,
  Button,
  TextField,
  Grid
} from "@mui/material";

function CreatePortfolioDialog({
  open,
  onClose,
  perform,
  titleText = "Create Portfolio"
}) {
  const [name, setName] = useState("");
  const onSubmit = async e => {
    e.preventDefault();
    await perform(name);
    onClose();
    setName("");
  };
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      data-testid="create-portfolio-dialog"
      fullWidth
    >
      <DialogTitle id="create-portfolio-title">{titleText}</DialogTitle>
      <DialogContent>
        <form onSubmit={onSubmit}>
          <FormControl fullWidth>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  id="name"
                  name="name"
                  label="Name"
                  value={name}
                  variant="outlined"
                  onChange={e => setName(e.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={4} />
              <Grid item xs={4}>
                <Button variant="contained" fullWidth onClick={onClose}>
                  Cancel
                </Button>
              </Grid>
              <Grid item xs={4}>
                <Button
                  data-testid="submit-create-portfolio"
                  type="submit"
                  variant="contained"
                  fullWidth
                  color="primary"
                >
                  Create
                </Button>
              </Grid>
            </Grid>
          </FormControl>
        </form>
      </DialogContent>
    </Dialog>
  );
}

CreatePortfolioDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  perform: PropTypes.func,
  titleText: PropTypes.string
};

export default CreatePortfolioDialog;
