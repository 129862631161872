import React from "react";
import { useQuery } from "@apollo/client";
import { Grid, LinearProgress } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import CurrentUserQuery from "ui/queries/CurrentUserQuery.graphql";
import DynamicSidenav from "ui/components/DynamicSidenav";
import { SiteWrapper } from "ui/contexts/SiteContext";
import { withNotifications } from "ui/contexts/NotificationsContext";
import { SidenavWrapper } from "ui/contexts/SidenavContext";
import { loginRequired } from "ui/providers/CurrentUserProvider";
import SiteSelector from "./SiteSelector";

const useStyles = makeStyles(theme => ({
  container: {
    height: "100%"
  }
}));

function SiteSelectionPage() {
  const classes = useStyles();
  const { data, loading } = useQuery(CurrentUserQuery);
  return (
    <Grid
      container
      className={classes.container}
      data-testid="site-selection-page"
    >
      <SiteWrapper>
        <SidenavWrapper
          Component={props => (
            <DynamicSidenav
              showLogout={data?.currentUser !== null}
              {...props}
            />
          )}
        >
          <Grid item xs container>
            <Grid item xs={12}>
              {loading && <LinearProgress />}
              {!loading && <SiteSelector {...(data?.currentUser || {})} />}
            </Grid>
          </Grid>
        </SidenavWrapper>
      </SiteWrapper>
    </Grid>
  );
}

export default withNotifications(loginRequired(SiteSelectionPage));
