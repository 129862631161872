import React from "react";
import { Grid } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import AcquisitionsSidenav from "ui/sites/acquisitions/components/AcquisitionsSidenav";
import CurrentSalespersonProvider from "ui/sites/acquisitions/providers/CurrentSalespersonProvider";
import { withNotifications } from "ui/contexts/NotificationsContext";
import { SiteWrapper } from "ui/contexts/SiteContext";
import { SidenavWrapper } from "ui/contexts/SidenavContext";
import Routes from "ui/sites/acquisitions/Routes";

const useStyles = makeStyles(theme => ({
  container: {
    height: "100%"
  }
}));

function AcquisitionsSite() {
  const classes = useStyles();
  return (
    <SiteWrapper site="acquisitions">
      <Grid container className={classes.container}>
        <SidenavWrapper Component={AcquisitionsSidenav}>
          <Grid item xs style={{ flex: true }}>
            <CurrentSalespersonProvider>
              <Routes />
            </CurrentSalespersonProvider>
          </Grid>
        </SidenavWrapper>
      </Grid>
    </SiteWrapper>
  );
}

export default withNotifications(AcquisitionsSite);
