import React, { useState, useEffect, useContext } from "react";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";
import { Grid, Paper } from "@mui/material";

import MapContext, { withMapContext } from "ui/contexts/MapContext";
import MapLayerContext, {
  withMapLayerContext
} from "ui/contexts/MapLayerContext";
import ParcelCursorContext from "ui/sites/acquisitions/contexts/ParcelCursorContext";
import CurrentSalespersonAvailableParcelsQuery from "ui/sites/acquisitions/queries/CurrentSalespersonAvailableParcelsQuery.graphql";
import CurrentUserNonzoningCategoriesQuery from "ui/queries/CurrentUserNonzoningCategoriesQuery.graphql";
import CurrentUserZoningCategoriesQuery from "ui/queries/CurrentUserZoningCategoriesQuery.graphql";
import CurrentUserAllCategoryGroupingsQuery from "ui/queries/CurrentUserAllCategoryGroupingsQuery.graphql";
import CurrentUserAllLendersQuery from "ui/queries/CurrentUserAllLendersQuery.graphql";

import ParcelSearchCursorCreateMutation from "ui/sites/acquisitions/queries/ParcelSearchCursorCreateMutation.graphql";
import ParcelCards from "ui/components/ParcelCards";
import MapOverlaySelector from "ui/components/MapOverlaySelector";
import MapLayers from "ui/components/MapLayers";
import AcquisitionsParcelPageParcelMap from "./AcquisitionsParcelsPageParcelMap";
import AcquisitionsParcelsPageFilterSidenav from "ui/sites/acquisitions/pages/AcquisitionsParcelsPage/AcquisitionsPageFilterSidenav/AcquisitionsParcelsPageFilterSidenav";

function AcquisitionsParcelsPage() {
  const navigate = useNavigate();
  const parcelCursorContext = useContext(ParcelCursorContext);
  const localExpanded = localStorage.getItem("parcelSidenavExpanded");
  const [expanded, setExpanded] = useState(
    localExpanded !== null ? localExpanded : true
  );
  const [includeClaimed, setIncludeClaimed] = useState(
    parcelCursorContext.includeClaimed
  );
  const { searchParams, demographicSelection, mapOverlays } =
    useContext(MapLayerContext);
  const [search, setSearch] = useState(parcelCursorContext.search);
  const [after, setAfter] = useState(parcelCursorContext.after);
  const [lastCursor, setLastCursor] = useState(parcelCursorContext.lastCursor);
  const [page, setPage] = useState(parcelCursorContext.page);
  const [categories, setCategories] = useState(parcelCursorContext.categories);
  const [zoningCategories, setZoningCategories] = useState(
    parcelCursorContext.zoningCategories
  );
  const [lenderIds, setLenderIds] = useState(parcelCursorContext.lenderIds);
  const [categoryGroupings, setCategoryGroupings] = useState(
    parcelCursorContext.categoryGroupings
  );
  const [searchText, setSearchText] = useState(
    _.toString(_.get(parcelCursorContext, "search", ""))
  );
  const [purchaseDateRange, setPurchaseDateRange] = useState(
    parcelCursorContext.purchaseDateRange
  );
  const [loanDateRange, setLoanDateRange] = useState(
    parcelCursorContext.loanDateRange
  );
  const [loanMaturityDateRange, setLoanMaturityDateRange] = useState(
    parcelCursorContext.loanMaturityDateRange
  );
  const [selectedParcel, setSelectedParcel] = useState(null);
  const [debounce, setDebounce] = useState(false);
  const mapContext = useContext(MapContext);

  const {
    center,
    ignoreMapChanges,
    mapInfoRef,
    touched: mapTouched
  } = mapContext;

  const controllableVariables = {
    includeClaimed,
    after,
    search,
    categories,
    zoningCategories,
    lenderIds,
    categoryGroupings,
    loanDateRange,
    loanMaturityDateRange,
    purchaseDateRange,
    mapOverlays
  };

  // todo: fix exhaustive deps warning
  // eslint-disable-next-line
  const variables = {
    ...controllableVariables,
    polygon: mapInfoRef.current.polygon,
    center: mapInfoRef.current.center,
    lastCursor,
    page,
    defaultZoom: mapInfoRef.current.zoom,
    defaultCenter: JSON.stringify(center),
    cursorId: parcelCursorContext.cursorId
  };

  const { data, loading } = useQuery(CurrentSalespersonAvailableParcelsQuery, {
    variables
  });

  const { data: zoningcategoriesData, loading: zoningCategoriesLoading } =
    useQuery(CurrentUserZoningCategoriesQuery, {
      variables: { first: 500 }
    });

  const { data: nonzoningcategoriesData, loading: nonzoningCategoriesLoading } =
    useQuery(CurrentUserNonzoningCategoriesQuery, {
      variables: { first: 500 }
    });

  const { data: lendersData, loading: lendersLoading } = useQuery(
    CurrentUserAllLendersQuery,
    { variables: { first: 5000 } }
  );

  const { data: categoryGroupingsData, loading: categoryGroupingsLoading } =
    useQuery(CurrentUserAllCategoryGroupingsQuery, {
      variables: { first: 5000 }
    });

  const [mutate] = useMutation(ParcelSearchCursorCreateMutation);

  const variablesChanged = _.some(
    _.map(_.keys(controllableVariables)),
    key => !_.isEqual(controllableVariables[key], parcelCursorContext[key])
  );

  useEffect(() => {
    // initialize portal demographics and map overlays
    window.portalDemographics = {};
    window.mapOverlays = {};
  });

  useEffect(() => {
    if ((mapTouched || variablesChanged) && !debounce && !ignoreMapChanges) {
      setDebounce(true);
      (async () => {
        const result = await mutate({ variables });
        if (result?.data) {
          const cursorId = _.get(
            result.data,
            "parcelSearchCursorCreate.cursor.cursorId"
          );
          const stringParams =
            demographicSelection === "" ? "" : `?${searchParams.toString()}`;
          navigate(`/acquisitions/parcels/${cursorId}${stringParams}`);
        }
      })();
    }
  }, [
    debounce,
    navigate,
    loading,
    mapTouched,
    mutate,
    variables,
    variablesChanged,
    demographicSelection,
    searchParams,
    ignoreMapChanges
  ]);

  const count = _.get(data, "currentSalesperson.availableParcels.totalCount");

  const onPageChange = (e, newPage) => {
    if (newPage === 0) {
      setLastCursor([]);
      setAfter(null);
    } else if (newPage < page) {
      setAfter(lastCursor.pop());
      setLastCursor(lastCursor);
    } else if (newPage > page) {
      const nextAfter = _.get(
        data,
        "currentSalesperson.availableParcels.pageInfo.endCursor"
      );
      setLastCursor(lastCursor + [after]);
      setAfter(nextAfter);
    }
    setPage(newPage);
  };

  const parcels = _.map(
    _.get(data, "currentSalesperson.availableParcels.edges"),
    ({ node }) => node
  );

  const withResetPagination = fn => () => {
    setPage(0);
    setAfter("");
    setLastCursor([]);
    fn();
  };

  const onSubmit = e => {
    e.preventDefault();
    withResetPagination(() => setSearch(searchText))();
  };

  const clearSearch = withResetPagination(() => setSearch(null));
  const toggleIncludeClaimed = e =>
    withResetPagination(() => setIncludeClaimed(e.target.checked))();
  return (
    <Grid container justifyContent="center" alignItems="center">
      <MapOverlaySelector />
      <Grid item xs={12} container>
        <AcquisitionsParcelsPageFilterSidenav
          {...{
            zoningcategories: _.map(
              _.get(
                zoningcategoriesData,
                "currentUser.zoningCategories.edges",
                []
              ),
              "node"
            ),
            nonzoningcategories: _.map(
              _.get(
                nonzoningcategoriesData,
                "currentUser.nonzoningCategories.edges",
                []
              ),
              "node"
            ),
            expanded,
            setExpanded: async val => {
              await localStorage.setItem("parcelSidenavExpanded", val);
              return setExpanded(val);
            },
            includeClaimed,
            toggleIncludeClaimed,
            onSubmit,
            searchText,
            setSearchText,
            search,
            clearSearch,
            selectedNonzoningcategories: _.isArray(categories)
              ? categories
              : [],
            setSelectedNonzoningcategories: x => setCategories(x),
            selectedZoningcategories: _.isArray(zoningCategories)
              ? zoningCategories
              : [],
            setSelectedZoningcategories: x => setZoningCategories(x),
            zoningCategoriesLoading,
            nonzoningCategoriesLoading,
            lenders: _.map(
              _.get(lendersData, "currentUser.allLenders.edges", []),
              "node"
            ),
            lendersLoading,
            selectedLenderIds: _.isArray(lenderIds) ? lenderIds : [],
            setSelectedLenderIds: setLenderIds,
            categoryGroupings: _.map(
              _.get(
                categoryGroupingsData,
                "currentUser.allCategoryGroupings.edges",
                []
              ),
              "node"
            ),
            selectedCategoryGroupings: categoryGroupings,
            setSelectedCategoryGroupings: setCategoryGroupings,
            categoryGroupingsLoading,
            loanDateRange,
            setLoanDateRange,
            loanMaturityDateRange,
            setLoanMaturityDateRange,
            purchaseDateRange,
            setPurchaseDateRange
          }}
        />
        <Grid item xs={4} style={{ height: "100%" }}>
          <Paper style={{ height: "100%" }}>
            <MapLayers>
              <AcquisitionsParcelPageParcelMap
                selectedParcel={selectedParcel}
                setSelectedParcel={setSelectedParcel}
                loading={loading}
                parcels={parcels}
              />
            </MapLayers>
          </Paper>
        </Grid>
        <Grid item xs={expanded ? 6 : 7}>
          <ParcelCards
            parcels={parcels}
            loading={loading}
            paginationProps={{
              page,
              rowsPerPage: 20,
              rowsPerPageOptions: [20],
              onPageChange,
              count
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default withMapContext(withMapLayerContext(AcquisitionsParcelsPage));
