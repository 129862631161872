import React, { useContext } from "react";
import PropTypes from "prop-types";
import { Button } from "@mui/material";
import NotificationsContext from "ui/contexts/NotificationsContext";
import actAndNotify from "ui/lib/actAndNotify";
import { useUnderwritingActions } from "ui/sites/underwriting/contexts/UnderwritingActionsContext";

function ClaimRequestAction({ request }) {
  const { notify } = useContext(NotificationsContext);
  const { claimRequest, refetchQueries } = useUnderwritingActions(
    request.lead.parcel.id
  );
  const onClick = actAndNotify(claimRequest, "underwritingRequestClaim", {
    notify,
    mutateOptions: {
      variables: { underwritingRequestId: request.id },
      refetchQueries
    },
    successMessage: "Successfully claimed lead!"
  });
  return (
    <Button variant="outlined" color="primary" onClick={onClick}>
      Claim
    </Button>
  );
}

ClaimRequestAction.propTypes = {
  request: PropTypes.object.isRequired
};

export default ClaimRequestAction;
