import React, { useState } from "react";
import _ from "lodash";
import Loader from "ui/components/Loader";
import { useQuery } from "@apollo/client";
import { Link as RouterLink } from "react-router-dom";
import {
  Grid,
  Paper,
  Link,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography
} from "@mui/material";
import CurrentSalespersonParcelNodsQuery from "ui/sites/acquisitions/queries/CurrentSalespersonParcelNodsQuery.graphql";

function AcquisitionsParcelNodsContainer({ parcelId }) {
  const [selectedNod, setSelectedNod] = useState(0);
  const { data, loading } = useQuery(CurrentSalespersonParcelNodsQuery, {
    variables: { parcelId }
  });
  if (loading) {
    return <Loader />;
  }

  const nods = _.reverse(
    _.sortBy(
      _.map(_.get(data, "currentSalesperson.parcel.nodSet.edges", []), "node"),
      "originalDateOfContract"
    )
  );

  if (_.isEmpty(nods)) {
    // todo, better error view
    return <p>No nods found for parcel.</p>;
  }

  const nod = nods[selectedNod];

  const fields = _.omit(nod, [
    "__typename",
    "lender",
    "id",
    "contact",
    "trustee"
  ]);

  const { contact } = nod;
  const { trustee } = nod;

  const retrievedFields = _.map(fields, (value, key) => ({
    key,
    value
  }));
  const [unpopulatedFields, populatedFields] = _.partition(
    retrievedFields,
    ({ value }) => _.isEmpty(value)
  );

  return (
    <Grid container>
      <Grid item xs={12}>
        <Paper elevation={1}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Date</TableCell>
                  <TableCell>Lender</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {_.map(nods, (node, i) => (
                  <TableRow
                    key={`row-${i}`}
                    selected={i === selectedNod}
                    onClick={() => setSelectedNod(i)}
                  >
                    <TableCell>{node.recordingDate}</TableCell>
                    <TableCell>
                      {node.lender === null || node.lender.id === null ? (
                        "?"
                      ) : (
                        <Link
                          component={RouterLink}
                          to={`/lender/${node.lender.id}`}
                          underline="hover"
                        >
                          {node.lender.name}
                        </Link>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Grid>
      <Grid item xs={12} sx={{ marginTop: 5, marginBottom: 2 }}>
        <Typography align="center" variant="h4">
          NOD {nod.recordingDate}
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ marginTop: 5 }}>
        <Typography align="center" variant="h4">
          Contact
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ paddingBottom: 10 }}>
        <Paper elevation={1}>
          <TableContainer>
            <Table>
              <TableBody>
                {_.map(
                  _.entries(_.omit(contact, ["__typename"])),
                  ([key, value]) => (
                    <TableRow key={`contact-${key}`}>
                      <TableCell>
                        <b>{_.startCase(key)}</b>
                      </TableCell>
                      <TableCell>{value}</TableCell>
                    </TableRow>
                  )
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Grid>
      <Grid item xs={12} sx={{ marginTop: 5 }}>
        <Typography align="center" variant="h4">
          Trustee
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ paddingBottom: 10 }}>
        <Paper elevation={1}>
          <TableContainer>
            <Table>
              <TableBody>
                {_.map(
                  _.entries(_.omit(trustee, ["__typename"])),
                  ([key, value]) => (
                    <TableRow key={`trustee-${key}`}>
                      <TableCell>
                        <b>{_.startCase(key)}</b>
                      </TableCell>
                      <TableCell>{value}</TableCell>
                    </TableRow>
                  )
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Grid>
      <Grid item xs={12} sx={{ marginTop: 5 }}>
        <Typography align="center" variant="h4">
          Info
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ paddingBottom: 10 }}>
        <Paper elevation={1}>
          <TableContainer>
            <Table>
              <TableBody>
                {_.map(_.sortBy(populatedFields, "key"), ({ value, key }) => (
                  <TableRow key={`populatedfield-${key}`}>
                    <TableCell>
                      <b>{_.startCase(key)}</b>
                    </TableCell>
                    <TableCell>{value}</TableCell>
                  </TableRow>
                ))}
                {_.map(_.sortBy(unpopulatedFields, "key"), ({ value, key }) => (
                  <TableRow key={`unpopulatedfield-${key}`}>
                    <TableCell>
                      <b>{_.startCase(key)}</b>
                    </TableCell>
                    <TableCell>{value}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Grid>
    </Grid>
  );
}

export default AcquisitionsParcelNodsContainer;
