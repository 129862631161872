import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(() => ({
  notificationDot: {
    width: 10
  },
  notificationRow: {
    cursor: "pointer"
  }
}));
