import React from "react";
import { Grid } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import UnderwritingSidenav from "ui/sites/underwriting/components/UnderwritingSidenav";
import CurrentUnderwriterProvider from "ui/sites/underwriting/providers/CurrentUnderwriterProvider";
import { withNotifications } from "ui/contexts/NotificationsContext";
import { SidenavWrapper } from "ui/contexts/SidenavContext";
import Routes from "ui/sites/underwriting/Routes";
import { SiteWrapper } from "ui/contexts/SiteContext";

const useStyles = makeStyles(theme => ({
  container: {
    height: "100%"
  }
}));

function UnderwritingSite() {
  const classes = useStyles();
  return (
    <SiteWrapper site="underwriting">
      <Grid container className={classes.container}>
        <SidenavWrapper Component={UnderwritingSidenav}>
          <Grid item xs>
            <CurrentUnderwriterProvider>
              <Routes />
            </CurrentUnderwriterProvider>
          </Grid>
        </SidenavWrapper>
      </Grid>
    </SiteWrapper>
  );
}

export default withNotifications(UnderwritingSite);
