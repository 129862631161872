import React from "react";
import PropTypes from "prop-types";

function PlaceMarker({ place, onClick }) {
  return (
    <img
      alt={place.name}
      src={place.icon}
      style={{
        backgroundColor: place.iconBackgroundColor,
        height: 20,
        width: 20,
        cursor: "pointer"
      }}
      onClick={onClick}
    />
  );
}

PlaceMarker.propTypes = {
  place: PropTypes.object
};

export default PlaceMarker;
