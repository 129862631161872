import React, { useState } from "react";
import _ from "lodash";
import { Box, Drawer, Typography, Stack, Button } from "@mui/material";
import OccupationNumbers from "./OccupationNumbers";
import HousingCostNumbers from "./HousingCostNumbers";

const keyTableMapping = [
  { key: "Occupation Numbers", table: "occupationNumbers" },
  { key: "Financial Characteristics", table: "housingCostNumbers" }
];

function TractInfoDrawer({
  tract,
  setTract,
  initialTable = "occupationNumbers"
}) {
  const [table, setTable] = useState(initialTable);

  return (
    <Drawer anchor="right" open={tract !== null} onClose={() => setTract(null)}>
      <Box sx={{ width: 750 }} role="presentation">
        <Box sx={{ backgroundColor: "#333", color: "white", padding: "5px" }}>
          <Typography variant="h6">{tract.name}</Typography>
        </Box>
        <Box>
          <Stack direction="row" spacing={1}>
            {_.map(keyTableMapping, ({ key, table }) => (
              <Button key={key} onClick={() => setTable(table)}>
                {key}
              </Button>
            ))}
          </Stack>
        </Box>
        <Box sx={{ paddingX: 5 }}>
          {table === "occupationNumbers" && <OccupationNumbers tract={tract} />}
          {table === "housingCostNumbers" && (
            <HousingCostNumbers tract={tract} />
          )}
        </Box>
      </Box>
    </Drawer>
  );
}

export default TractInfoDrawer;
