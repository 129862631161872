import React from "react";
import PropTypes from "prop-types";
import { TextField, Box, Button } from "@mui/material";
import { Formik, Form } from "formik";
import PasswordResetRequestFormValidation from "./PasswordResetRequestFormValidation";
import useStyles from "./useStyles";

function PasswordResetRequestForm({ handleSubmit }) {
  const classes = useStyles();

  const getError = ({ touched, field, errors }) =>
    touched[field] ? errors[field] : undefined;

  return (
    <Formik
      initialValues={{
        email: ""
      }}
      validationSchema={PasswordResetRequestFormValidation}
      onSubmit={handleSubmit}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleSubmit,
        isSubmitting
      }) => (
        <Form onSubmit={handleSubmit} data-testid="password-reset-request-form">
          <Box mt={5}>
            <TextField
              name="email"
              id="email"
              label="Email Address"
              variant="outlined"
              fullWidth
              onChange={handleChange}
              value={values.email}
              disabled={isSubmitting}
              error={Boolean(getError({ field: "email", errors, touched }))}
              helperText={getError({ field: "email", errors, touched })}
            />
          </Box>
          <Button
            className={classes.passwordResetRequestButton}
            variant="contained"
            color="secondary"
            fullWidth
            disabled={isSubmitting}
            id="password-reset-request"
            data-testid="password-reset-request"
            type="submit"
            title="Request Reset"
          >
            Request a Password Reset
          </Button>
        </Form>
      )}
    </Formik>
  );
}

PasswordResetRequestForm.propTypes = {
  handleSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool
};

export default PasswordResetRequestForm;
