import React from "react";
import _ from "lodash";
import { useQuery } from "@apollo/client";
import CircularProgress from "@mui/material/CircularProgress";
import CurrentUserLenderParcelsQuery from "ui/queries/CurrentUserLenderParcelsQuery.graphql";

function LenderFilteredTotalCount({
  lender,
  categories = [],
  categoryGroupings = []
}) {
  const { data, loading } = useQuery(CurrentUserLenderParcelsQuery, {
    variables: { categories, categoryGroupings, lenderId: lender.id, first: 0 }
  });
  if (loading) {
    return <CircularProgress size="0.6rem" />;
  }
  return <span>{_.get(data, "currentUser.lender.parcels.totalCount")}</span>;
}

export default LenderFilteredTotalCount;
