import React, { useState } from "react";
import _ from "lodash";
import { DateTime } from "luxon";
import { Grid, IconButton, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { greyText } from "ui/themes/AlineaTheme/colors";

const useStyles = makeStyles(theme => ({
  datePickerText: {
    color: greyText,
    width: "100%"
  },
  datePickerInput: {
    background: "#fff",
    borderRadius: "5px",
    margin: theme.spacing(1)
  },
  datePickerLabel: {
    color: greyText
  },
  datePickerHeadingWrapper: {
    textAlign: "center"
  },
  datePickerHeading: {
    color: "#fff"
  },
  drawerButton: {
    color: greyText
  }
}));

function DateFilter({
  name,
  dateRange = {},
  setDateRange,
  defaultOpen = false
}) {
  const classes = useStyles();
  const [open, setOpen] = useState(defaultOpen);

  const updateDateRange = key =>
    _.debounce(
      newValue => {
        const newDateRange = dateRange ? { ...dateRange } : {};
        newDateRange[key] = newValue;
        if (!(newDateRange?.lte || newDateRange?.gte)) {
          setDateRange(null);
          return;
        }
        setDateRange(newDateRange);
      },
      1000,
      { trailing: true }
    );

  const getDate = val => {
    if (!val) {
      return null;
    }
    if (_.isString(val)) {
      return DateTime.fromISO(val);
    }
    if (_.isDate(val)) {
      return val;
    }
  };

  const lte = getDate(dateRange?.lte);
  const gte = getDate(dateRange?.gte);

  return (
    <Grid item container xs={12} alignItems="center">
      <Grid item xs={2}>
        {open && (
          <IconButton
            className={classes.drawerButton}
            onClick={() => setOpen(false)}
          >
            <ExpandLess />
          </IconButton>
        )}
        {!open && (
          <IconButton
            className={classes.drawerButton}
            onClick={() => setOpen(true)}
          >
            <ExpandMore />
          </IconButton>
        )}
      </Grid>
      <Grid item xs={8} className={classes.datePickerHeadingWrapper}>
        <Typography variant="overline" className={classes.datePickerHeading}>
          {name}
        </Typography>
      </Grid>
      {open && (
        <>
          <Grid item xs={12}>
            <DatePicker
              value={gte}
              label="Start Date"
              onChange={updateDateRange("gte")}
              slotProps={{
                field: { clearable: true, onClear: () => {} },
                textField: {
                  className: classes.datePickerText,
                  InputLabelProps: { className: classes.datePickerLabel },
                  InputProps: {
                    className: classes.datePickerInput
                  }
                }
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <DatePicker
              label="End Date"
              value={lte}
              onChange={updateDateRange("lte")}
              slotProps={{
                textField: {
                  field: { clearable: true, onClear: () => {} },
                  className: classes.datePickerText,
                  InputLabelProps: { className: classes.datePickerLabel },
                  InputProps: {
                    className: classes.datePickerInput
                  }
                }
              }}
            />
          </Grid>
        </>
      )}
    </Grid>
  );
}

export default DateFilter;
