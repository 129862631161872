import React from "react";
import PropTypes from "prop-types";
import { Button, FormControl, TextField } from "@mui/material";

function AddressSearchForm({ onSubmit, address, setAddress }) {
  return (
    <form
      onSubmit={onSubmit}
      data-testid="find-create-parcel-address-search-form"
    >
      <FormControl fullWidth>
        <TextField
          id="address"
          name="address"
          label="Address"
          value={address}
          variant="outlined"
          onChange={e => setAddress(e.target.value)}
          fullWidth
        />
        <Button
          data-testid="submit-find"
          type="submit"
          variant="outlined"
          fullWidth
          color="primary"
        >
          Find
        </Button>
      </FormControl>
    </form>
  );
}

AddressSearchForm.propTypes = {
  onSubmit: PropTypes.func,
  address: PropTypes.string,
  setAddress: PropTypes.func
};

export default AddressSearchForm;
