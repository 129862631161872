import React from "react";
import { ApolloProvider } from "@apollo/client";
import {
  CssBaseline,
  ThemeProvider,
  StyledEngineProvider
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import Routes from "ui/Routes";
import client from "ui/lib/graphql/client";
import AlineaTheme from "ui/themes/AlineaTheme";

function App() {
  return (
    <>
      <CssBaseline />
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={AlineaTheme}>
          <ApolloProvider client={client}>
            <LocalizationProvider dateAdapter={AdapterLuxon}>
              <Routes />
            </LocalizationProvider>
          </ApolloProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </>
  );
}

export default App;
