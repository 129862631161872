import React from "react";
import _ from "lodash";
import { useNavigate, useLocation } from "react-router-dom";
import { Grid, Typography, AppBar, Tab, Tabs } from "@mui/material";
import { Upload, MoveToInbox } from "@mui/icons-material";
import makeStyles from "@mui/styles/makeStyles";
import AcquisitionsInboundLeadsContainer from "ui/sites/acquisitions/containers/AcquisitionsInboundLeadsContainer";
import AcquisitionsInboundLeadsUploadsContainer from "ui/sites/acquisitions/containers/AcquisitionsInboundLeadsUploadsContainer";

const useStyles = makeStyles(theme => ({
  wrapper: {
    padding: theme.spacing(3, 3)
  },
  tableWrapper: {
    marginTop: theme.spacing(5)
  },
  title: {
    marginBottom: theme.spacing(2.5)
  }
}));

function AcquisitionsInboundLeadsPage() {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();

  const routes = ["", "/uploads"];
  const tabValue = _.indexOf(
    routes,
    location.pathname.replace("/acquisitions/inbound-leads", "")
  );

  return (
    <Grid
      item
      xs={12}
      container
      className={classes.wrapper}
      data-testid="acquisitions-inbound-leads-page"
    >
      <Grid item xs={12} className={classes.title}>
        <Typography variant="h4" align="center">
          Inbound Leads
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <AppBar position="static">
          <Tabs
            value={tabValue}
            onChange={(e, newValue) =>
              navigate(`/acquisitions/inbound-leads${routes[newValue]}`)
            }
            textColor="inherit"
          >
            <Tab label="Inbound Leads" icon={<MoveToInbox />} />
            <Tab label="Uploads" icon={<Upload />} />
          </Tabs>
        </AppBar>
      </Grid>
      <Grid item xs={12}>
        {tabValue === 0 && <AcquisitionsInboundLeadsContainer />}
        {tabValue === 1 && <AcquisitionsInboundLeadsUploadsContainer />}
      </Grid>
    </Grid>
  );
}

export default AcquisitionsInboundLeadsPage;
