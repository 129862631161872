import React from "react";
import PropTypes from "prop-types";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import RequestRow from "./RequestRow";

const useStyles = makeStyles(() => ({
  table: { width: "100%" }
}));

function RequestsTable({
  requests,
  noLinks = false,
  withPortfolioManagement = false,
  canDelete = false
}) {
  console.log("canDelete do be", canDelete);
  const classes = useStyles();
  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} size="small" data-testid="leads-table">
        <TableHead>
          <TableRow>
            <TableCell>Status</TableCell>
            <TableCell>Salesperson</TableCell>
            <TableCell>Address</TableCell>
            <TableCell>City</TableCell>
            <TableCell>State</TableCell>
            <TableCell>Zip</TableCell>
            <TableCell>Last Sale Price</TableCell>
            <TableCell>Asking Price</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {requests.map(request => (
            <RequestRow
              key={JSON.stringify(request)}
              request={request}
              noLinks={noLinks}
              withPortfolioManagement={withPortfolioManagement}
              canDelete={canDelete}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

RequestsTable.propTypes = {
  requests: PropTypes.arrayOf(PropTypes.object),
  noLinks: PropTypes.bool,
  withPortfolioManagement: PropTypes.bool
};

export default RequestsTable;
