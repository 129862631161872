import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { DateTime } from "luxon";
import ReactMarkdown from "react-markdown";
import { Button, Grid, TextField, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Loader from "ui/components/Loader";
import NotificationsContext from "ui/contexts/NotificationsContext";
import actAndNotify from "ui/lib/actAndNotify";

const useStyles = makeStyles(theme => ({
  parcelNotes: {
    padding: theme.spacing(2, 1)
  },
  note: {
    borderBottom: "1px dotted #ccc",
    margin: theme.spacing(1, 1)
  },
  timestamp: {
    fontStyle: "italic"
  }
}));

function ParcelNotes({ parcelId, parcelNotes, submitNote }) {
  const classes = useStyles();
  const [note, setNote] = useState("");
  const [mutationLoading, setMutationLoading] = useState(false);
  const { notify } = useContext(NotificationsContext);

  async function submitNoteAndNotify() {
    setMutationLoading(true);
    await actAndNotify(submitNote, "parcelNoteAdd", {
      notify,
      mutateOptions: { variables: { note, parcelId } },
      successMessage: "Successfully created note!"
    })();
    setNote("");
    setMutationLoading(false);
  }

  return (
    <Grid container spacing={2} className={classes.parcelNotes}>
      <Grid item xs={12}>
        <Typography variant="h4" gutterBottom align="center">
          Notes
        </Typography>
      </Grid>
      {_.map(parcelNotes, ({ node }) => (
        <Grid item xs={12} container className={classes.note} key={node.id}>
          <Grid item xs={12}>
            <ReactMarkdown>{node.note}</ReactMarkdown>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="body2"
              className={classes.timestamp}
              align="right"
            >
              {DateTime.fromISO(node.created).toRelative()} by{" "}
              {_.get(node, "author.email", "?")}
            </Typography>
          </Grid>
        </Grid>
      ))}
      <Grid item xs={12} container justifyContent="center">
        <Grid item xs={12}>
          <TextField
            label="Add a Note"
            multiline
            data-testid="add-note"
            rows={4}
            variant="outlined"
            value={note}
            onChange={e => setNote(e.target.value)}
            fullWidth
          />
          <Button
            onClick={submitNoteAndNotify}
            variant="contained"
            color="secondary"
            data-testid="submit"
            disabled={mutationLoading}
            fullWidth
          >
            Submit
          </Button>
          {mutationLoading && <Loader />}
        </Grid>
      </Grid>
    </Grid>
  );
}

ParcelNotes.propTypes = {
  parcelId: PropTypes.string.isRequired
};

export default ParcelNotes;
