import React from "react";
import PropTypes from "prop-types";
import { HomeWorkTwoTone } from "@mui/icons-material";
import AcquisitionDialog from "./AcquisitionDialog";

function ParcelMarker({
  parcel,
  open = false,
  setOpen = () => {},
  variant = "acquisitions"
}) {
  return (
    <>
      <HomeWorkTwoTone
        style={{ cursor: "pointer" }}
        onClick={() => setOpen(parcel.id)}
      />
      {variant === "acquisitions" && (
        <AcquisitionDialog
          parcelId={parcel.id}
          open={open}
          handleClose={() => setOpen(null)}
        />
      )}
    </>
  );
}

ParcelMarker.propTypes = {
  parcel: PropTypes.object.isRequired,
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  variant: PropTypes.string
};

export default ParcelMarker;
