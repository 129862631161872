import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { DateTime } from "luxon";
import {
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  CardActions,
  IconButton,
  Grid,
  Typography,
  LinearProgress
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { GetApp, HighlightOff } from "@mui/icons-material";
import actAndNotify from "ui/lib/actAndNotify";
import NotificationsContext from "ui/contexts/NotificationsContext";

const useStyles = makeStyles(theme => ({
  descriptionText: {
    fontSize: theme.typography.fontSize * 0.8
  },
  history: {
    color: "rgba(0, 0, 0, 0.7)",
    fontSize: theme.typography.fontSize * 0.6,
    fontStyle: "italic"
  },
  docPreview: {
    fontSize: theme.typography.fontSize * 3
  },
  root: {
    width: 450
  }
}));

function ParcelImage({ parcelImage, parcelId, deleteMutation }) {
  const classes = useStyles();
  const { notify } = useContext(NotificationsContext);
  const [loading, setLoading] = useState(false);
  const { id: parcelImageId, s3Url, description, filename } = parcelImage;

  function openImage() {
    window.open(s3Url, "_blank");
  }

  if (loading) {
    return (
      <Grid item xs={12}>
        <LinearProgress />
      </Grid>
    );
  }

  const deleteParcelImage = async () => {
    setLoading(true);
    await actAndNotify(deleteMutation, "parcelImageDelete", {
      notify,
      mutateOptions: { variables: { parcelImageId } },
      successMessage: `Successfully deleted file "${filename}"`
    })();
    setLoading(false);
  };

  return (
    <Grid item xs={3} container className={classes.parcelImage}>
      <Card className={classes.root}>
        <CardActionArea onClick={openImage}>
          <CardMedia
            component="img"
            alt={description}
            image={s3Url}
            title={description}
            height="250vh"
          />
          <CardContent>
            <Typography variant="body2" component="p">
              {description}
            </Typography>
            <Typography className={classes.history}>
              {`${DateTime.fromISO(
                parcelImage.created
              ).toRelative()} by ${_.get(parcelImage, "author.email", "?")}`}
            </Typography>
          </CardContent>
        </CardActionArea>
        <CardActions>
          <Grid container justifyContent="flex-end" spacing={1}>
            <IconButton onClick={deleteParcelImage} size="large">
              <HighlightOff />
            </IconButton>
            <IconButton onClick={openImage} size="large">
              <GetApp />
            </IconButton>
          </Grid>
        </CardActions>
      </Card>
    </Grid>
  );
}

ParcelImage.propTypes = {
  parcelId: PropTypes.string,
  parcelImage: PropTypes.object
};

export default ParcelImage;
