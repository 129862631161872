import React from "react";
import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";
import { TableCell, Link } from "@mui/material";
import getActor from "./getActor";

function NotificationLead({ notification, site }) {
  return <>
    <TableCell>
      {`${getActor(notification.actor)}`} {notification.verb} a lead for the
      parcel {notification.actionObject.parcel.addressFull}
      {notification.target && ` to ${notification.target.email}`}
    </TableCell>
    <TableCell align="right">
      <Link
        component={RouterLink}
        to={`/${site}/parcel/${notification.actionObject.parcel.id}`}
        underline="hover">
        View Parcel
      </Link>
    </TableCell>
  </>;
}

NotificationLead.propTypes = {
  notification: PropTypes.object,
  site: PropTypes.string
};

export default NotificationLead;
