import React from "react";
import { Grid, Paper } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { headerText, navBackground } from "ui/themes/AlineaTheme/colors";
import HeaderLogo from "ui/images/HeaderLogo.png";

const useStyles = makeStyles(theme => ({
  header: {
    backgroundColor: navBackground
  },
  headerPaper: {
    backgroundColor: navBackground,
    color: headerText,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    width: "100%"
  },
  container: {
    height: "100%"
  },
  inner: {
    height: "100%"
  },
  headerLogo: {
    height: 100
  }
}));

function MainLayout({ children }) {
  const classes = useStyles();
  return (
    <Grid container className={classes.container}>
      <Grid item xs={12} className={classes.header} container>
        <Paper elevation={3} className={classes.headerPaper}>
          <Grid item xs={12} container>
            <Grid item xs={2} />
            <Grid item xs={10}>
              <Grid container justifyContent="center">
                <img
                  src={HeaderLogo}
                  alt="Alinea Holdings"
                  className={classes.headerLogo}
                />
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={12} className={classes.inner}>
        {children}
      </Grid>
    </Grid>
  );
}

export default MainLayout;
