import React, { useState, useContext } from "react";
import _ from "lodash";
import { useQuery, useMutation } from "@apollo/client";
import { Grid, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import actAndNotify from "ui/lib/actAndNotify";
import NotificationsContext from "ui/contexts/NotificationsContext";
import Loader from "ui/components/Loader";
import LeadsTable from "ui/sites/acquisitions/components/LeadsTable";
import useCursors from "ui/hooks/useCursors";
import usePagination from "ui/hooks/usePagination";
import { withLeadActions } from "ui/sites/acquisitions/contexts/LeadActionsContext";
import ParcelExportFromSalespersonTeamLeadsMutation from "ui/sites/acquisitions/queries/ParcelExportFromSalespersonTeamLeadsMutation.graphql";
import ParcelExportByIdMutation from "ui/queries/ParcelExportByIdMutation.graphql";
import CurrentSalespersonTeamLeadsQuery from "ui/sites/acquisitions/queries/CurrentSalespersonTeamLeadsQuery.graphql";

const useStyles = makeStyles(theme => ({
  wrapper: {
    padding: theme.spacing(3, 3)
  },
  tableWrapper: {
    marginTop: theme.spacing(5)
  },
  title: {
    marginBottom: theme.spacing(2.5)
  }
}));

function AcquisitionsTeamLeadsPage() {
  const classes = useStyles();
  const cursors = useCursors();
  const { notify } = useContext(NotificationsContext);
  const [salespersonId, setSalespersonId] = useState(null);
  const [exportById] = useMutation(ParcelExportByIdMutation);
  const [exportAll] = useMutation(ParcelExportFromSalespersonTeamLeadsMutation);
  const variables = { salespersonId, ..._.pick(cursors, ["first", "after"]) };

  const { data, loading } = useQuery(CurrentSalespersonTeamLeadsQuery, {
    variables
  });

  const { paginationProps } = usePagination({
    nextAfter: _.get(data, "currentSalesperson.teamLeads.pageInfo.endCursor"),
    count: _.get(data, "currentSalesperson.teamLeads.totalCount"),
    ...cursors
  });

  if (loading) {
    return <Loader />;
  }

  const leads = _.map(
    _.get(data, "currentSalesperson.teamLeads.edges", []),
    "node"
  );

  function handleRequestCsvUpload(parcelIds) {
    const mutate = _.isEmpty(parcelIds) ? exportAll : exportById;
    const slug = _.isEmpty(parcelIds)
      ? "parcelExportFromSalespersonTeamLeads"
      : "parcelExportById";
    return actAndNotify(mutate, slug, {
      notify,
      mutateOptions: { variables: { parcelIds } },
      successMessage:
        "Requested CSV export. You will be notified when it's ready."
    });
  }

  return (
    <Grid
      item
      xs={12}
      container
      className={classes.wrapper}
      data-testid="acquisitions-team-leads-page"
    >
      <Grid item xs={12} className={classes.title}>
        <Typography variant="h4" align="center">
          My Team Leads
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <LeadsTable
          leads={leads}
          handleRequestCsvUpload={handleRequestCsvUpload}
          paginationProps={paginationProps}
          salespersonId={salespersonId}
          setSalespersonId={setSalespersonId}
          withPortfolioManagement
          withExportSelection
        />
      </Grid>
    </Grid>
  );
}

export default withLeadActions(AcquisitionsTeamLeadsPage);
