import React, { useContext } from "react";
import _ from "lodash";
import GoogleMapReact from "google-maps-react-markers";
import useWindowDimensions from "ui/hooks/useWindowDimensions";
import MapContext from "ui/contexts/MapContext";
import MapLayerContext from "ui/contexts/MapLayerContext";
import useMapCentering from "ui/hooks/useMapCentering";

function DemographicMap() {
  const windowDimensions = useWindowDimensions();
  const mapLayerContext = useContext(MapLayerContext);
  const mapContext = useContext(MapContext);
  useMapCentering({ ...mapContext, ignoreMapChanges: true }, []);
  const { defaultCenter, defaultZoom, center } = mapContext;
  const onGoogleApiLoaded = _.first([
    mapLayerContext.onGoogleApiLoaded,
    mapContext.onGoogleApiLoaded
  ]);
  return (
    <div
      style={{
        height: windowDimensions.height - 130,
        width: "100%"
      }}
      data-testid="demographic-map"
    >
      <GoogleMapReact
        apiKey={process.env.GOOGLE_API_KEY}
        libraries={["places"]}
        defaultCenter={defaultCenter}
        center={center}
        defaultZoom={defaultZoom}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={onGoogleApiLoaded}
        options={{ streetViewControl: true }}
      />
    </div>
  );
}

export default DemographicMap;
