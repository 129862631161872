import React, { useContext } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { useNavigate, Link as RouterLink } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";
import { Grid, Typography, Button, Link } from "@mui/material";
import actAndNotify from "ui/lib/actAndNotify";
import NotificationsContext from "ui/contexts/NotificationsContext";
import Loader from "ui/components/Loader";
import CurrentSalespersonAvailableParcelsQuery from "ui/sites/acquisitions/queries/CurrentSalespersonAvailableParcelsQuery.graphql";
import ParcelCreateFromAddressMutation from "ui/sites/acquisitions/queries/ParcelCreateFromAddressMutation.graphql";

function ParcelFindResultPane({ address }) {
  const { loading, data } = useQuery(CurrentSalespersonAvailableParcelsQuery, {
    variables: {
      search: address,
      includeClaimed: true
    }
  });
  const [mutate, { loading: mutationLoading }] = useMutation(
    ParcelCreateFromAddressMutation,
    {
      variables: { address }
    }
  );
  const navigate = useNavigate();
  const { notify } = useContext(NotificationsContext);

  const create = actAndNotify(mutate, "parcelCreateFromAddress", {
    notify,
    successMessage: "Successfully created parcel",
    callback: ({ parcel: { id } }) => navigate(`/acquisitions/parcel/${id}`)
  });

  if (loading || mutationLoading) {
    return <Loader />;
  }

  if (_.get(data, "currentSalesperson.availableParcels.totalCount", 0) === 0) {
    return (
      <Grid item xs={12} container data-testid="find-create-parcel-not-found">
        <Grid item xs={12}>
          <Typography variant="h3" align="center">
            Parcel Not Found
          </Typography>
          <Typography align="center">
            You can create the parcel below to track it as a lead.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Button
            onClick={create}
            data-testid="create-parcel-button"
            fullWidth
            variant="outlined"
          >
            Create Parcel
          </Button>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid item xs={12} container data-testid="find-create-parcel-results">
      {_.map(
        _.get(data, "currentSalesperson.availableParcels.edges", []),
        ({ node: { id, addressFull, city, state, zipcode } }) => (
          <Grid item xs={12} key={id}>
            <Link
              component={RouterLink}
              to={`/acquisitions/parcel/${id}`}
              data-testid={`parcel-result-${id}`}
              underline="hover"
            >
              {addressFull}, {city}, {state} {zipcode}
            </Link>
          </Grid>
        )
      )}
    </Grid>
  );
}

ParcelFindResultPane.propTypes = {
  address: PropTypes.string
};

export default ParcelFindResultPane;
