import React from "react";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/client";
import Loader from "ui/components/Loader";
import ParcelDocuments from "ui/components/ParcelDocuments";
import { useLeadActions } from "ui/sites/acquisitions/contexts/LeadActionsContext";
import CurrentSalesPersonParcelDocumentsQuery from "ui/sites/acquisitions/queries/CurrentSalespersonParcelDocumentsQuery.graphql";

function AcquisitionsParcelDocumentsContainer({ parcelId }) {
  const { data, loading } = useQuery(CurrentSalesPersonParcelDocumentsQuery, {
    variables: { parcelId }
  });
  const { uploadParcelDocument, deleteParcelDocument } =
    useLeadActions(parcelId);
  if (loading) {
    return <Loader />;
  }
  return (
    <ParcelDocuments
      parcel={data.currentSalesperson?.parcel}
      uploadMutation={uploadParcelDocument}
      deleteMutation={deleteParcelDocument}
    />
  );
}

AcquisitionsParcelDocumentsContainer.propTypes = {
  parcelId: PropTypes.string.isRequired
};

export default AcquisitionsParcelDocumentsContainer;
