import React, { useContext } from "react";
import { useMutation } from "@apollo/client";
import LeadModifyMutation from "ui/sites/acquisitions/queries/LeadModifyMutation.graphql";
import LeadGenerateMutation from "ui/sites/acquisitions/queries/LeadGenerateMutation.graphql";
import LeadPromoteMutation from "ui/sites/acquisitions/queries/LeadPromoteMutation.graphql";
import LeadRemoveMutation from "ui/sites/acquisitions/queries/LeadRemoveMutation.graphql";
import LeadAssignMutation from "ui/sites/acquisitions/queries/LeadAssignMutation.graphql";
import CurrentUserActivityQuery from "ui/queries/CurrentUserActivityQuery.graphql";
import CurrentSalespersonLeadsQuery from "ui/sites/acquisitions/queries/CurrentSalespersonLeadsQuery.graphql";
import CurrentSalespersonAvailableParcelsQuery from "ui/sites/acquisitions/queries/CurrentSalespersonAvailableParcelsQuery.graphql";
import CurrentSalespersonParcelDetailsQuery from "ui/sites/acquisitions/queries/CurrentSalespersonParcelDetailsQuery.graphql";
import CurrentSalespersonParcelActivityQuery from "ui/sites/acquisitions/queries/CurrentSalespersonParcelActivityQuery.graphql";
import CurrentSalesPersonParcelDocumentsQuery from "ui/sites/acquisitions/queries/CurrentSalespersonParcelDocumentsQuery.graphql";
import ParcelDocumentUploadMutation from "ui/queries/ParcelDocumentUploadMutation.graphql";
import ParcelDocumentDeleteMutation from "ui/queries/ParcelDocumentDeleteMutation.graphql";
import CurrentSalesPersonParcelImagesQuery from "ui/sites/acquisitions/queries/CurrentSalespersonParcelImagesQuery.graphql";
import ParcelImageUploadMutation from "ui/queries/ParcelImageUploadMutation.graphql";
import ParcelImageDeleteMutation from "ui/queries/ParcelImageDeleteMutation.graphql";
import ParcelNoteAddMutation from "ui/queries/ParcelNoteAddMutation.graphql";
import CurrentSalespersonParcelNotesQuery from "ui/sites/acquisitions/queries/CurrentSalespersonParcelNotesQuery.graphql";
import MyUnderwritingRequestsQuery from "ui/sites/underwriting/queries/MyUnderwritingRequestsQuery.graphql";
import OpenUnderwritingRequestsQuery from "ui/sites/underwriting/queries/OpenUnderwritingRequestsQuery.graphql";

const LeadActionsContext = React.createContext(null);

export function useLeadActions(parcelId) {
  const ctx = useContext(LeadActionsContext);
  const refetchQueries = ctx.getRefetchQueries(parcelId);
  const [uploadParcelDocument] = useMutation(ParcelDocumentUploadMutation, {
    refetchQueries: [
      ...refetchQueries,
      { query: CurrentSalesPersonParcelDocumentsQuery, variables: { parcelId } }
    ]
  });
  const [deleteParcelDocument] = useMutation(ParcelDocumentDeleteMutation, {
    refetchQueries: [
      ...refetchQueries,
      { query: CurrentSalesPersonParcelDocumentsQuery, variables: { parcelId } }
    ]
  });
  const [parcelImageUpload] = useMutation(ParcelImageUploadMutation, {
    refetchQueries: [
      ...refetchQueries,
      { query: CurrentSalesPersonParcelImagesQuery, variables: { parcelId } }
    ]
  });
  const [parcelImageDelete] = useMutation(ParcelImageDeleteMutation, {
    refetchQueries: [
      ...refetchQueries,
      { query: CurrentSalesPersonParcelImagesQuery, variables: { parcelId } }
    ]
  });
  const [submitNote] = useMutation(ParcelNoteAddMutation, {
    refetchQueries: [
      { query: CurrentSalespersonParcelNotesQuery, variables: { parcelId } }
    ]
  });
  return {
    ...ctx,
    uploadParcelDocument,
    deleteParcelDocument,
    parcelImageUpload,
    parcelImageDelete,
    submitNote,
    refetchQueries
  };
}

export function withLeadActions(Component) {
  return function (props) {
    const [modifyLead] = useMutation(LeadModifyMutation);
    const [generateLead] = useMutation(LeadGenerateMutation);
    const [promoteLead] = useMutation(LeadPromoteMutation);
    const [removeLead] = useMutation(LeadRemoveMutation);
    const [assignLead] = useMutation(LeadAssignMutation);
    const getRefetchQueries = parcelId => [
      { query: CurrentSalespersonParcelDetailsQuery, variables: { parcelId } },
      { query: CurrentSalespersonParcelActivityQuery, variables: { parcelId } },
      { query: CurrentSalespersonLeadsQuery },
      { query: CurrentSalespersonAvailableParcelsQuery },
      { query: CurrentUserActivityQuery },
      { query: MyUnderwritingRequestsQuery },
      { query: OpenUnderwritingRequestsQuery }
    ];
    return (
      <LeadActionsContext.Provider
        value={{
          modifyLead,
          generateLead,
          promoteLead,
          removeLead,
          assignLead,
          getRefetchQueries
        }}
      >
        <Component {...props} />
      </LeadActionsContext.Provider>
    );
  };
}

export default LeadActionsContext;
