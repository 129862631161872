import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { useQuery } from "@apollo/client";
import { Grid, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Loader from "ui/components/Loader";
import ParcelInfoTableInner from "ui/components/ParcelInfoTable/ParcelInfoTableInner";
import CurrentUnderwriterParcelComparablesQuery from "ui/sites/underwriting/queries/CurrentUnderwriterParcelComparablesQuery.graphql";

const useStyles = makeStyles({
  settings: {
    paddingRight: 10,
    paddingTop: 10
  },
  table: {
    minWidth: 350
  },
  header: {
    marginBottom: 10
  }
});

function UnderwritingParcelComparablesContainer({ parcel, compType }) {
  const classes = useStyles();
  const { data, loading } = useQuery(CurrentUnderwriterParcelComparablesQuery, {
    variables: {
      parcelId: parcel.id,
      compType
    }
  });

  return (
    <>
      <Grid item xs={12}>
        <Typography align="center" variant="h4" className={classes.header}>
          Comparables
        </Typography>
        {loading && <Loader />}
        {!loading && (
          <ParcelInfoTableInner
            parcels={_.map(
              _.get(
                data,
                "currentUnderwriter.parcel.currentRequest.comparables.edges",
                []
              ),
              "node.parcel"
            )}
            openInNewTab
          />
        )}
      </Grid>
    </>
  );
}

UnderwritingParcelComparablesContainer.propTypes = {
  parcel: PropTypes.object,
  compType: PropTypes.string
};

export default UnderwritingParcelComparablesContainer;
