import React from "react";
import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";
import { TableCell, Link } from "@mui/material";
import getActor from "./getActor";

function NotificationPurchaseValue({ notification, site }) {
  return <>
    <TableCell>
      {`${getActor(notification.actor)}`} {notification.verb} a lead on the
      parcel at{" "}
      {notification.actionObject.underwritingRequest.lead.parcel.addressFull}
    </TableCell>
    {site === "underwriting" && (
      <TableCell align="right">
        <Link
          component={RouterLink}
          to={`/${site}/parcel/${notification.actionObject.underwritingRequest.lead.parcel.id}/purchase-value`}
          underline="hover">
          View Purchase Value
        </Link>
      </TableCell>
    )}
    {site !== "underwriting" && (
      <TableCell align="right">
        <Link
          component={RouterLink}
          to={`/${site}/parcel/${notification.actionObject.underwritingRequest.lead.parcel.id}`}
          underline="hover">
          View Parcel
        </Link>
      </TableCell>
    )}
  </>;
}

NotificationPurchaseValue.propTypes = {
  notification: PropTypes.object,
  site: PropTypes.string
};

export default NotificationPurchaseValue;
