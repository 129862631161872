import React, { useContext } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { Select, MenuItem, InputLabel, FormControl } from "@mui/material";
import { Cancel } from "@mui/icons-material";
import CurrentSalespersonContext from "ui/sites/acquisitions/contexts/CurrentSalespersonContext";

function SalespersonFilter({ salespersonId, setSalespersonId }) {
  const { currentSalesperson } = useContext(CurrentSalespersonContext);

  return (
    <FormControl fullWidth>
      <InputLabel id="salesperson-filter-label">Salesperson</InputLabel>
      <Select
        labelId="salesperson-filter-id"
        data-testid="salesperson-filter"
        id="salesperson-filter"
        value={salespersonId || ""}
        onChange={e => setSalespersonId(e.target.value)}
        label="Filter by Salesperson"
        placeholder="Salesperson"
        variant="standard"
        fullWidth
      >
        {_.map(
          _.get(currentSalesperson, "salespersonSet.edges", []),
          ({ node }) => (
            <MenuItem
              key={node.id}
              value={node.id}
              data-testid={`menu-item-${node.id}`}
            >
              {node.user.email}
            </MenuItem>
          )
        )}
        {salespersonId && (
          <MenuItem value={null}>
            <Cancel /> <i>Remove Filter</i>
          </MenuItem>
        )}
      </Select>
    </FormControl>
  );
}

SalespersonFilter.propTypes = {
  setSalespersonId: PropTypes.func,
  salespersonId: PropTypes.string
};

export default SalespersonFilter;
