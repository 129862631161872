import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";
import { useQuery, useMutation, useApolloClient } from "@apollo/client";
import { Box, Grid, Link, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { EmptySidenav } from "ui/components/GlobalSidenav";
import Loader from "ui/components/Loader";
import LoginForm from "ui/components/LoginForm";
import CurrentUserQuery from "ui/queries/CurrentUserQuery.graphql";
import TokenAuthMutation from "ui/lib/graphql/queries/TokenAuthMutation.graphql";
import CurrentUserContext from "ui/contexts/CurrentUserContext";

const useStyles = makeStyles(theme => ({
  loginForm: {
    marginTop: theme.spacing(10)
  }
}));

function CurrentUserProvider({ children }) {
  const client = useApolloClient();
  const [error, setError] = useState("");
  const { data, loading } = useQuery(CurrentUserQuery);
  const [mutate] = useMutation(TokenAuthMutation);
  const classes = useStyles();

  async function handleSubmit({ email: username, password }) {
    try {
      const { data } = await mutate({ variables: { username, password } });
      await localStorage.setItem("token", data.tokenAuth.token);
      client.cache.reset();
    } catch (e) {
      setError("There was a problem logging in.");
    }
  }

  if (loading) {
    return (
      <Grid item xs={12}>
        <Loader />
      </Grid>
    );
  }
  if (data?.currentUser === null) {
    return (
      <>
        <Grid item xs={12} container alignItems="flex-start">
          <EmptySidenav />
          <Grid item xs={10} container alignItems="flex-start">
            <Grid item xs={12} className={classes.loginForm}>
              <Typography align="center" variant="h4">
                Please Login
              </Typography>
              {error && <Typography align="center">{error}</Typography>}
            </Grid>
            <Grid item container xs={12} align="center" justifyContent="center">
              <Grid item xs={4}>
                <LoginForm handleSubmit={handleSubmit} />
                <Box mt={5} textAlign="right">
                  Trouble Logging in?&nbsp;
                  <Link
                    component={RouterLink}
                    to={`/password-reset`}
                    underline="hover"
                  >
                    Reset Password
                  </Link>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  }

  return (
    <CurrentUserContext.Provider value={{ user: data?.currentUser }}>
      {children}
    </CurrentUserContext.Provider>
  );
}

CurrentUserProvider.propTypes = {
  children: PropTypes.node
};

export const loginRequired = Component => props =>
  (
    <CurrentUserProvider>
      <Component {...props} />
    </CurrentUserProvider>
  );

export default CurrentUserProvider;
