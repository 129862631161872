import * as Yup from "yup";

export default Yup.object().shape({
  email: Yup.string().email("Invalid Email").required("Required Field"),
  password: Yup.string()
    .min(6, "Password should have at least 6 characters")
    .required("Required Field"),
  passwordConfirmation: Yup.string().oneOf(
    [Yup.ref("password"), null],
    "Passwords must match"
  )
});
