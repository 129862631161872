import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { Grid, Select, FormControl, MenuItem, InputLabel } from "@mui/material";
import GoogleMapReact from "google-maps-react-markers";
import MapContext, { withMapContext } from "ui/contexts/MapContext";
import useMapCentering from "ui/hooks/useMapCentering";
import ParcelMarker from "ui/components/ParcelMarker";
import PlaceMarker from "./PlaceMarker";
import supportedPlaces from "./supportedPlaces";
import NearbyPlaceList from "./NearbyPlaceList";

function NearbyPlaces({ parcel }) {
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [currentMap, setCurrentMap] = useState(null);
  const [placeType, setPlaceType] = useState("supermarket");
  const [nearbyPlaces, setNearbyPlaces] = useState([]);
  const mapContext = useContext(MapContext);
  const { onGoogleApiLoaded, defaultZoom, center } = mapContext;
  useMapCentering(mapContext, [parcel]);
  const [lng, lat] = parcel.coordinates.coordinates;

  const performNearbySearch = ({ map = currentMap, type = placeType }) => {
    /* eslint-disable-next-line */
    const center = new google.maps.LatLng(lat, lng);
    /* eslint-disable-next-line */
    new google.maps.places.PlacesService(map).nearbySearch(
      {
        location: center,
        radius: 12000,
        types: [type]
      },
      (results, status) => {
        setNearbyPlaces(results);
      }
    );
  };

  const updatePlace = e => {
    setPlaceType(e.target.value);
    performNearbySearch({ map: currentMap, type: e.target.value });
  };

  const onGoogleApiLoadedWrapped = ({ map, maps }) => {
    onGoogleApiLoaded({ map, maps });
    setCurrentMap(map);
    performNearbySearch({ map, type: placeType });
  };

  return (
    <Grid container spacing={2} style={{ padding: 10 }}>
      <Grid item container xs={12} spacing={2}>
        <Grid item xs={4}>
          <FormControl fullWidth>
            <InputLabel id="select-type-label">Place Type</InputLabel>
            <Select
              labelId="select-type-label"
              id="select-type"
              value={placeType}
              onChange={updatePlace}
              variant="standard"
            >
              {_.map(supportedPlaces, pType => (
                <MenuItem key={pType} value={pType}>
                  {_.startCase(pType)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Grid item xs={4}>
        <div style={{ height: "80vh", width: "100%" }}>
          <GoogleMapReact
            apiKey={process.env.GOOGLE_API_KEY}
            libraries={["places"]}
            defaultCenter={{ lat, lng }}
            center={center}
            defaultZoom={defaultZoom}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={onGoogleApiLoadedWrapped}
            options={{ streetViewControl: true }}
            onZoomAnimationEnd={performNearbySearch}
            onDragEnd={performNearbySearch}
          >
            <ParcelMarker lat={lat} lng={lng} parcel={parcel} />
            {nearbyPlaces.map(place => (
              <PlaceMarker
                key={place.place_id}
                lat={place.geometry.location.lat()}
                lng={place.geometry.location.lng()}
                place={place}
                onClick={() => setSelectedPlace(place.place_id)}
              />
            ))}
          </GoogleMapReact>
        </div>
      </Grid>
      <Grid item xs={8}>
        <NearbyPlaceList places={nearbyPlaces} selectedPlace={selectedPlace} />
      </Grid>
    </Grid>
  );
}

NearbyPlaces.propTypes = {
  parcel: PropTypes.object
};

export default withMapContext(NearbyPlaces);
