import React from "react";
import PropTypes from "prop-types";
import AcquisitionDialogInner from "./AcquisitionDialogInner";

function AcquisitionDialog({ parcelId, open, handleClose }) {
  if (!open) {
    return null;
  }
  return (
    <AcquisitionDialogInner parcelId={parcelId} handleClose={handleClose} />
  );
}

AcquisitionDialog.propTypes = {
  parcelId: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool
};

export default AcquisitionDialog;
