import React from "react";
import { LinearProgress, Grid } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles(theme => ({
  loader: {
    marginTop: theme.spacing(5)
  }
}));

function Loader({ testid = "loading" }) {
  const classes = useStyles();
  return (
    <Grid item xs={12} className={classes.loader} data-testid={testid}>
      <LinearProgress />
    </Grid>
  );
}

export default Loader;
