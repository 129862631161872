import React from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import { useMutation } from "@apollo/client";
import RentalPurchaseValueWorksheetCreateOrUpdateMutation from "ui/sites/underwriting/queries/RentalPurchaseValueWorksheetCreateOrUpdateMutation.graphql";
import CurrentUnderwriterParcelDetailsQuery from "ui/sites/underwriting/queries/CurrentUnderwriterParcelDetailsQuery.graphql";
import RentalPurchaseValueWorksheetForm from "ui/sites/underwriting/components/RentalPurchaseValueWorksheetForm";
import RentalPurchaseValueWorksheetTable from "ui/sites/underwriting/components/RentalPurchaseValueWorksheetTable";

function RentalPurchaseValueWorksheetContainer({ parcel }) {
  const [mutate, { loading }] = useMutation(
    RentalPurchaseValueWorksheetCreateOrUpdateMutation,
    {
      refetchQueries: [
        {
          query: CurrentUnderwriterParcelDetailsQuery,
          variables: { parcelId: parcel.id }
        }
      ]
    }
  );
  const worksheet = _.get(
    parcel,
    "currentRequest.rentalpurchasevalueworksheet",
    {
      // todo: populate defaults from other parts of the parcel?
    }
  );
  async function onSubmit(worksheetValues) {
    await mutate({
      variables: {
        input: {
          parcelId: parcel.id,
          worksheetValues
        }
      }
    });
  }
  return (
    <>
      <RentalPurchaseValueWorksheetForm
        loading={loading}
        worksheet={worksheet}
        onSubmit={onSubmit}
      />
      {_.has(worksheet, "calculatedValues") && (
        <RentalPurchaseValueWorksheetTable worksheet={worksheet} />
      )}
    </>
  );
}

RentalPurchaseValueWorksheetContainer.propTypes = {
  parcel: PropTypes.object.isRequired
};

export default RentalPurchaseValueWorksheetContainer;
