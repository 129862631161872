import React, { useContext } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { useParams } from "react-router-dom";
import { Grid, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useQuery, useMutation } from "@apollo/client";
import actAndNotify from "ui/lib/actAndNotify";
import useCursors from "ui/hooks/useCursors";
import usePagination from "ui/hooks/usePagination";
import { SiteWrapper } from "ui/contexts/SiteContext";
import NotificationsContext from "ui/contexts/NotificationsContext";
import { withNotifications } from "ui/contexts/NotificationsContext";
import { SidenavWrapper } from "ui/contexts/SidenavContext";
import CurrentUserCategoryParcelsQuery from "ui/queries/CurrentUserCategoryParcelsQuery.graphql";
import ParcelExportFromCategoryMutation from "ui/sites/acquisitions/queries/ParcelExportFromCategoryMutation.graphql";
import ParcelExportByIdMutation from "ui/queries/ParcelExportByIdMutation.graphql";
import DynamicSidenav from "ui/components/DynamicSidenav";
import ParcelInfoTableInner from "ui/components/ParcelInfoTable/ParcelInfoTableInner";
import ParcelRemoveCategoryAction from "./ParcelRemoveCategoryAction";

const useStyles = makeStyles(theme => ({
  container: {
    height: "100%"
  }
}));

function CategoryPage({ site }) {
  const classes = useStyles();
  const { categoryId } = useParams();
  const cursors = useCursors();
  const { notify } = useContext(NotificationsContext);
  const variables = { categoryId, ..._.pick(cursors, ["first", "after"]) };
  const { data, loading } = useQuery(CurrentUserCategoryParcelsQuery, {
    variables
  });
  const count = _.get(data, `currentUser.category.parcels.totalCount`, 0);

  const { paginationProps } = usePagination({
    nextAfter: _.get(data, `currentUser.category.parcels.pageInfo.endCursor`),
    count,
    ...cursors
  });

  const [exportById] = useMutation(ParcelExportByIdMutation);
  const [exportAll] = useMutation(ParcelExportFromCategoryMutation, {
    variables: { categoryId }
  });

  const parcels = _.map(
    _.get(data, "currentUser.category.parcels.edges"),
    ({ node }) => node
  );

  const Action = ({ parcel }) => (
    <ParcelRemoveCategoryAction categoryId={categoryId} parcelId={parcel.id} />
  );

  function handleRequestCsvUpload(parcelIds) {
    const mutateOptions = {};
    if (!_.isEmpty(parcelIds)) {
      mutateOptions.variables = { parcelIds };
    }
    const mutate = _.isEmpty(parcelIds) ? exportAll : exportById;
    const slug = _.isEmpty(parcelIds)
      ? "parcelExportFromCategory"
      : "parcelExportById";
    return actAndNotify(mutate, slug, {
      notify,
      mutateOptions,
      successMessage:
        "Requested CSV export. You will be notified when it's ready."
    });
  }

  return (
    <Grid
      container
      className={classes.container}
      data-testid="notifications-page"
    >
      <SiteWrapper>
        <SidenavWrapper
          Component={props => (
            <DynamicSidenav
              showLogout={_.get(data, "currentUser", null) !== null}
              {...props}
            />
          )}
        >
          <Grid item xs container>
            <Grid item xs={12}>
              <Typography variant="h4" align="center">
                {_.get(data, "currentUser.category.name")}
              </Typography>
              <ParcelInfoTableInner
                parcels={parcels}
                loading={loading}
                paginationProps={paginationProps}
                Action={Action}
                handleRequestCsvUpload={handleRequestCsvUpload}
                withExportSelection
              />
            </Grid>
          </Grid>
        </SidenavWrapper>
      </SiteWrapper>
    </Grid>
  );
}

CategoryPage.propTypes = {
  site: PropTypes.string
};

export default withNotifications(CategoryPage);
