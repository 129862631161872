import React from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import { useMutation } from "@apollo/client";
import WholesalePurchaseValueWorksheetCreateOrUpdateMutation from "ui/sites/underwriting/queries/WholesalePurchaseValueWorksheetCreateOrUpdateMutation.graphql";
import CurrentUnderwriterParcelDetailsQuery from "ui/sites/underwriting/queries/CurrentUnderwriterParcelDetailsQuery.graphql";
import WholesalePurchaseValueWorksheetForm from "ui/sites/underwriting/components/WholesalePurchaseValueWorksheetForm";

function WholesalePurchaseValueWorksheetContainer({ parcel }) {
  const [mutate, { loading }] = useMutation(
    WholesalePurchaseValueWorksheetCreateOrUpdateMutation,
    {
      refetchQueries: [
        {
          query: CurrentUnderwriterParcelDetailsQuery,
          variables: { parcelId: parcel.id }
        }
      ]
    }
  );
  const worksheet = _.get(
    parcel,
    "currentRequest.wholesalepurchasevalueworksheet",
    {
      // todo: populate defaults from other parts of the parcel?
    }
  );
  async function onSubmit(worksheetValues) {
    await mutate({
      variables: {
        input: {
          parcelId: parcel.id,
          worksheetValues
        }
      }
    });
  }
  return (
    <WholesalePurchaseValueWorksheetForm
      loading={loading}
      worksheet={worksheet}
      onSubmit={onSubmit}
    />
  );
}

WholesalePurchaseValueWorksheetContainer.propTypes = {
  parcel: PropTypes.object.isRequired
};

export default WholesalePurchaseValueWorksheetContainer;
