import React, { useContext } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { CircularProgress } from "@mui/material";
import { HomeWorkTwoTone, HomeWorkOutlined } from "@mui/icons-material";
import GoogleMapReact from "google-maps-react-markers";
import useMapCentering from "ui/hooks/useMapCentering";
import MapContext from "ui/contexts/MapContext";

function CircularProgressWrapped() {
  return <CircularProgress />;
}

function ComparableParcelsMap({ mainParcel, loading, comparableParcels }) {
  const mapContext = useContext(MapContext);
  const { onGoogleApiLoaded, defaultCenter, defaultZoom, center } = mapContext;
  useMapCentering(mapContext, [mainParcel]);

  return (
    <div style={{ height: "80vh", width: "100%" }} data-testid="parcel-map">
      <GoogleMapReact
        apiKey={process.env.GOOGLE_API_KEY}
        libraries={["places"]}
        defaultCenter={defaultCenter}
        center={center}
        defaultZoom={defaultZoom}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={onGoogleApiLoaded}
        options={{ streetViewControl: true }}
      >
        {loading && (
          <CircularProgressWrapped
            lat={_.get(center, "latitude", defaultCenter.lat)}
            lng={_.get(center, "longitude", defaultCenter.lng)}
          />
        )}
        {_.map(comparableParcels, parcel => {
          const {
            id,
            coordinates: {
              coordinates: [lng, lat]
            }
          } = parcel;
          return (
            <HomeWorkTwoTone
              key={id}
              lng={lng}
              lat={lat}
              style={{ cursor: "pointer" }}
              onClick={() => window.open(`/underwriting/parcel/${parcel.id}`)}
            />
          );
        })}
        <HomeWorkOutlined
          lat={mainParcel.coordinates.coordinates[1]}
          lng={mainParcel.coordinates.coordinates[0]}
        />
      </GoogleMapReact>
    </div>
  );
}

ComparableParcelsMap.propTypes = {
  loading: PropTypes.bool,
  comparableParcels: PropTypes.arrayOf(PropTypes.object),
  mainParcel: PropTypes.object
};

export default ComparableParcelsMap;
