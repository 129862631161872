import React from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import { DateTime } from "luxon";
import { TableRow, TableCell } from "@mui/material";

function LogEntry({ logEntry }) {
  return (
    <TableRow data-testid={`logentry-${logEntry.id}`}>
      <TableCell>{DateTime.fromISO(logEntry.timestamp).toRelative()}</TableCell>
      <TableCell>{_.get(logEntry, "actor.email", "System")}</TableCell>
      <TableCell>{logEntry.actionString}</TableCell>
      <TableCell>{logEntry.instanceString}</TableCell>
      <TableCell>{logEntry.instanceId}</TableCell>
    </TableRow>
  );
}

LogEntry.propTypes = {
  logEntry: PropTypes.object
};

export default LogEntry;
