import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TableFooter,
  Paper
} from "@mui/material";
import { AssignmentReturned } from "@mui/icons-material";
import makeStyles from "@mui/styles/makeStyles";
import LeadRow from "./LeadRow";
import SalespersonFilter from "./SalespersonFilter";
import _ from "lodash";

const useStyles = makeStyles(() => ({
  table: { width: "100%" }
}));

function LeadsTable({
  leads,
  noLinks,
  salespersonId = null,
  setSalespersonId = null,
  paginationProps = null,
  withPortfolioManagement = false,
  withExportSelection = false,
  handleRequestCsvUpload = () => {}
}) {
  const [parcelIds, setParcelIds] = useState([]);
  const classes = useStyles();
  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} size="small" data-testid="leads-table">
        <TableHead>
          <TableRow>
            {withExportSelection && <TableCell></TableCell>}
            <TableCell>Status</TableCell>
            <TableCell>Address</TableCell>
            <TableCell>City</TableCell>
            <TableCell>State</TableCell>
            <TableCell>Zip</TableCell>
            <TableCell>Last Sale Price</TableCell>
            <TableCell>Expires</TableCell>
            <TableCell>
              {!setSalespersonId && "Salesperson"}
              {setSalespersonId && (
                <SalespersonFilter
                  setSalespersonId={setSalespersonId}
                  salespersonId={salespersonId}
                />
              )}
            </TableCell>
            <TableCell>Actions</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {leads.map(lead => (
            <LeadRow
              key={`lead-${JSON.stringify(lead)}`}
              lead={lead}
              noLinks={noLinks}
              withExportSelection={withExportSelection}
              withPortfolioManagement={withPortfolioManagement}
              checked={_.includes(parcelIds, lead.parcel.id)}
              handleCheckedChange={() =>
                setParcelIds(_.xor(parcelIds, [lead.parcel.id]))
              }
            />
          ))}
          {paginationProps && (
            <TableRow>
              <TablePagination {...paginationProps} />
            </TableRow>
          )}
        </TableBody>
        {withExportSelection && (
          <TableFooter>
            <TableRow>
              <TableCell colSpan={11} align="right">
                <Button
                  variant="contained"
                  onClick={handleRequestCsvUpload(parcelIds)}
                >
                  <AssignmentReturned /> Export
                </Button>
              </TableCell>
            </TableRow>
          </TableFooter>
        )}
      </Table>
    </TableContainer>
  );
}

LeadsTable.propTypes = {
  leads: PropTypes.arrayOf(PropTypes.object),
  noLinks: PropTypes.bool,
  paginationProps: PropTypes.object,
  setSalespersonId: PropTypes.func,
  salespersonId: PropTypes.string,
  withPortfolioManagement: PropTypes.bool,
  withExportSelection: PropTypes.bool,
  handleRequestCsvUpload: PropTypes.func
};

LeadsTable.defaultProps = {
  leads: [],
  generateLead: () => {},
  modifyLead: () => {},
  noLinks: false,
  withPortfolioManagement: false,
  withExportSelection: false,
  handleRequestCsvUpload: () => {}
};

export default LeadsTable;
