import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { useQuery } from "@apollo/client";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  LinearProgress
} from "@mui/material";
import CurrentSalespersonParcelDetailsQuery from "ui/sites/acquisitions/queries/CurrentSalespersonParcelDetailsQuery.graphql";
import ParcelInfoTable from "ui/components/ParcelInfoTable";

function AcquisitionDialogInner({ parcelId, handleClose }) {
  const { loading, data } = useQuery(CurrentSalespersonParcelDetailsQuery, {
    variables: { parcelId }
  });
  const parcel = _.get(data, "currentSalesperson.parcel", {});
  return (
    <Dialog
      open
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      maxWidth="md"
      data-testid="acquisition-dialog"
      fullWidth
    >
      <DialogTitle id="form-dialog-title">Parcel Details</DialogTitle>
      {loading && (
        <>
          <DialogContent>
            <LinearProgress data-testid="loading" />
          </DialogContent>
        </>
      )}
      {!loading && (
        <>
          <DialogContent>
            <ParcelInfoTable parcel={parcel} />
          </DialogContent>
        </>
      )}
    </Dialog>
  );
}

AcquisitionDialogInner.propTypes = {
  parcelId: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired
};

export default AcquisitionDialogInner;
