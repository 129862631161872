import React from "react";

const SiteContext = React.createContext(null);

export function SiteWrapper({ children, site = null }) {
  let derivedSite = site;
  if (site === null) {
    derivedSite = localStorage.getItem("siteContext");
  } else {
    localStorage.setItem("siteContext", site);
  }
  return (
    <SiteContext.Provider value={{ site: derivedSite }}>
      {children}
    </SiteContext.Provider>
  );
}

export default SiteContext;
