import React, { useContext, useState } from "react";
import _ from "lodash";
import { useQuery, useMutation } from "@apollo/client";
import { styled } from "@mui/material/styles";
import {
  Grid,
  IconButton,
  Button,
  CircularProgress,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography
} from "@mui/material";
import { Upload, Help } from "@mui/icons-material";
import Loader from "ui/components/Loader";
import useCursors from "ui/hooks/useCursors";
import usePagination from "ui/hooks/usePagination";
import NotificationsContext from "ui/contexts/NotificationsContext";
import CurrentSalespersonInboundLeadCsvUploadsQuery from "ui/sites/acquisitions/queries/CurrentSalespersonInboundLeadCsvUploadsQuery.graphql";
import InboundLeadCsvUploadRequestMutation from "ui/sites/acquisitions/queries/InboundLeadCsvUploadRequestMutation.graphql";
import InboundLeadCsvUploadProcessMutation from "ui/sites/acquisitions/queries/InboundLeadCsvUploadProcessMutation.graphql";
import AcquisitionsInboundLeadsUploadsTable from "ui/sites/acquisitions/components/AcquisitionsInboundLeadsUploadsTable";

const Input = styled("input")({
  display: "none"
});

async function postDataToPresignedUrl({ event, presignedUrl, fields }) {
  const result = await fetch(presignedUrl, {
    method: "PUT",
    body: event.target.files[0]
  });
  if (result.status > 299) {
    throw new Error(`Error ${result.status} uploading CSV.`);
  }
}

async function getUploadIdAndPresignedUrl({ event, csvRequest }) {
  const { data } = await csvRequest({
    variables: { filename: event.target.files[0].name }
  });

  if (!_.get(data, "inboundLeadCsvUploadRequest.ok", false)) {
    throw new Error(
      _.get(data, "inboundLeadCsvUploadRequest.reason", "Unknown Error")
    );
  }

  const uploadId = _.get(data, "inboundLeadCsvUploadRequest.upload.id", null);
  if (uploadId === null) {
    throw new Error("Error retrieving upload data");
  }

  const presignedUrl = _.get(
    data,
    "inboundLeadCsvUploadRequest.presignedUrl",
    null
  );
  if (presignedUrl === null) {
    throw new Error("Error retrieving presigned URL");
  }

  return { uploadId, presignedUrl };
}

async function queueUploadToBeProcessed({ uploadId, csvProcess }) {
  const { data } = await csvProcess({ variables: { uploadId } });
  if (!_.get(data, "inboundLeadCsvUploadProcess.ok")) {
    throw new Error(
      _.get(data, "inboundLeadCsvUploadProcess.reason", "Unknown error")
    );
  }
}

export function provideHandleUpload({
  notify,
  setLoading,
  csvRequest,
  csvProcess
}) {
  async function handleUpload(event) {
    setLoading(true);

    try {
      const { uploadId, presignedUrl, fields } =
        await getUploadIdAndPresignedUrl({
          event,
          csvRequest
        });
      await postDataToPresignedUrl({ event, presignedUrl, fields });
      await queueUploadToBeProcessed({ uploadId, csvProcess });
      notify({
        variant: "success",
        message: "Upload successfully enqueued! Please check back shortly."
      });
    } catch (e) {
      notify({ variant: "error", message: e.message });
    }

    setLoading(false);
  }
  return handleUpload;
}

function AcquisitionsInboundLeadsUploadsContainer() {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const { notify } = useContext(NotificationsContext);
  const cursors = useCursors();
  const variables = _.pick(cursors, ["first", "after"]);
  const { data, loading: queryLoading } = useQuery(
    CurrentSalespersonInboundLeadCsvUploadsQuery,
    {
      variables,
      pollInterval: 5000
    }
  );
  const [csvRequest] = useMutation(InboundLeadCsvUploadRequestMutation);
  const [csvProcess] = useMutation(InboundLeadCsvUploadProcessMutation);

  const handleUpload = provideHandleUpload({
    csvRequest,
    csvProcess,
    setLoading,
    notify
  });

  const { paginationProps } = usePagination({
    nextAfter: _.get(
      data,
      "currentSalesperson.inboundleadcsvuploadSet.pageInfo.endCursor"
    ),
    count: _.get(data, "currentSalesperson.inboundleadcsvuploadSet.totalCount"),
    ...cursors
  });

  if (queryLoading) {
    return <Loader />;
  }

  const uploads = _.map(
    _.get(data, "currentSalesperson.inboundleadcsvuploadSet.edges"),
    "node"
  );

  return (
    <>
      <Grid item xs={12}>
        <AcquisitionsInboundLeadsUploadsTable
          paginationProps={paginationProps}
          uploads={uploads}
        />
      </Grid>
      <Grid
        item
        xs={12}
        justifyContent="flex-end"
        style={{ marginTop: 10 }}
        align="right"
      >
        <label htmlFor="contained-button-file">
          <Input
            accept="text/csv"
            id="contained-button-file"
            multiple
            type="file"
            onChange={handleUpload}
          />
          <Tooltip title="Upload a CSV">
            <Button variant="contained" component="span" disabled={loading}>
              {!loading && (
                <>
                  <Upload /> Upload
                </>
              )}
              {loading && <CircularProgress />}
            </Button>
          </Tooltip>
        </label>
        <IconButton onClick={() => setOpen(true)}>
          <Help />
        </IconButton>
      </Grid>
      <Dialog open={open} onClose={() => setOpen(false)} maxWidth="md">
        <DialogTitle>CSV Upload Instructions</DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item xs={12}>
              <Typography>
                Upload a file where each line has the following format:
              </Typography>
              <pre>PREFORECLOSURE,HOT,123 some street, my town, GA, 30329</pre>
              <pre>
                SOME OTHER CATEGORY,COLD,456 other street, other town, CA, 90210
              </pre>
              <Typography>
                Please note that the first two comma-separated fields should
                correspond to the desired category and lead status
                (respectively) for the parcel in question.
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default AcquisitionsInboundLeadsUploadsContainer;
