import React, { useContext } from "react";
import PropTypes from "prop-types";
import { IconButton } from "@mui/material";
import { Cancel } from "@mui/icons-material";
import NotificationsContext from "ui/contexts/NotificationsContext";
import actAndNotify from "ui/lib/actAndNotify";
import { useUnderwritingActions } from "ui/sites/underwriting/contexts/UnderwritingActionsContext";

function DeleteRequestAction({ request }) {
  const { notify } = useContext(NotificationsContext);
  const { deleteRequest, refetchQueries } = useUnderwritingActions(
    request.lead.parcel.id
  );
  const onClick = actAndNotify(deleteRequest, "underwritingRequestDelete", {
    notify,
    mutateOptions: {
      variables: { underwritingRequestId: request.id },
      refetchQueries
    },
    successMessage: "Deleted request!"
  });
  return (
    <IconButton onClick={onClick}>
      <Cancel />
    </IconButton>
  );
}

DeleteRequestAction.propTypes = {
  request: PropTypes.object.isRequired
};

export default DeleteRequestAction;
