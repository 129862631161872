import React from "react";
import _ from "lodash";
import { useQuery } from "@apollo/client";
import { Navigate } from "react-router-dom";
import { Grid, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { SiteWrapper } from "ui/contexts/SiteContext";
import { SidenavWrapper } from "ui/contexts/SidenavContext";
import CurrentUserActivityQuery from "ui/queries/CurrentUserActivityQuery.graphql";
import useCursors from "ui/hooks/useCursors";
import usePagination from "ui/hooks/usePagination";
import Auditlog from "ui/components/Auditlog";
import DynamicSidenav from "ui/components/DynamicSidenav";

const useStyles = makeStyles(theme => ({
  container: {
    height: "100%"
  }
}));

function ActivityPage() {
  const classes = useStyles();
  const cursors = useCursors();
  const variables = _.pick(cursors, ["first", "after"]);
  const { loading, data } = useQuery(CurrentUserActivityQuery, {
    variables
  });
  const { paginationProps } = usePagination({
    nextAfter: _.get(data, "currentUser.activity.pageInfo.endCursor"),
    count: _.get(data, "currentUser.activity.totalCount"),
    ...cursors
  });

  if (!loading && data.currentUser === null) {
    return <Navigate to="/" />;
  }

  return (
    <Grid container className={classes.container} data-testid="my-activity">
      <SiteWrapper>
        <SidenavWrapper
          Component={props => (
            <DynamicSidenav
              showLogout={data?.currentUser !== null}
              {...props}
            />
          )}
        >
          <Grid item xs container>
            <Grid item xs={12}>
              <Typography align="center" variant="h2">
                My Activity
              </Typography>
              <Grid item xs={12}>
                <Auditlog
                  logEntries={_.map(
                    _.get(data, "currentUser.activity.edges"),
                    "node"
                  )}
                  loading={loading}
                  paginationProps={paginationProps}
                />
              </Grid>
            </Grid>
          </Grid>
        </SidenavWrapper>
      </SiteWrapper>
    </Grid>
  );
}

export default ActivityPage;
