import React, { useContext } from "react";
import { useMutation } from "@apollo/client";
import { useParams } from "react-router-dom";
import { Grid, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { EmptySidenav } from "ui/components/GlobalSidenav";
import PasswordResetForm from "ui/components/PasswordResetForm";
import NotificationsContext, {
  withNotifications
} from "ui/contexts/NotificationsContext";
import actAndNotify from "ui/lib/actAndNotify";
import PasswordResetPerformMutation from "ui/queries/PasswordResetPerformMutation.graphql";

const useStyles = makeStyles(theme => ({
  resetPassword: {
    marginTop: theme.spacing(10)
  }
}));

function PasswordResetPerformPage() {
  const classes = useStyles();
  const { token } = useParams();

  const { notify } = useContext(NotificationsContext);
  const [mutate] = useMutation(PasswordResetPerformMutation);

  async function handleSubmit({ email, password }) {
    await actAndNotify(mutate, "passwordResetPerform", {
      notify,
      mutateOptions: {
        variables: { email, password, token }
      },
      successMessage: (
        <>
          You've successfully reset your password!{" "}
          <a href="/">Return to Login</a>
        </>
      )
    })();
  }

  return (
    <Grid item xs={12} container alignItems="flex-start">
      <EmptySidenav />
      <Grid item xs container alignItems="flex-start">
        <Grid item xs={12} className={classes.resetPassword}>
          <Typography align="center" variant="h4">
            Reset Your Password
          </Typography>
        </Grid>
        <Grid item container xs={12} align="center" justifyContent="center">
          <Grid item xs={4}>
            <PasswordResetForm handleSubmit={handleSubmit} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default withNotifications(PasswordResetPerformPage);
