import * as Yup from "yup";
import _ from "lodash";

export const fieldsToLabel = {
  askingPrice: "Asking Price",
  approvedPurchasePrice: "Approved Purchase Price",
  budget: "Budget",
  daysUntilCompletion: "Days Until Completion",
  afterRepairValue: "After-Repair Value",
  dispoCosts: "Disposition Cost",
  insurance: "Insurance",
  utilities: "Utilities",
  propertyTaxes: "Property Taxes",
  taxRate: "Tax Rate"
};

export default Yup.object().shape(
  _.fromPairs(
    _.map(_.toPairs(fieldsToLabel), ([field, label]) => [
      field,
      Yup.number().required("Required Field").label(label)
    ])
  )
);
