import { useState } from "react";
import {
  getStringFromLocalStorage,
  getIntegerFromLocalStorage,
  getJSONArrayFromLocalStorage
} from "ui/lib/localStorage";

export function useCursors(initialFirst = 25, localStorageSlug = null) {
  const [first, setFirst] = useState(
    localStorageSlug === null
      ? initialFirst
      : getIntegerFromLocalStorage(`${localStorageSlug}First`, initialFirst)
  );
  const [after, setAfter] = useState(
    localStorageSlug === null
      ? null
      : getStringFromLocalStorage(`${localStorageSlug}After`)
  );
  const [lastCursor, setLastCursor] = useState(
    localStorageSlug === null
      ? []
      : getJSONArrayFromLocalStorage(`${localStorageSlug}LastCursor`)
  );
  return {
    first,
    setFirst:
      localStorageSlug === null
        ? setFirst
        : val => {
            localStorage.setItem(`${localStorageSlug}First`, val);
            setFirst(val);
          },
    after,
    setAfter:
      localStorageSlug === null
        ? setAfter
        : val => {
            localStorage.setItem(`${localStorageSlug}After`, val);
            setAfter(val);
          },
    lastCursor,
    setLastCursor:
      localStorageSlug === null
        ? setLastCursor
        : val => {
            localStorage.setItem(
              `${localStorageSlug}LastCursor`,
              JSON.stringify(val)
            );
            setLastCursor(val);
          }
  };
}

export default useCursors;
