import React, { useContext } from "react";
import _ from "lodash";
import { CircularProgress } from "@mui/material";
import GoogleMapReact from "google-maps-react-markers";
import useWindowDimensions from "ui/hooks/useWindowDimensions";
import useMapCentering from "ui/hooks/useMapCentering";
import MapContext from "ui/contexts/MapContext";
import MapLayerContext from "ui/contexts/MapLayerContext";
import ParcelMarker from "ui/components/ParcelMarker";

function CircularProgressWrapped() {
  return <CircularProgress />;
}

function PortfolioMap({
  loading = false,
  parcels = [],
  selectedParcel,
  setSelectedParcel
}) {
  const windowDimensions = useWindowDimensions();
  const mapContext = useContext(MapContext);
  const mapLayerContext = useContext(MapLayerContext);
  useMapCentering({ ...mapContext, ignoreMapChanges: false }, parcels);
  const { defaultCenter, defaultZoom, center } = mapContext;
  const onGoogleApiLoaded = _.first([
    mapLayerContext.onGoogleApiLoaded,
    mapContext.onGoogleApiLoaded
  ]);
  return (
    <div
      style={{
        height: windowDimensions.height - 600,
        width: "100%"
      }}
      data-testid="portfolio-map"
    >
      <GoogleMapReact
        apiKey={process.env.GOOGLE_API_KEY}
        libraries={["places"]}
        defaultCenter={center}
        center={center}
        defaultZoom={defaultZoom}
        yesIWantToUseGoogleMapApiInternals
        options={{ streetViewControl: true }}
        onGoogleApiLoaded={onGoogleApiLoaded}
      >
        {loading && (
          <CircularProgressWrapped
            lat={_.get(center, "latitude", defaultCenter.lat)}
            lng={_.get(center, "longitude", defaultCenter.lng)}
          />
        )}
        {_.map(parcels, parcel => {
          const {
            id,
            coordinates: {
              coordinates: [lng, lat]
            }
          } = parcel;
          return (
            <ParcelMarker
              key={id}
              lng={lng}
              lat={lat}
              parcel={parcel}
              open={id === selectedParcel}
              setOpen={x => setSelectedParcel(x)}
            />
          );
        })}
      </GoogleMapReact>
    </div>
  );
}

export default PortfolioMap;
