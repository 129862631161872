import React from "react";
import _ from "lodash";
import { useQuery } from "@apollo/client";
import { Grid } from "@mui/material";
import Loader from "ui/components/Loader";
import useCursors from "ui/hooks/useCursors";
import usePagination from "ui/hooks/usePagination";
import CurrentSalespersonInboundLeadsQuery from "ui/sites/acquisitions/queries/CurrentSalespersonInboundLeadsQuery.graphql";
import AcquisitionsInboundLeadsTable from "ui/sites/acquisitions/components/AcquisitionsInboundLeadsTable";

function AcquisitionsInboundLeadsPage() {
  const cursors = useCursors();
  const variables = _.pick(cursors, ["first", "after"]);
  const { data, loading } = useQuery(CurrentSalespersonInboundLeadsQuery, {
    variables
  });

  const { paginationProps } = usePagination({
    nextAfter: _.get(
      data,
      "currentSalesperson.inboundLeads.pageInfo.endCursor"
    ),
    count: _.get(data, "currentSalesperson.inboundLeads.totalCount"),
    ...cursors
  });

  if (loading) {
    return <Loader />;
  }

  const inboundLeads = _.map(
    _.get(data, "currentSalesperson.inboundLeads.edges"),
    "node"
  );

  return (
    <Grid item xs={12}>
      <AcquisitionsInboundLeadsTable
        paginationProps={paginationProps}
        inboundLeads={inboundLeads}
      />
    </Grid>
  );
}

export default AcquisitionsInboundLeadsPage;
