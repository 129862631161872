import _ from "lodash";
import showOwnerOccupiedSummary from "ui/components/MapLayers/showOwnerOccupiedSummary";
import {
  availableDemographics,
  incomeBrackets,
  summarizableDemographics
} from "ui/lib/demographics";

export function renderIncomeData(
  map,
  demographics,
  demographicSelection,
  setDemographics,
  survey,
  censusTracts,
  newDemographic
) {
  // income data
  function handleDemographics() {
    const updatedDemographics = _.fromPairs(
      _.map(
        _.filter(
          _.uniqBy(_.concat(censusTracts, _.map(demographics, "tract")), "id"),
          tract => !_.isEmpty(_.get(tract, "geoJson"))
        ),
        tract => {
          const data = {
            ...JSON.parse(tract.p7Data),
            ...JSON.parse(tract.p8Data),
            ...JSON.parse(tract.incomeData)
          };
          const income = _.floor(_.get(data, "S1901_C01_012E", 0));
          // eslint-disable-next-line
          const bracket = _.find(incomeBrackets, (v, k) => {
            return k > income;
          });
          if (
            _.has(demographics, tract.id) &&
            _.has(demographics[tract.id], "polygon")
          ) {
            demographics[tract.id].polygon.setOptions({
              fillColor: bracket.color,
              fillOpacity: 0.5
            });
            return [tract.id, demographics[tract.id]];
          } else {
            const demographic = newDemographic(
              map,
              tract,
              { fillColor: bracket.color, fillOpacity: 0.8 },
              data
            );
            return [tract.id, demographic];
          }
        }
      )
    );
    setDemographics(updatedDemographics);
  }
  _.debounce(handleDemographics, 500, {
    trailing: true
  })();
}

export function renderDemographicLayer(
  map,
  demographics,
  demographicSelection,
  setDemographics,
  survey,
  censusTracts,
  newDemographic
) {
  function handleDemographics() {
    const totalField = _.findKey(
      availableDemographics,
      ({ title }, slug) => slug.startsWith(survey) && title === "Total"
    );
    _.map(demographics, demographic => {
      demographic.polygon.setVisible(true);
    });
    const updatedDemographics = _.fromPairs(
      _.map(
        _.filter(
          _.uniqBy(_.concat(censusTracts, _.map(demographics, "tract")), "id"),
          tract => !_.isEmpty(_.get(tract, "geoJson"))
        ),
        tract => {
          const data = {
            ...JSON.parse(tract.p7Data),
            ...JSON.parse(tract.p8Data),
            ...JSON.parse(tract.incomeData)
          };
          const population = parseFloat(data[demographicSelection]);
          const total = parseFloat(data[totalField]);
          const percentage = population === null ? 0.00001 : population / total;
          if (_.has(demographics, tract.id)) {
            demographics[tract.id].polygon.setOptions({
              fillColor: availableDemographics[demographicSelection].color,
              fillOpacity: percentage
            });
            return [tract.id, demographics[tract.id]];
          } else {
            const demographic = newDemographic(
              map,
              tract,
              {
                fillColor: availableDemographics[demographicSelection].color,
                fillOpacity: percentage
              },
              data
            );
            return [tract.id, demographic];
          }
        }
      )
    );
    setDemographics(updatedDemographics);
  }
  _.debounce(handleDemographics, 500, {
    trailing: true
  })();
}

export function renderDemographicSummaryLayer(
  map,
  demographics,
  demographicSelection,
  setDemographics,
  survey,
  censusTracts,
  newDemographic
) {
  function handleDemographics() {
    const totalField = _.findKey(
      availableDemographics,
      ({ title }, slug) => title === "Total"
    );
    _.map(demographics, demographic => {
      demographic.polygon.setVisible(true);
    });
    const updatedDemographics = _.fromPairs(
      _.map(
        _.filter(
          _.uniqBy(_.concat(censusTracts, _.map(demographics, "tract")), "id"),
          tract => !_.isEmpty(_.get(tract, "geoJson"))
        ),
        tract => {
          const data = {
            ...JSON.parse(tract.p7Data),
            ...JSON.parse(tract.p8Data)
          };
          const total = parseFloat(data[totalField]);

          // iterate over all populations and figure out the largest one
          // remove "total" and "not hispanic"
          const mapped = _.map(
            _.pick(data, summarizableDemographics),
            (populationString, key) => {
              const population = parseFloat(populationString) || 0;
              const percentage =
                population === 0 ? 0.00001 : population / total;
              return { population, percentage, key };
            }
          );
          const { percentage, key } = _.maxBy(mapped, "percentage");

          if (_.has(demographics, tract.id)) {
            demographics[tract.id].polygon.setOptions({
              fillColor: availableDemographics[key].color,
              fillOpacity: percentage
            });
            return [tract.id, demographics[tract.id]];
          } else {
            const demographic = newDemographic(
              map,
              tract,
              {
                fillColor: availableDemographics[key].color,
                fillOpacity: percentage
              },
              data
            );
            return [tract.id, demographic];
          }
        }
      )
    );
    setDemographics(updatedDemographics);
  }
  _.debounce(handleDemographics, 500, {
    trailing: true
  })();
}

const renterPctToColor = {
  0.4: { fillColor: "#dddd22", fillOpacity: 0.8 },
  0.3: { fillColor: "#aaaa22", fillOpacity: 0.6 },
  0.2: { fillColor: "#888822", fillOpacity: 0.4 }
};

export function renderOwnerOccupationLayer(
  map,
  demographics,
  demographicSelection,
  setDemographics,
  survey,
  censusTracts,
  newDemographic
) {
  function handleDemographics() {
    _.map(demographics, demographic => {
      demographic.polygon.setVisible(true);
    });
    const updatedDemographics = _.fromPairs(
      _.map(
        _.filter(
          _.uniqBy(_.concat(censusTracts, _.map(demographics, "tract")), "id"),
          tract => !_.isEmpty(_.get(tract, "geoJson"))
        ),
        tract => {
          const data = {
            ...JSON.parse(tract.acsS2504)
          };

          data.totalOwnerOccupied = parseInt(data.S2504_C01_001E);
          data.totalRenterOccupied = parseInt(data.S2504_C03_001E);
          data.total = data.totalOwnerOccupied + data.totalRenterOccupied;

          let fillData = { fillColor: "#666622", fillOpacity: 0.2 };

          if (data.totalOwnerOccupied && data.totalRenterOccupied) {
            data.pctOwnerOccupied = data.totalOwnerOccupied / data.total;
            data.pctRenterOccupied = data.totalRenterOccupied / data.total;
            _.forEach(renterPctToColor, (val, pct) => {
              if (data.pctRenterOccupied > pct) {
                fillData = val;
                return false;
              }
            });
          } else {
            data.pctOwnerOccupied = 0;
            data.pctRenterOccupied = 0;
          }

          const demographic = newDemographic(
            map,
            tract,
            {
              ...fillData
            },
            data,
            showOwnerOccupiedSummary
          );
          return [tract.id, demographic];
        }
      )
    );
    setDemographics(updatedDemographics);
  }
  _.debounce(handleDemographics, 500, {
    trailing: true
  })();
}

const SURVEY_TO_RENDER = {
  P7: renderDemographicLayer,
  P8: renderDemographicLayer,
  summary: renderDemographicSummaryLayer,
  ownerOccupied: renderOwnerOccupationLayer
};

export function renderDemographics(
  map,
  demographics,
  demographicSelection,
  setDemographics,
  censusTracts,
  newDemographic
) {
  const survey = demographicSelection.split("_")[0];
  _.get(SURVEY_TO_RENDER, survey, renderIncomeData)(
    map,
    demographics,
    demographicSelection,
    setDemographics,
    survey,
    censusTracts,
    newDemographic
  );
}
