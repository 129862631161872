import React from "react";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/client";
import Loader from "ui/components/Loader";
import ParcelImages from "ui/components/ParcelImages";
import { useLeadActions } from "ui/sites/acquisitions/contexts/LeadActionsContext";
import CurrentSalesPersonParcelImagesQuery from "ui/sites/acquisitions/queries/CurrentSalespersonParcelImagesQuery.graphql";

function AcquisitionsParcelImagesContainer({ parcelId }) {
  const { data, loading } = useQuery(CurrentSalesPersonParcelImagesQuery, {
    variables: { parcelId }
  });
  const { parcelImageUpload, parcelImageDelete } = useLeadActions(parcelId);

  if (loading) {
    return <Loader />;
  }
  return (
    <ParcelImages
      parcel={data.currentSalesperson?.parcel}
      uploadMutation={parcelImageUpload}
      deleteMutation={parcelImageDelete}
    />
  );
}

AcquisitionsParcelImagesContainer.propTypes = {
  parcelId: PropTypes.string.isRequired
};

export default AcquisitionsParcelImagesContainer;
