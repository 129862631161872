import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { amber, green, red, grey } from "@mui/material/colors";
import { Snackbar, Button, IconButton, Paper } from "@mui/material";
import {
  CheckCircle as CheckCircleIcon,
  Error as ErrorIcon,
  Info as InfoIcon,
  Close as CloseIcon,
  Warning as WarningIcon
} from "@mui/icons-material";

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon
};

const useStyles = makeStyles(theme => ({
  success: {
    backgroundColor: green[750]
  },
  error: {
    backgroundColor: red[500]
  },
  info: {
    backgroundColor: grey[200]
  },
  warning: {
    backgroundColor: amber[850]
  },
  icon: {
    fontSize: 20
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1)
  },
  bar: {
    backgroundColor: grey[850],
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    padding: theme.spacing(2.5, 5)
  }
}));

function AlineaSnackbar({ open, message, variant, closeFn, link, fixed }) {
  const classes = useStyles();
  const handleClose = () => {
    closeFn();
  };
  const Icon = variantIcon[variant];
  const action = [];
  if (link) {
    action.push([
      <Button
        key="undo"
        color="secondary"
        size="small"
        href={link.link || "#"}
        onClick={handleClose}
      >
        {link.name}
      </Button>
    ]);
  }
  action.push(
    <IconButton
      key="close"
      aria-label="Close"
      color="inherit"
      className={classes.close}
      onClick={handleClose}
      size="large">
      <CloseIcon />
    </IconButton>
  );

  const autoHideProps = {};

  if (!fixed) {
    autoHideProps.autoHideDuration = 5000;
  }

  return (
    <div data-testid={`snackbar-${open ? "open" : "closed"}`}>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        open={open}
        onClose={handleClose}
        ContentProps={{
          "aria-describedby": "message-id"
        }}
        action={action}
        message=""
        {...autoHideProps}
      >
        <Paper className={classes.bar}>
          <Icon className={`${classes.icon} ${classes.iconVariant}`} />
          <span id="message-id">{message}</span>
        </Paper>
      </Snackbar>
    </div>
  );
}

Snackbar.propTypes = {
  open: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
  // no idea why it becomes undefined sometimes
  variant: PropTypes.string,
  closeFn: PropTypes.func,
  link: PropTypes.shape({
    link: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
  }),
  fixed: PropTypes.bool
};

AlineaSnackbar.defaultProps = {
  fixed: false,
  variant: "info",
  closeFn: () => {}
};

export default AlineaSnackbar;
