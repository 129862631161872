import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { Formik, Form } from "formik";
import {
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableFooter,
  TableRow,
  TextField,
  InputAdornment,
  Paper
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Loader from "ui/components/Loader";
import RentalPurchaseValueValidation, {
  fieldsToLabel
} from "./RentalPurchaseValueValidation";

const useStyles = makeStyles(() => ({
  table: { width: "100%" }
}));

const fieldsToInputProps = {
  askingPrice: {
    startAdornment: <InputAdornment position="start">$</InputAdornment>
  },
  approvedPurchasePrice: {
    startAdornment: <InputAdornment position="start">$</InputAdornment>
  },
  budget: {
    startAdornment: <InputAdornment position="start">$</InputAdornment>
  },
  rent: {
    startAdornment: <InputAdornment position="start">$</InputAdornment>
  },
  estimatedSalePrice: {
    startAdornment: <InputAdornment position="start">$</InputAdornment>
  },
  expenseRatio: {
    endAdornment: <InputAdornment position="end">%</InputAdornment>
  },
  rentalIncreaseRate: {
    endAdornment: <InputAdornment position="end">%</InputAdornment>
  },
  taxRate: { endAdornment: <InputAdornment position="end">%</InputAdornment> }
};

function RentalPurchaseValueWorksheetForm({
  loading = false,
  onSubmit = null,
  worksheet = {}
}) {
  const classes = useStyles();
  const readOnly = onSubmit === null || loading;
  const getError = ({ touched, field, errors }) =>
    touched[field] ? errors[field] : undefined;
  return (
    <Formik
      initialValues={{ ...worksheet }}
      validationSchema={RentalPurchaseValueValidation}
      onSubmit={onSubmit}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleSubmit,
        isSubmitting
      }) => (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Form
              onSubmit={handleSubmit}
              data-testid="rental-purchase-value-form"
            >
              <TableContainer component={Paper}>
                <Table
                  className={classes.table}
                  size="small"
                  data-testid="purchase-value-table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <b>Type</b>
                      </TableCell>
                      <TableCell>Amount</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {_.toPairs(fieldsToLabel).map(([field, label]) => (
                      <TableRow
                        key={field}
                        data-testid={`Rental-purchase-value-table-${field}`}
                      >
                        <TableCell>
                          <b>{label}</b>
                        </TableCell>
                        <TableCell data-testid={`cell-${field}`}>
                          <TextField
                            name={field}
                            id={field}
                            fullWidth
                            disabled={isSubmitting}
                            onChange={handleChange}
                            value={values[field] || ""}
                            error={Boolean(
                              getError({ field, errors, touched })
                            )}
                            helperText={getError({
                              field,
                              errors,
                              touched
                            })}
                            InputProps={{
                              ...fieldsToInputProps[field],
                              readOnly
                            }}
                            inputProps={{
                              "data-testid": `${field}-input`
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                    {_.has(worksheet, "roi") && (
                      <TableRow>
                        <TableCell>
                          <b>ROI</b>
                        </TableCell>
                        <TableCell data-testid="cell-roi">
                          <TextField
                            id="roi"
                            InputProps={{
                              readOnly: true,
                              startAdornment: (
                                <InputAdornment position="start">
                                  $
                                </InputAdornment>
                              )
                            }}
                            value={worksheet.roi}
                          />
                        </TableCell>
                      </TableRow>
                    )}
                    {_.has(worksheet, "annualizedReturn") && (
                      <TableRow>
                        <TableCell>
                          <b>Annualized Return</b>
                        </TableCell>
                        <TableCell data-testid="cell-annualizedReturn">
                          <TextField
                            id="annualizedReturn"
                            InputProps={{
                              readOnly: true,
                              startAdornment: (
                                <InputAdornment position="start">
                                  $
                                </InputAdornment>
                              )
                            }}
                            value={worksheet.annualizedReturn}
                          />
                        </TableCell>
                      </TableRow>
                    )}
                    {_.has(worksheet, "profitAfterTax") && (
                      <TableRow>
                        <TableCell>
                          <b>Profit After Tax</b>
                        </TableCell>
                        <TableCell data-testid="cell-profitAfterTax">
                          <TextField
                            id="profitAfterTax"
                            InputProps={{
                              readOnly: true,
                              startAdornment: (
                                <InputAdornment position="start">
                                  $
                                </InputAdornment>
                              )
                            }}
                            value={worksheet.profitAfterTax}
                          />
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                  {(loading || !readOnly) && (
                    <TableFooter>
                      <TableRow>
                        <TableCell colSpan={2} align="right">
                          {loading && <Loader />}
                          {!loading && (
                            <Button
                              variant="contained"
                              size="large"
                              type="submit"
                              color="primary"
                              data-testid="rental-purchase-value-worksheet-form-submit"
                            >
                              Submit
                            </Button>
                          )}
                        </TableCell>
                      </TableRow>
                    </TableFooter>
                  )}
                </Table>
              </TableContainer>
            </Form>
          </Grid>
        </Grid>
      )}
    </Formik>
  );
}

RentalPurchaseValueWorksheetForm.propTypes = {
  onSubmit: PropTypes.func,
  worksheet: PropTypes.object,
  loading: PropTypes.bool
};

export default RentalPurchaseValueWorksheetForm;
