import React from "react";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/client";
import { Grid, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Loader from "ui/components/Loader";
import { loginRequired } from "ui/providers/CurrentUserProvider";
import CurrentUnderwriterQuery from "ui/sites/underwriting/queries/CurrentUnderwriterQuery.graphql";
import CurrentUnderwriterContext from "ui/sites/underwriting/contexts/CurrentUnderwriterContext";

const useStyles = makeStyles(theme => ({
  loginForm: {
    marginTop: theme.spacing(10)
  }
}));

function CurrentUnderwriterProvider({ children }) {
  const { data, loading } = useQuery(CurrentUnderwriterQuery);
  const classes = useStyles();

  if (loading) {
    return (
      <Grid item xs={12}>
        <Loader />
      </Grid>
    );
  }
  if (data?.currentUnderwriter === null) {
    return (
      <>
        <Grid item xs={12} className={classes.loginForm}>
          <Typography align="center" variant="h4" data-testid="access-denied">
            Access Denied
          </Typography>
        </Grid>
      </>
    );
  }

  return (
    <CurrentUnderwriterContext.Provider
      value={{ currentUnderwriter: data?.currentUnderwriter }}
    >
      {children}
    </CurrentUnderwriterContext.Provider>
  );
}

CurrentUnderwriterProvider.propTypes = {
  children: PropTypes.node
};

export default loginRequired(CurrentUnderwriterProvider);
