import React from "react";
import _ from "lodash";
import {
  Grid,
  Tooltip,
  IconButton,
  Typography,
  Divider,
  FormGroup,
  Chip,
  TextField,
  InputAdornment
} from "@mui/material";
import { greyText } from "ui/themes/AlineaTheme/colors";
import { Menu, Search, Clear } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import FindOrCreateParcelButton from "ui/sites/acquisitions/components/FindOrCreateParcelButton";
import MultiFilter from "../../../../../components/MultiFilter/MultiFilter";
import DateFilter from "./DateFilter";

const useStyles = makeStyles(theme => ({
  filtersSidenav: {
    backgroundColor: "#3f51b5",
    paddingRight: "5px"
  },
  sideNavText: {
    color: "#fff"
  },
  search: {
    color: greyText,
    width: "100%"
  },
  searchLabel: {
    color: greyText
  },
  searchInput: {
    background: "#fff",
    borderRadius: "5px",
    margin: theme.spacing(1)
  },
  chip: {
    marginTop: 15,
    marginLeft: 10
  },
  formControlLabel: {
    color: "#fff",
    margin: theme.spacing(0.3)
  },
  checkbox: {
    color: "#fff !important"
  },
  findButtonWrapper: {
    margin: theme.spacing(1)
  },
  categoriesHeadingWrapper: {
    textAlign: "center"
  },
  categoriesHeading: {
    color: "#fff"
  },
  categoryChip: {
    margin: theme.spacing(0.5)
  },
  categoriesWrapper: {
    maxHeight: "75vh",
    overflowY: "scroll",
    textAlign: "center",
    paddingRight: "17px"
  },
  autocompleteInput: {
    backgroundColor: "white",
    margin: theme.spacing(1),
    borderRadius: "5px"
  }
}));

function AcquisitionsParcelsPageFilterSidenav({
  expanded,
  setExpanded,
  includeClaimed,
  toggleIncludeClaimed,
  onSubmit,
  searchText,
  setSearchText,
  search,
  clearSearch,
  zoningcategories,
  zoningcategoriesLoading,
  selectedZoningcategories,
  setSelectedZoningcategories,
  nonzoningcategories,
  nonzoningcategoriesLoading,
  selectedNonzoningcategories,
  setSelectedNonzoningcategories,
  lenders,
  lendersLoading,
  selectedLenderIds,
  setSelectedLenderIds,
  categoryGroupings,
  selectedCategoryGroupings,
  categoryGroupingsLoading,
  setSelectedCategoryGroupings,
  loanDateRange,
  setLoanDateRange,
  loanMaturityDateRange,
  setLoanMaturityDateRange,
  purchaseDateRange,
  setPurchaseDateRange
}) {
  const classes = useStyles();

  const alphabeticalzoningCategories = _.map(
    _.sortBy(zoningcategories, ({ name }) => _.lowerCase(name)),
    ({ name }) => ({
      label: name
    })
  );
  const alphabeticalnonzoningCategories = _.map(
    _.sortBy(nonzoningcategories, ({ name }) => _.lowerCase(name)),
    ({ name }) => ({
      label: name
    })
  );

  const idToLenderName = {};
  const alphabeticalLenders = _.map(
    _.sortBy(lenders, ({ name }) => _.lowerCase(name)),
    ({ id, name }) => {
      idToLenderName[id] = name;
      return {
        label: `${name}`,
        value: id
      };
    }
  );

  const alphabeticalCategoryGroupings = _.map(
    _.sortBy(categoryGroupings, ({ name }) => _.lowerCase(name)),
    ({ name }) => ({
      label: name
    })
  );

  const toggleSelectedNonzoningCategory = function (name) {
    return () =>
      setSelectedNonzoningcategories(
        _.xor(selectedNonzoningcategories, [name])
      );
  };

  const toggleSelectedZoningCategory = function (name) {
    return () =>
      setSelectedZoningcategories(_.xor(selectedZoningcategories, [name]));
  };

  const toggleSelectedCategoryGrouping = function (name) {
    return () =>
      setSelectedCategoryGroupings(_.xor(selectedCategoryGroupings, [name]));
  };

  const toggleSelectedLenderId = function (lenderId) {
    return () => {
      setSelectedLenderIds(_.xor(selectedLenderIds, [lenderId]));
    };
  };

  return (
    <Grid item xs={expanded ? 2 : 1} className={classes.filtersSidenav}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Tooltip title={expanded ? "Minimize" : "Expand"} placement="right">
            <IconButton onClick={() => setExpanded(!expanded)}>
              <Menu className={classes.sideNavText} />
            </IconButton>
          </Tooltip>
          <Typography variant="caption" className={classes.sideNavText}>
            Filters
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <MultiFilter
          autocompleteOptions={alphabeticalnonzoningCategories}
          loading={nonzoningcategoriesLoading}
          title="Categories"
          onChange={(event, newValue) => {
            if (newValue.label) {
              toggleSelectedNonzoningCategory(newValue.label)();
            }
          }}
          defaultOpen={!_.isEmpty(selectedNonzoningcategories)}
          isOptionEqualToValue={(option, value) => option.label === value}
          selectedValues={selectedNonzoningcategories}
          toggleSelectedValue={toggleSelectedNonzoningCategory}
          renderChips={() =>
            _.map(selectedNonzoningcategories, name => (
              <Chip
                key={name}
                label={`${name}`}
                variant="filled"
                color="warning"
                data-testid={`categories-${name}`}
                onDelete={toggleSelectedNonzoningCategory(name)}
                deleteIcon={<Clear />}
                className={classes.categoryChip}
              />
            ))
          }
        />
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <MultiFilter
          autocompleteOptions={alphabeticalzoningCategories}
          loading={nonzoningcategoriesLoading}
          title="Zoning Categories"
          onChange={(event, newValue) => {
            if (newValue.label) {
              toggleSelectedZoningCategory(newValue.label)();
            }
          }}
          defaultOpen={!_.isEmpty(selectedZoningcategories)}
          isOptionEqualToValue={(option, value) => option.label === value}
          selectedValues={selectedZoningcategories}
          toggleSelectedValue={toggleSelectedZoningCategory}
          renderChips={() =>
            _.map(selectedZoningcategories, name => (
              <Chip
                key={name}
                label={`${name}`}
                variant="filled"
                color="warning"
                data-testid={`categories-${name}`}
                onDelete={toggleSelectedZoningCategory(name)}
                deleteIcon={<Clear />}
                className={classes.categoryChip}
              />
            ))
          }
        />
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <MultiFilter
          autocompleteOptions={alphabeticalLenders}
          loading={lendersLoading}
          title="Lenders"
          onChange={(event, newValue) => {
            if (newValue.label) {
              toggleSelectedLenderId(newValue.value)();
            }
          }}
          isOptionEqualToValue={(option, value) => option.value === value}
          selectedValues={selectedLenderIds}
          defaultOpen={!_.isEmpty(selectedLenderIds)}
          toggleSelectedValue={toggleSelectedLenderId}
          renderChips={() =>
            _.map(selectedLenderIds, lenderId => {
              return (
                <Chip
                  key={lenderId}
                  label={idToLenderName[lenderId]}
                  variant="filled"
                  color="warning"
                  data-testid={`lender-${lenderId}`}
                  onDelete={toggleSelectedLenderId(lenderId)}
                  deleteIcon={<Clear />}
                  className={classes.categoryChip}
                />
              );
            })
          }
        />
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <MultiFilter
          autocompleteOptions={alphabeticalCategoryGroupings}
          loading={categoryGroupingsLoading}
          title="Property Type"
          onChange={(event, newValue) => {
            if (newValue.label) {
              toggleSelectedCategoryGrouping(newValue.label)();
            }
          }}
          isOptionEqualToValue={(option, value) => {
            return option.label === value.label;
          }}
          defaultOpen={!_.isEmpty(selectedCategoryGroupings)}
          selectedValues={selectedCategoryGroupings}
          toggleSelectedValue={toggleSelectedCategoryGrouping}
          renderChips={() =>
            _.map(selectedCategoryGroupings, name => (
              <Chip
                key={name}
                label={`${name}`}
                variant="filled"
                color="warning"
                data-testid={`categorygrouping-${name}`}
                onDelete={toggleSelectedCategoryGrouping(name)}
                deleteIcon={<Clear />}
                className={classes.categoryChip}
              />
            ))
          }
        />
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <DateFilter
          name={"Loan Date"}
          dateRange={loanDateRange}
          setDateRange={setLoanDateRange}
          defaultOpen={!_.isEmpty(loanDateRange)}
        />
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <DateFilter
          name={"Loan Maturity Date"}
          dateRange={loanMaturityDateRange}
          setDateRange={setLoanMaturityDateRange}
          defaultOpen={!_.isEmpty(loanMaturityDateRange)}
        />
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <DateFilter
          name={"Purchase Date"}
          dateRange={purchaseDateRange}
          setDateRange={setPurchaseDateRange}
          defaultOpen={!_.isEmpty(purchaseDateRange)}
        />
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <form onSubmit={onSubmit} className={classes.searchForm}>
            <TextField
              className={classes.search}
              value={searchText}
              placeholder={expanded ? "Search" : ""}
              onChange={e => setSearchText(e.target.value)}
              inputProps={{ "data-testid": "search-input" }}
              InputLabelProps={{ className: classes.searchLabel }}
              InputProps={{
                className: classes.searchInput,
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                )
              }}
            />
          </form>
          {search && (
            <FormGroup row>
              <Chip
                className={classes.chip}
                label="Clear Search"
                onDelete={clearSearch}
                color="primary"
                data-testid="delete-search"
              />
            </FormGroup>
          )}
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12} className={classes.findButtonWrapper}>
          <FindOrCreateParcelButton expanded={expanded} />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default AcquisitionsParcelsPageFilterSidenav;
