import React from "react";
import _ from "lodash";
import { useQuery } from "@apollo/client";
import { Badge } from "@mui/material";
import { Notifications } from "@mui/icons-material";
import CurrentUserNotificationsCountQuery from "ui/queries/CurrentUserNotificationsCountQuery.graphql";
import useStyles from "./useStyles";

function NotificationsBadge() {
  const classes = useStyles();
  const { data } = useQuery(CurrentUserNotificationsCountQuery, {
    pollInterval: 5000
  });
  return (
    <Badge
      color="secondary"
      badgeContent={_.get(
        data,
        "currentUser.unreadNotifications.totalCount",
        0
      )}
    >
      <Notifications className={classes.itemIcon} />
    </Badge>
  );
}

export default NotificationsBadge;
