import React, { useState, useContext, useRef } from "react";
import PropTypes from "prop-types";
import NotificationsContext from "ui/contexts/NotificationsContext";

import { Grid, Typography, TextField, Button } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import actAndNotify from "ui/lib/actAndNotify";
import ParcelImage from "./ParcelImage";

const useStyles = makeStyles(theme => ({
  parcelImagesOuter: {
    padding: theme.spacing(2, 1)
  },
  parcelImages: {
    borderBottom: "1px dashed #ccc",
    paddingBottom: theme.spacing(2),
    marginBottom: theme.spacing(2),
    height: "415px",
    overflowY: "scroll"
  }
}));

function ParcelImages({ parcel, uploadMutation, deleteMutation }) {
  const classes = useStyles();
  const [description, setDescription] = useState("");
  const { notify } = useContext(NotificationsContext);
  const ref = useRef();

  async function onFileUpload(e) {
    const {
      target: {
        validity,
        files: [file]
      }
    } = e;
    if (validity.valid) {
      await actAndNotify(uploadMutation, "parcelImageUpload", {
        notify,
        mutateOptions: {
          variables: { file, parcelId: parcel.id, description }
        },
        successMessage: "Upload succeeded!"
      })();
      setDescription("");
      ref.current.value = null;
      return;
    }
    notify({ variant: "error", message: "There was an error." });
  }

  let inner;
  if ((parcel?.parcelimageSet?.totalCount || 0) === 0) {
    inner = (
      <Grid item xs={12}>
        <Typography>No Images!</Typography>
      </Grid>
    );
  } else {
    inner = (
      <Grid item xs={12} container className={classes.parcelImages} spacing={2}>
        {parcel.parcelimageSet.edges.map(({ node }) => (
          <ParcelImage
            parcelImage={node}
            parcelId={parcel.id}
            key={node.id}
            deleteMutation={deleteMutation}
          />
        ))}
      </Grid>
    );
  }
  return (
    <Grid container className={classes.parcelImagesOuter}>
      <Grid item xs={12}>
        <Typography variant="h4" gutterBottom align="center">
          Images
        </Typography>
      </Grid>
      {inner}
      <Grid item xs={12} container>
        <Grid item xs={8}>
          <TextField
            label="Description"
            value={description}
            fullWidth
            onChange={e => setDescription(e.target.value)}
          />
        </Grid>
        <Grid item xs={4}>
          <input
            id="parcel-image-upload"
            style={{ display: "none" }}
            type="file"
            disabled={!description}
            onChange={onFileUpload}
            ref={ref}
          />
          <label htmlFor="parcel-image-upload">
            <Button
              variant="contained"
              color="primary"
              component="span"
              disabled={!description}
              fullWidth
            >
              Upload Image
            </Button>
          </label>
        </Grid>
      </Grid>
    </Grid>
  );
}

ParcelImages.propTypes = {
  parcel: PropTypes.object.isRequired,
  uploadMutation: PropTypes.func.isRequired,
  deleteMutation: PropTypes.func.isRequired
};

export default ParcelImages;
