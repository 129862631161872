import React, { useState, useRef } from "react";
import _ from "lodash";

const MapContext = React.createContext(null);

export const withMapContext = Component => props => {
  const [ignoreMapChanges, setIgnoreMapChanges] = useState(false);
  const ignoreMapChangesRef = useRef(ignoreMapChanges);
  const [polygon, setPolygon] = useState(
    _.get(
      props,
      "polygon",
      // filter with the atlanta metro default polygon
      '{"type":"Polygon","coordinates":[[[-84.46662895727158,33.691887845982635],[-84.30937104272843,33.691887845982635],[-84.30937104272843,33.80607414195432],[-84.46662895727158,33.80607414195432],[-84.46662895727158,33.691887845982635]]]}'
    )
  );
  const [zoom, setZoom] = useState(_.get(props, "defaultZoom", 12));
  const [center, setCenter] = useState(
    _.get(props, "defaultCenter", {
      lat: 33.749,
      lng: -84.388
    })
  );
  const [touched, setTouched] = useState(false);

  // map info ref is used to track old values when the map does change
  const mapInfoRef = useRef({
    zoom,
    center,
    polygon
  });

  function onGoogleApiLoaded({ map, maps }) {
    function setPolygonFromBounds(reason) {
      return _.debounce(
        () => {
          const bounds = map.getBounds();
          if (!_.isEmpty(bounds)) {
            const [lats, lngs] = Object.values(bounds);
            const [leftSide, rightSide] = Object.values(lngs);
            const [bottomSide, topSide] = Object.values(lats);
            const newPolygon = JSON.stringify({
              type: "Polygon",
              coordinates: [
                [
                  [leftSide, bottomSide],
                  [rightSide, bottomSide],
                  [rightSide, topSide],
                  [leftSide, topSide],
                  [leftSide, bottomSide]
                ]
              ]
            });
            const newCenter = {
              lat: map.getCenter().lat(),
              lng: map.getCenter().lng()
            };
            const newZoom = map.getZoom();
            setPolygon(newPolygon);
            setZoom(newZoom);
            setCenter(newCenter);
            if (reason !== "api_loaded") {
              setTouched(true);
            }
            if (!ignoreMapChangesRef.current) {
              mapInfoRef.current = {
                zoom: newZoom,
                center: newCenter,
                polygon: newPolygon
              };
            }
          }
        },
        1000,
        { trailing: true }
      );
    }
    map.addListener("dragend", setPolygonFromBounds("dragend"));
    map.addListener("zoom_changed", setPolygonFromBounds("zoom_changed"));
    setPolygonFromBounds("api_loaded")();
  }

  return (
    <MapContext.Provider
      value={{
        polygon,
        setPolygon,
        onGoogleApiLoaded,
        defaultCenter: _.get(props, "defaultCenter", {
          lat: 33.749,
          lng: -84.388
        }),
        defaultZoom: _.get(props, "defaultZoom", 12),
        zoom,
        setZoom,
        setCenter,
        center,
        touched,
        mapInfoRef,
        ignoreMapChanges,
        setIgnoreMapChanges: x => {
          setIgnoreMapChanges(x);
          ignoreMapChangesRef.current = x;
        }
      }}
    >
      <Component {...props} />
    </MapContext.Provider>
  );
};

export default MapContext;
