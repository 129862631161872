import * as Yup from "yup";
import _ from "lodash";

export const fieldsToLabel = {
  askingPrice: "Asking Price",
  approvedPurchasePrice: "Approved Purchase Price",
  budget: "Budget",
  daysUntilSale: "Days Until Sale",
  asIsValue: "As-Is Value",
  salePrice: "Sale Price",
  dispoCosts: "Disposition Cost",
  taxRate: "Tax Rate"
};

export default Yup.object().shape(
  _.fromPairs(
    _.map(_.toPairs(fieldsToLabel), ([field, label]) => [
      field,
      Yup.number().required("Required Field").label(label)
    ])
  )
);
