import React from "react";
import { Box } from "@mui/material";
import useStyles from "./useStyles";

function EmptySidenav() {
  const classes = useStyles();
  return <Box className={classes.sidenav} />;
}

export default EmptySidenav;
