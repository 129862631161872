import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { useApolloClient } from "@apollo/client";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Tooltip
} from "@mui/material";
import {
  AccountBalance,
  AssignmentReturned,
  Home,
  History,
  ExitToApp,
  Work,
  Category,
  Menu,
  Group,
  LocalLibrary
} from "@mui/icons-material";
import NotificationsBadge from "./NotificationsBadge";
import useStyles from "./useStyles";

const baseLinks = [
  { Icon: Home, path: "/", text: "Home" },
  { Icon: NotificationsBadge, path: "/notifications", text: "Notifications" },
  { Icon: AssignmentReturned, path: "/exports", text: "Exports" },
  { Icon: History, path: "/activity", text: "My Activity" },
  { Icon: Work, path: "/portfolios", text: "Portfolios" },
  { Icon: Category, path: "/categories", text: "Categories" },
  { Icon: AccountBalance, path: "/lenders", text: "Lenders" },
  { Icon: Group, path: "/owners", text: "Owners" },
  { Icon: LocalLibrary, path: "/map", text: "Demographics Map" }
];

function GlobalSidenav({
  links,
  showLogout,
  expanded = false,
  setExpanded = () => {}
}) {
  const navigate = useNavigate();
  const location = useLocation();
  const classes = useStyles();
  const client = useApolloClient();
  function logout() {
    localStorage.removeItem("token");
    client.cache.reset();
  }
  return (
    <Box style={{ display: "flex" }}>
      <Paper elevation={3} className={classes.sidenav} square>
        <List>
          <Tooltip title={expanded ? "Minimize" : "Expand"} placement="right">
            <ListItem button onClick={() => setExpanded(!expanded)}>
              <ListItemIcon>
                <Menu className={classes.itemIcon} />
              </ListItemIcon>
              {expanded && (
                <ListItemText primary="Minimize" className={classes.itemText} />
              )}
            </ListItem>
          </Tooltip>
          {baseLinks.concat(links).map(({ Icon, path, text }) => (
            <Tooltip title={text} placement="right" key={text}>
              <ListItem
                button
                selected={path === location.pathname}
                onClick={() => navigate(path)}
                data-testid={`${_.kebabCase(text)}-item`}
              >
                <ListItemIcon>
                  <Icon
                    className={classes.itemIcon}
                    data-testid={`${_.kebabCase(text)}-icon`}
                  />
                </ListItemIcon>
                {expanded && (
                  <ListItemText
                    primary={text}
                    className={classes.itemText}
                    data-testid={`${_.kebabCase(text)}-text`}
                  />
                )}
              </ListItem>
            </Tooltip>
          ))}
          {showLogout && (
            <Tooltip title="Log Out" placement="right">
              <ListItem button onClick={logout} key={"logout"}>
                <ListItemIcon>
                  <ExitToApp className={classes.itemIcon} />
                </ListItemIcon>
                {expanded && (
                  <ListItemText
                    primary="Log Out"
                    className={classes.itemText}
                  />
                )}
              </ListItem>
            </Tooltip>
          )}
        </List>
      </Paper>
    </Box>
  );
}

GlobalSidenav.propTypes = {
  links: PropTypes.arrayOf(PropTypes.object),
  onLogout: PropTypes.func,
  showLogout: PropTypes.bool
};

GlobalSidenav.defaultProps = {
  links: [],
  showLogout: false
};

export default GlobalSidenav;
