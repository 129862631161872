import _ from "lodash";
import { availableDemographics } from "ui/lib/demographics";

export default function showDemographics(
  tract,
  surveyData,
  infoWindow,
  map,
  setTract
) {
  return event => {
    // Since this polygon has only one path, we can call getPath() to return the
    // MVCArray of LatLngs.
    // @ts-ignore
    let medianIncome = _.get(surveyData, "S1901_C01_012E", "Unavailable");

    if (medianIncome !== "Unavailable") {
      medianIncome = new Intl.NumberFormat("en-US", {
        style: "currency",
        precision: 0,
        currency: "USD",
        useGrouping: true,
        currencyDisplay: "narrowSymbol",
        maximumFractionDigits: 0
      }).format(medianIncome);
    }

    if (medianIncome === "-$666,666,666") {
      // not sure why the census data does this
      medianIncome = "Unavailable";
    }

    const title = tract.name;

    let contentString =
      "<b>Demographics</b><br>" +
      `Census Tract: ${title} <br>` +
      `Median Income: ${medianIncome}<br>` +
      _.filter(
        _.map(availableDemographics, ({ title }, slug) => {
          let thisTitle = title;
          if (title === "Median Income") {
            return "";
          }
          if (title === "Total" && slug.startsWith("P8")) {
            return "";
          }
          return `${thisTitle}: ${surveyData[slug] || 0}`;
        }),
        x => x
      ).join("\n<br>");

    contentString += `<br><a id="view-tract-${tract.id}" href="#">See More</a>`;

    // Replace the info window's content and position.
    infoWindow.setContent(contentString);
    infoWindow.setPosition(event.latLng);
    infoWindow.setZIndex(100000);
    infoWindow.open(map);
    setTimeout(() => {
      document.getElementById(`view-tract-${tract.id}`).onclick = e => {
        e.preventDefault();
        setTract(tract);
      };
    }, 100);
  };
}
