import React, { useContext } from "react";
import PropTypes from "prop-types";
import SiteContext from "ui/contexts/SiteContext";
import NotificationExport from "./NotificationExport";
import NotificationParcel from "./NotificationParcel";
import NotificationParcelNote from "./NotificationParcelNote";
import NotificationParcelDocument from "./NotificationParcelDocument";
import NotificationParcelImage from "./NotificationParcelImage";
import NotificationLead from "./NotificationLead";
import NotificationPurchaseValue from "./NotificationPurchaseValue";
import NotificationUnderwritingRequest from "./NotificationUnderwritingRequest";
import NotificationCoolingLead from "./NotificationCoolingLead";

const transitiveComponents = {
  Parcel: NotificationParcel,
  ParcelNote: NotificationParcelNote,
  ParcelDocument: NotificationParcelDocument,
  ParcelImage: NotificationParcelImage,
  Lead: NotificationLead,
  UnderwritingRequest: NotificationUnderwritingRequest,
  PurchaseValue: NotificationPurchaseValue
};

function Notification({ notification }) {
  const { site } = useContext(SiteContext);

  let Component;

  if (notification.actor.__typename === "Export") {
    Component = NotificationExport;
  } else {
    Component = notification.actionObject
      ? transitiveComponents[notification.actionObject.__typename]
      : // deferring supporting multiple actor types for now
        NotificationCoolingLead;
  }

  return <Component notification={notification} site={site} />;
}

Notification.propTypes = {
  notification: PropTypes.object
};

export default Notification;
