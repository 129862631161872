import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { Grid } from "@mui/material";
import { useQuery, useMutation } from "@apollo/client";
import useCursors from "ui/hooks/useCursors";
import usePagination from "ui/hooks/usePagination";
import actAndNotify from "ui/lib/actAndNotify";
import NotificationsContext from "ui/contexts/NotificationsContext";
import { withMapContext } from "ui/contexts/MapContext";
import Loader from "ui/components/Loader";
import ParcelInfoTableInner from "ui/components/ParcelInfoTable/ParcelInfoTableInner";
import ComparableParcelsMap from "ui/sites/underwriting/components/ComparableParcelsMap";
import ComparableParcelAction from "ui/sites/underwriting/components/ComparableParcelAction";
import ComparableParcelAddDialog from "ui/sites/underwriting/components/ComparableParcelAddDialog";
import CurrentUnderwriterComparableParcelsQuery from "ui/sites/underwriting/queries/CurrentUnderwriterComparableParcelsQuery.graphql";
import CurrentUnderwriterParcelDetailsQuery from "ui/sites/underwriting/queries/CurrentUnderwriterParcelDetailsQuery.graphql";
import ComparableParcelAddMutation from "ui/sites/underwriting/queries/ComparableParcelAddMutation.graphql";

function UnderwritingComparablesContainer({ parcel }) {
  const cursors = useCursors();
  const { notify } = useContext(NotificationsContext);
  const [selectedComparableParcel, setSelectedComparableParcel] =
    useState(null);
  const comparableParcelsQueryVariables = {
    parcelId: parcel.id,
    ..._.pick(cursors, ["first", "after"])
  };
  const { data, loading } = useQuery(CurrentUnderwriterComparableParcelsQuery, {
    variables: comparableParcelsQueryVariables
  });
  const [mutate] = useMutation(ComparableParcelAddMutation, {
    refetchQueries: [
      {
        query: CurrentUnderwriterParcelDetailsQuery,
        variables: { parcelId: parcel.id }
      },
      {
        query: CurrentUnderwriterComparableParcelsQuery,
        variables: comparableParcelsQueryVariables
      }
    ]
  });
  const { paginationProps } = usePagination({
    nextAfter: _.get(
      data,
      "currentUnderwriter.parcel.comparableParcels.pageInfo.endCursor"
    ),
    count: _.get(
      data,
      "currentUnderwriter.parcel.comparableParcels.totalCount"
    ),
    ...cursors
  });
  if (loading) {
    return <Loader />;
  }
  const comparableParcels = _.map(
    _.get(data, "currentUnderwriter.parcel.comparableParcels.edges"),
    "node"
  );

  const ConnectedComparableParcelAction = ({ parcel: innerParcel }) => (
    <ComparableParcelAction
      underwritingRequest={parcel.currentRequest}
      onClick={() => setSelectedComparableParcel(innerParcel)}
      parcel={innerParcel}
    />
  );

  async function addComparableParcel(compType) {
    await actAndNotify(mutate, "comparableParcelAdd", {
      notify,
      mutateOptions: {
        variables: {
          compType,
          underwritingRequestId: parcel.currentRequest.id,
          parcelId: selectedComparableParcel.id
        }
      },
      successMessage: "Successfully added comparable parcel"
    })();
  }

  return (
    <Grid container>
      <ComparableParcelAddDialog
        open={selectedComparableParcel !== null}
        onClose={() => setSelectedComparableParcel(null)}
        perform={addComparableParcel}
      />
      <Grid item xs={3}>
        <ComparableParcelsMap
          mainParcel={parcel}
          comparableParcels={comparableParcels}
        />
      </Grid>
      <Grid item xs={9}>
        <ParcelInfoTableInner
          parcels={comparableParcels}
          paginationProps={paginationProps}
          openInNewTab
          Action={ConnectedComparableParcelAction}
        />
      </Grid>
    </Grid>
  );
}

UnderwritingComparablesContainer.propTypes = {
  parcel: PropTypes.object.isRequired
};

export default withMapContext(UnderwritingComparablesContainer);
