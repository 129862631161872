import React, { useContext } from "react";
import _ from "lodash";
import { Button, Chip } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { AS_IS, RENTAL, LISTED } from "ui/themes/AlineaTheme/colors";
import CurrentUnderwriterContext from "ui/sites/underwriting/contexts/CurrentUnderwriterContext";

const useStyles = makeStyles(() => ({
  AS_IS: {
    width: "100%",
    backgroundColor: AS_IS
  },
  RENTAL: {
    width: "100%",
    backgroundColor: RENTAL
  },
  LISTED: {
    width: "100%",
    backgroundColor: LISTED
  },
  actionButton: {
    width: 150
  }
}));

function ComparableParcelAction({ underwritingRequest, parcel, onClick }) {
  const classes = useStyles();
  const { currentUnderwriter } = useContext(CurrentUnderwriterContext);

  const compType = _.get(parcel, "comparableparcelSet.edges.0.node.compType");

  if (compType) {
    return <Chip label={compType} className={classes[compType]} />;
  }

  if (
    underwritingRequest &&
    underwritingRequest?.assignedUnderwriter?.id === currentUnderwriter.id
  ) {
    return (
      <Button
        onClick={onClick}
        variant="contained"
        size="small"
        color="primary"
        className={classes.actionButton}
      >
        Add Comparable
      </Button>
    );
  }
  return null;
}

export default ComparableParcelAction;
