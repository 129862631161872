import React, { useContext } from "react";
import { useMutation } from "@apollo/client";
import CurrentUserActivityQuery from "ui/queries/CurrentUserActivityQuery.graphql";
import UnderwritingRequestClaimMutation from "ui/sites/underwriting/queries/UnderwritingRequestClaimMutation.graphql";
import UnderwritingRequestDeleteMutation from "ui/sites/underwriting/queries/UnderwritingRequestDeleteMutation.graphql";
import CurrentUnderwriterParcelActivityQuery from "ui/sites/underwriting/queries/CurrentUnderwriterParcelActivityQuery.graphql";
import CurrentUnderwriterParcelDetailsQuery from "ui/sites/underwriting/queries/CurrentUnderwriterParcelDetailsQuery.graphql";
import CurrentUnderwriterParcelDocumentsQuery from "ui/sites/underwriting/queries/CurrentUnderwriterParcelDocumentsQuery.graphql";
import CurrentUnderwriterParcelImagesQuery from "ui/sites/underwriting/queries/CurrentUnderwriterParcelImagesQuery.graphql";
import CurrentUnderwriterParcelNotesQuery from "ui/sites/underwriting/queries/CurrentUnderwriterParcelNotesQuery.graphql";
import ParcelNoteAddMutation from "ui/queries/ParcelNoteAddMutation.graphql";
import ParcelImageUploadMutation from "ui/queries/ParcelImageUploadMutation.graphql";
import ParcelImageDeleteMutation from "ui/queries/ParcelImageDeleteMutation.graphql";
import MyUnderwritingRequestsQuery from "ui/sites/underwriting/queries/MyUnderwritingRequestsQuery.graphql";
import OpenUnderwritingRequestsQuery from "ui/sites/underwriting/queries/OpenUnderwritingRequestsQuery.graphql";
import ParcelDocumentUploadMutation from "ui/queries/ParcelDocumentUploadMutation.graphql";
import ParcelDocumentDeleteMutation from "ui/queries/ParcelDocumentDeleteMutation.graphql";

const UnderwritingActionsContext = React.createContext(null);

export function useUnderwritingActions(parcelId) {
  const ctx = useContext(UnderwritingActionsContext);
  const refetchQueries = ctx.getRefetchQueries(parcelId);
  const [uploadParcelDocument] = useMutation(ParcelDocumentUploadMutation, {
    refetchQueries: [
      ...refetchQueries,
      { query: CurrentUnderwriterParcelDocumentsQuery, variables: { parcelId } }
    ]
  });
  const [deleteParcelDocument] = useMutation(ParcelDocumentDeleteMutation, {
    refetchQueries: [
      ...refetchQueries,
      { query: CurrentUnderwriterParcelDocumentsQuery, variables: { parcelId } }
    ]
  });
  const [uploadParcelImage] = useMutation(ParcelImageUploadMutation, {
    refetchQueries: [
      ...refetchQueries,
      { query: CurrentUnderwriterParcelImagesQuery, variables: { parcelId } }
    ]
  });
  const [deleteParcelImage] = useMutation(ParcelImageDeleteMutation, {
    refetchQueries: [
      ...refetchQueries,
      { query: CurrentUnderwriterParcelImagesQuery, variables: { parcelId } }
    ]
  });
  const [submitNote] = useMutation(ParcelNoteAddMutation, {
    refetchQueries: [
      ...refetchQueries,
      { query: CurrentUnderwriterParcelNotesQuery, variables: { parcelId } }
    ]
  });
  return {
    ...ctx,
    refetchQueries,
    uploadParcelDocument,
    deleteParcelDocument,
    uploadParcelImage,
    deleteParcelImage,
    submitNote
  };
}

export function withUnderwritingActions(Component) {
  return function (props) {
    const [claimRequest] = useMutation(UnderwritingRequestClaimMutation);
    const [deleteRequest] = useMutation(UnderwritingRequestDeleteMutation);
    const getRefetchQueries = parcelId => [
      { query: CurrentUnderwriterParcelDetailsQuery, variables: { parcelId } },
      { query: CurrentUnderwriterParcelActivityQuery, variables: { parcelId } },
      { query: MyUnderwritingRequestsQuery },
      { query: OpenUnderwritingRequestsQuery },
      { query: CurrentUserActivityQuery }
    ];
    return (
      <UnderwritingActionsContext.Provider
        value={{
          claimRequest,
          getRefetchQueries,
          deleteRequest
        }}
      >
        <Component {...props} />
      </UnderwritingActionsContext.Provider>
    );
  };
}

export default UnderwritingActionsContext;
