import React, { useState } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { useQuery } from "@apollo/client";
import { Grid, Typography } from "@mui/material";
import CurrentUnderwriterParcelActivityQuery from "ui/sites/underwriting/queries/CurrentUnderwriterParcelActivityQuery.graphql";
import Auditlog from "ui/components/Auditlog";

function UnderwritingParcelAuditlogContainer({ parcelId }) {
  const [first, setFirst] = useState(25);
  const [after, setAfter] = useState(null);
  const [lastCursor, setLastCursor] = useState([]);
  const [page, setPage] = useState(0);

  const onRowsPerPageChange = event => {
    setFirst(parseInt(event.target.value, 10));
    setPage(0);
  };

  const onPageChange = (e, newPage) => {
    if (newPage === 0) {
      setLastCursor([]);
      setAfter(null);
    } else if (newPage < page) {
      setAfter(lastCursor.pop());
      setLastCursor(lastCursor);
    } else if (newPage > page) {
      const nextAfter = _.get(
        data,
        "currentSalesperson.parcel.activity.pageInfo.endCursor"
      );
      setLastCursor(lastCursor + [after]);
      setAfter(nextAfter);
    }
    setPage(newPage);
  };
  const variables = { first, after, parcelId };
  const { data, loading } = useQuery(CurrentUnderwriterParcelActivityQuery, {
    variables
  });
  const count = _.get(data, "currentUnderwriter.parcel.activity.totalCount");

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h3" align="center">
          Auditlog
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Auditlog
          logEntries={_.map(
            _.get(data, "currentUnderwriter.parcel.activity.edges"),
            "node"
          )}
          loading={loading}
          paginationProps={{
            page,
            rowsPerPage: first,
            rowsPerPageOptions: [5, 10, 25, 50, 100],
            onPageChange,
            onRowsPerPageChange,
            count
          }}
        />
      </Grid>
    </Grid>
  );
}

UnderwritingParcelAuditlogContainer.propTypes = {
  parcelId: PropTypes.string.isRequired
};

export default UnderwritingParcelAuditlogContainer;
