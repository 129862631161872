import _ from "lodash";

export function createOverlay(map) {
  return kmlUrl => {
    if ((!_.has(window.mapOverlays), kmlUrl)) {
      // eslint-disable-next-line
      window.mapOverlays[kmlUrl] = new google.maps.KmlLayer(kmlUrl, {
        suppressInfoWindows: false,
        preserveViewport: true,
        map: map
      });
    } else if (window.mapOverlays[kmlUrl].getMap() === null) {
      window.mapOverlays[kmlUrl].setMap(map);
    }
  };
}

export function clearOverlays(map, mapOverlays) {
  _.map(_.keys(window.mapOverlays), kmlUrl => {
    if (!_.includes(mapOverlays, kmlUrl)) {
      window.mapOverlays[kmlUrl].setMap(null);
    }
  });
}
