import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { useParams } from "react-router-dom";
import { Grid, Typography, Paper } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useQuery, useMutation } from "@apollo/client";
import actAndNotify from "ui/lib/actAndNotify";
import useCursors from "ui/hooks/useCursors";
import usePagination from "ui/hooks/usePagination";
import { withMapContext } from "ui/contexts/MapContext";
import { withMapLayerContext } from "ui/contexts/MapLayerContext";
import { SiteWrapper } from "ui/contexts/SiteContext";
import NotificationsContext from "ui/contexts/NotificationsContext";
import { withNotifications } from "ui/contexts/NotificationsContext";
import { SidenavWrapper } from "ui/contexts/SidenavContext";
import CurrentUserPortfolioParcelsQuery from "ui/queries/CurrentUserPortfolioParcelsQuery.graphql";
import ParcelExportFromPortfolioMutation from "ui/sites/acquisitions/queries/ParcelExportFromPortfolioMutation.graphql";
import ParcelExportByIdMutation from "ui/queries/ParcelExportByIdMutation.graphql";
import DynamicSidenav from "ui/components/DynamicSidenav";
import ParcelInfoTableInner from "ui/components/ParcelInfoTable/ParcelInfoTableInner";
import PortfoliosPage from "ui/pages/PortfoliosPage";
import ParcelRemoveFromPortfolioAction from "./ParcelRemoveFromPortfolioAction";
import MapLayers from "ui/components/MapLayers";
import PortfolioMap from "./PortfolioMap";
import MapOverlaySelector from "ui/components/MapOverlaySelector";
import Loader from "ui/components/Loader";

const useStyles = makeStyles(theme => ({
  container: {
    height: "100%"
  }
}));

function PortfolioPage({ site }) {
  const classes = useStyles();
  const { portfolioId } = useParams();
  const cursors = useCursors();
  const { notify } = useContext(NotificationsContext);
  const [selectedParcel, setSelectedParcel] = useState(null);
  const variables = { portfolioId, ..._.pick(cursors, ["first", "after"]) };
  const { data, loading } = useQuery(CurrentUserPortfolioParcelsQuery, {
    variables
  });
  const [exportById] = useMutation(ParcelExportByIdMutation);
  const [exportAll] = useMutation(ParcelExportFromPortfolioMutation, {
    variables: { portfolioId }
  });
  const count = _.get(data, `currentUser.portfolio.parcels.totalCount`, 0);

  const { paginationProps } = usePagination({
    nextAfter: _.get(data, `currentUser.portfolio.parcels.pageInfo.endCursor`),
    count,
    ...cursors
  });

  const parcels = _.map(
    _.get(data, "currentUser.portfolio.parcels.edges"),
    ({ node }) => node
  );

  function handleRequestCsvUpload(parcelIds) {
    const mutateOptions = {};
    if (!_.isEmpty(parcelIds)) {
      mutateOptions.variables = { parcelIds };
    }
    const mutate = _.isEmpty(parcelIds) ? exportAll : exportById;
    const slug = _.isEmpty(parcelIds)
      ? "parcelExportFromPortfolio"
      : "parcelExportById";
    return actAndNotify(mutate, slug, {
      notify,
      mutateOptions,
      successMessage:
        "Requested CSV export. You will be notified when it's ready."
    });
  }

  useEffect(() => {
    // initialize portal demographics and map overlays
    window.portalDemographics = {};
    window.mapOverlays = {};
  });

  const Action = ({ parcel }) => (
    <ParcelRemoveFromPortfolioAction
      portfolioId={portfolioId}
      parcelId={parcel.id}
    />
  );

  return (
    <Grid
      container
      className={classes.container}
      data-testid="notifications-page"
    >
      <SiteWrapper>
        <SidenavWrapper
          Component={props => (
            <DynamicSidenav
              showLogout={_.get(data, "currentUser", null) !== null}
              {...props}
            />
          )}
        >
          <Grid item xs container>
            <Grid item xs={12}>
              <Typography variant="h4" align="center">
                {_.get(data, "currentUser.portfolio.name")}
              </Typography>
            </Grid>
            {loading && <Loader />}
            {!loading && (
              <>
                <Grid item xs={12}>
                  <MapOverlaySelector hideIgnoreMapChanges />
                </Grid>
                <Grid item xs={12}>
                  <Paper style={{ height: "50%" }}>
                    <MapLayers>
                      <PortfolioMap
                        parcels={parcels}
                        loading={loading}
                        selectedParcel={selectedParcel}
                        setSelectedParcel={setSelectedParcel}
                      />
                    </MapLayers>
                  </Paper>
                </Grid>
                <Grid item xs={12}>
                  <ParcelInfoTableInner
                    parcels={parcels}
                    loading={loading}
                    paginationProps={paginationProps}
                    Action={Action}
                    handleRequestCsvUpload={handleRequestCsvUpload}
                    withExportSelection
                  />
                </Grid>
              </>
            )}
          </Grid>
        </SidenavWrapper>
      </SiteWrapper>
    </Grid>
  );
}

PortfoliosPage.propTypes = {
  site: PropTypes.string
};

export default withNotifications(
  withMapContext(withMapLayerContext(PortfolioPage))
);
