import React from "react";
import _ from "lodash";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableContainer,
  TableRow,
  Typography
} from "@mui/material";
import {
  VictoryChart,
  VictoryBar,
  VictoryTooltip,
  VictoryAxis,
  VictoryGroup
} from "victory";
import VictoryTheme from "ui/themes/VictoryTheme";
import legend from "./surveyVariables/S2504.json";

const tables = [
  "YEAR STRUCTURE BUILT",
  "HOUSE HEATING FUEL",
  "ROOMS",
  "VEHICLES AVAILABLE",
  "UNITS IN STRUCTURE",
  "COMPLETE FACILITIES",
  "BEDROOMS"
];

const segments = [
  // { key: "Occupied housing units", title: "Total" },
  { key: "Owner-occupied housing units", title: "Owner-occupied" },
  { key: "Renter-occupied housing units", title: "Renter-occupied" }
];

const prefix = "Estimate!!SEGMENT!!Occupied housing units!!";

function OccupationNumbers({ tract }) {
  const tractData = JSON.parse(tract.acsS2504);

  const allFields = _.sortBy(
    _.map(_.keys(legend.variables), key => ({
      ...legend.variables[key],
      key
    })),
    "label"
  );

  const eligibleFields = _.sortBy(
    _.filter(allFields, ({ label }) => label.match(/^Estimate!!/)),
    "label"
  );

  return (
    <div>
      <div>
        {_.map(tables, table => (
          <div key={table}>
            <div>
              <Typography variant="h6">{table}</Typography>
            </div>
            <div>
              <VictoryChart
                theme={VictoryTheme}
                padding={{ left: 100 }}
                domainPadding={{ x: 20, y: 10 }}
              >
                <VictoryAxis
                  style={{
                    tickLabels: { fontSize: 8 }
                  }}
                  tickFormat={tick => {
                    const splt = tick.split(" ");
                    if (splt.length > 4) {
                      return (
                        splt.slice(0, 3).join(" ") +
                        "\n" +
                        splt.slice(3).join(" ")
                      );
                    }
                    return tick;
                  }}
                />
                <VictoryGroup offset={60} style={{ data: {} }}>
                  {_.map(segments, ({ key: segmentKey, title }) => (
                    <VictoryBar
                      key={segmentKey}
                      data={_.sortBy(
                        _.map(
                          _.filter(eligibleFields, ({ label }) =>
                            _.includes(
                              label,
                              `${prefix.replace("SEGMENT", segmentKey)}${table}`
                            )
                          ),
                          ({ label, key }) => ({
                            x: `${_.last(label.split("!!"))}`,
                            y: parseInt(tractData[key])
                          })
                        ),
                        "y"
                      )}
                      labels={({ datum }) => `${datum.y}\n${title}\n${datum.x}`}
                      labelComponent={
                        <VictoryTooltip
                          pointerOrientation="bottom"
                          centerOffset={{ x: -100 }}
                        />
                      }
                      horizontal
                      style={{
                        labels: {
                          fontSize: 8
                        },
                        tickLabels: {
                          fontSize: 8
                        }
                      }}
                    />
                  ))}
                </VictoryGroup>
              </VictoryChart>
            </div>
          </div>
        ))}
      </div>
      <div>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell colSpan={2}>Housing Characteristics</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {_.map(
                eligibleFields,
                ({ key, label }) =>
                  !_.isEmpty(tractData[key]) && (
                    <TableRow key={key}>
                      <TableCell>
                        <b>{label.replace("Estimate!!", "")}</b>
                      </TableCell>
                      <TableCell>{tractData[key]}</TableCell>
                    </TableRow>
                  )
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
}

export default OccupationNumbers;
