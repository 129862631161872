import React from "react";
import PropTypes from "prop-types";
import { useQuery, useMutation } from "@apollo/client";
import { Grid, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Loader from "ui/components/Loader";
import { loginRequired } from "ui/providers/CurrentUserProvider";
import CurrentSalespersonQuery from "ui/sites/acquisitions/queries/CurrentSalespersonQuery.graphql";
import CurrentSalespersonContext from "ui/sites/acquisitions/contexts/CurrentSalespersonContext";
import SalespersonInviteMutation from "ui/sites/acquisitions/queries/SalespersonInviteMutation.graphql";

const useStyles = makeStyles(theme => ({
  loginForm: {
    marginTop: theme.spacing(10)
  }
}));

function CurrentSalespersonProvider({ children }) {
  const classes = useStyles();
  const { data, loading } = useQuery(CurrentSalespersonQuery);
  const [salespersonInvite] = useMutation(SalespersonInviteMutation, {
    refetchQueries: [{ query: CurrentSalespersonQuery }]
  });

  if (loading) {
    return (
      <Grid item xs={12}>
        <Loader />
      </Grid>
    );
  }

  if (data?.currentSalesperson === null) {
    return (
      <Grid item xs={12} className={classes.loginForm}>
        <Typography align="center" variant="h4" data-testid="access-denied">
          Access Denied
        </Typography>
      </Grid>
    );
  }

  return (
    <CurrentSalespersonContext.Provider
      value={{
        currentSalesperson: data?.currentSalesperson,
        salespersonInvite
      }}
    >
      {children}
    </CurrentSalespersonContext.Provider>
  );
}

CurrentSalespersonProvider.propTypes = {
  children: PropTypes.node
};

export default loginRequired(CurrentSalespersonProvider);
