import React from "react";
import PropTypes from "prop-types";
import { DateTime } from "luxon";
import { Badge, TableRow, TableCell } from "@mui/material";
import useStyles from "./useStyles";
import Notification from "./Notification";

function NotificationRow({ notification, markAsRead }) {
  const classes = useStyles();
  return (
    <TableRow
      className={classes.notificationRow}
      data-testid={`notification-${notification.id}`}
      onClick={markAsRead(notification.id)}
    >
      <TableCell className={classes.notificationDot}>
        {notification.unread && <Badge color="secondary" variant="dot" />}
      </TableCell>
      <Notification notification={notification} />
      <TableCell align="right">
        {DateTime.fromISO(notification.timestamp).toRelative()}
      </TableCell>
    </TableRow>
  );
}

NotificationRow.propTypes = {
  notification: PropTypes.object,
  markAsRead: PropTypes.func
};

export default NotificationRow;
