import React from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Link
} from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles(() => ({
  table: { width: "100%" }
}));

function AcquisitionsInboundLeadsTable({
  inboundLeads,
  paginationProps = null
}) {
  const classes = useStyles();
  return (
    <TableContainer component={Paper}>
      <Table
        className={classes.table}
        size="small"
        data-testid="inbound-leads-table"
      >
        <TableHead>
          <TableRow>
            <TableCell>Address</TableCell>
            <TableCell>City</TableCell>
            <TableCell>State</TableCell>
            <TableCell>Zip</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {_.map(inboundLeads, inboundLead => (
            <TableRow key={inboundLead.id}>
              <TableCell>
                <Link
                  component={RouterLink}
                  to={`/acquisitions/parcel/${inboundLead.parcel.id}/inbound-lead`}
                  underline="hover"
                >
                  {inboundLead.parcel.addressFull}
                </Link>
              </TableCell>
              <TableCell>{inboundLead.parcel.city}</TableCell>
              <TableCell>{inboundLead.parcel.state}</TableCell>
              <TableCell>{inboundLead.parcel.zipcode}</TableCell>
            </TableRow>
          ))}
          {paginationProps && (
            <TableRow>
              <TablePagination {...paginationProps} />
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

AcquisitionsInboundLeadsTable.propTypes = {
  inboundLeads: PropTypes.arrayOf(PropTypes.object),
  paginationProps: PropTypes.object
};

AcquisitionsInboundLeadsTable.defaultProps = {
  inboundLeads: []
};

export default AcquisitionsInboundLeadsTable;
