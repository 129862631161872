import React, { useContext, useState } from "react";
import { useSearchParams } from "react-router-dom";
import ParcelCursorContext from "ui/sites/acquisitions/contexts/ParcelCursorContext";

const MapLayerContext = React.createContext({
  demographics: window.portalDemographics || {}
});

export const withMapLayerContext = Component => props => {
  const parcelCursorContext = useContext(ParcelCursorContext);
  const [onGoogleApiLoaded, setOnGoogleApiLoaded] = useState(null);
  const demographics = window.portalDemographics || {};
  const setDemographics = x => {
    window.portalDemographics = x;
  };
  const [showCensusTracts, setShowCensusTracts] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  // searchParams.get("demo") causes a race condition that makes layers break
  const [demographicSelection, setDemographicSelection] = useState("");
  const [mapOverlays, setMapOverlays] = useState([
    ...(parcelCursorContext.mapOverlays || [])
  ]);
  return (
    <MapLayerContext.Provider
      value={{
        onGoogleApiLoaded,
        setOnGoogleApiLoaded,
        demographics,
        setDemographics,
        mapOverlays,
        setMapOverlays,
        showCensusTracts,
        setShowCensusTracts,
        searchParams,
        setSearchParams,
        demographicSelection,
        setDemographicSelection: x => {
          setDemographicSelection(x);
          setSearchParams({ ...searchParams, demo: x });
        }
      }}
    >
      <Component {...props} />
    </MapLayerContext.Provider>
  );
};

export default MapLayerContext;
