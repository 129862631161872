export const greyText = "rgba(230, 230, 230, 0.7);";
export const headerText = "rgba(230, 230, 230, 0.9);";
export const navBackground = "#333";
export const hot = "rgba(230, 30, 30, 0.8)";
export const warm = "rgba(200, 50, 50, 0.6)";
export const checkBack30 = "rgba(175, 70, 70, 0.4)";
export const checkBackYear = "rgba(130, 130, 30, 0.4)";
export const cold = "rgba(30, 30, 230, 0.6)";
export const AS_IS = "rgba(200, 175, 175, 0.5)";
export const RENTAL = "rgba(175, 100, 175, 0.5)";
export const LISTED = "rgba(175, 175, 200, 0.5)";
const colors = {
  greyText,
  navBackground,
  headerText,
  hot,
  cold,
  checkBack30,
  checkBackYear,
  warm
};
export default colors;
