import { useEffect } from "react";
import * as geolib from "geolib";
import _ from "lodash";

/**
 * First argument is the map context
 * Second argument is a list of any object with
 *     latitude and longitude fields nested into coordinates (e.g. parcel).
 */
const useMapCentering = (
  { setCenter, center, ignoreMapChanges = false },
  parcels
) => {
  useEffect(() => {
    if (parcels && parcels.length > 0) {
      const { latitude, longitude } = geolib.getCenter(
        _.map(
          parcels,
          ({
            coordinates: {
              coordinates: [longitude, latitude]
            }
          }) => ({
            latitude,
            longitude
          })
        )
      );
      const newCenter = { lat: latitude, lng: longitude };
      const centersUnequal =
        JSON.stringify(newCenter) !== JSON.stringify(center);
      if (centersUnequal && !ignoreMapChanges) {
        setCenter(newCenter);
      }
    }
    // we only want to call this on mount.
    // eslint complains about not mentioning the "dependencies"
    // here, but this is exactly the behavior we desire.
    // adding the dependencies will cause it to run any time one of them changes.
    /* eslint-disable*/
  }, []);
};

export default useMapCentering;
