import React from "react";
import PropTypes from "prop-types";
import ParcelInfoTableInner from "./ParcelInfoTableInner";

function ParcelInfoTable({ parcel }) {
  return <ParcelInfoTableInner parcels={[parcel]} />;
}

ParcelInfoTable.propTypes = {
  parcel: PropTypes.object.isRequired
};

export default ParcelInfoTable;
