import { useState } from "react";
import last from "lodash/last";
import parseInt from "lodash/parseInt";
import { getIntegerFromLocalStorage } from "ui/lib/localStorage";

function usePagination({
  first,
  setFirst,
  after,
  setAfter,
  lastCursor,
  setLastCursor,
  nextAfter,
  count,
  localStorageSlug = null
}) {
  const [page, setPage] = useState(
    localStorageSlug === null
      ? 0
      : getIntegerFromLocalStorage(`${localStorageSlug}Page`)
  );

  const updatePage = val => {
    if (localStorageSlug !== null) {
      localStorage.setItem(`${localStorageSlug}Page`, val);
    }
    setPage(val);
  };

  const onRowsPerPageChange = event => {
    setFirst(parseInt(event.target.value, 10));
    updatePage(0);
  };

  const onPageChange = (e, newPage) => {
    if (newPage === 0) {
      setLastCursor([]);
      setAfter(null);
    } else if (newPage < page) {
      lastCursor.pop();
      setAfter(last(lastCursor));
      setLastCursor(lastCursor);
    } else if (newPage > page) {
      setLastCursor(lastCursor.concat([nextAfter]));
      setAfter(nextAfter);
    }
    updatePage(newPage);
  };

  return {
    first,
    setFirst,
    after,
    setAfter,
    lastCursor,
    setLastCursor,
    page,
    setPage: updatePage,
    onRowsPerPageChange,
    onPageChange,
    paginationProps: {
      page,
      rowsPerPage: first,
      rowsPerPageOptions: [5, 10, 25, 50, 100],
      onPageChange,
      onRowsPerPageChange,
      count
    }
  };
}

export default usePagination;
