import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { Link as RouterLink } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";
import {
  Paper,
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableContainer,
  TableRow,
  TableFooter,
  TablePagination,
  LinearProgress,
  Link
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Cancel } from "@mui/icons-material";
import actAndNotify from "ui/lib/actAndNotify";
import NotificationsContext from "ui/contexts/NotificationsContext";
import useCursors from "ui/hooks/useCursors";
import usePagination from "ui/hooks/usePagination";
import CurrentUserAllCategoriesQuery from "ui/queries/CurrentUserAllCategoriesQuery.graphql";
import CurrentUserMyPotfoliosQuery from "ui/queries/CurrentUserMyCategoriesQuery.graphql";
import CategoryCreateMutation from "ui/queries/CategoryCreateMutation.graphql";
import CategoryDeleteMutation from "ui/queries/CategoryDeleteMutation.graphql";
import CreateCategoryDialog from "./CreateCategoryDialog";

const useStyles = makeStyles(() => ({
  table: { width: "100%" }
}));

function CategoriesList({ query, accessor }) {
  const classes = useStyles();
  const cursors = useCursors();
  const { notify } = useContext(NotificationsContext);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const variables = _.pick(cursors, ["first", "after"]);

  if (accessor === "allCategories") {
    variables.includeParcelCount = true;
  }

  const refetchQueries = [
    { query: CurrentUserAllCategoriesQuery },
    { query: CurrentUserMyPotfoliosQuery }
  ];

  const { data, loading: queryLoading } = useQuery(query, {
    variables,
    fetchPolicy: "cache-and-network"
  });
  const [categoryCreate] = useMutation(CategoryCreateMutation);
  const [categoryDelete] = useMutation(CategoryDeleteMutation);
  const count = _.get(data, `currentUser.${accessor}.totalCount`, 0);
  const owned = accessor === "myCategories";

  const { paginationProps } = usePagination({
    nextAfter: _.get(data, `currentUser.${accessor}.pageInfo.endCursor`),
    count,
    ...cursors
  });

  async function performCreateCategory(name) {
    setLoading(true);
    await actAndNotify(categoryCreate, "categoryCreate", {
      notify,
      mutateOptions: {
        variables: { name },
        refetchQueries,
        awaitRefetchQueries: true
      },
      successMessage: "Category Created!"
    })();
    setLoading(false);
  }

  async function performDeleteCategory(categoryId) {
    setLoading(true);
    await actAndNotify(categoryDelete, "categoryDelete", {
      notify,
      mutateOptions: {
        variables: { categoryId },
        refetchQueries,
        awaitRefetchQueries: true
      },
      successMessage: "Category Deleted!"
    })();
    setLoading(false);
  }

  return (
    <TableContainer component={Paper}>
      <Table
        className={classes.table}
        size="small"
        data-testid="categories-table"
      >
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell align="right"># Parcels</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {!loading && count === 0 && (
            <TableRow>
              <TableCell align="center" colSpan={3}>
                <b>No Categories!</b>
              </TableCell>
            </TableRow>
          )}
          {_.map(_.get(data, `currentUser.${accessor}.edges`), ({ node }) => (
            <TableRow key={node.id}>
              <TableCell>
                <Link
                  component={RouterLink}
                  to={`/category/${node.id}`}
                  underline="hover"
                >
                  {node.name}
                </Link>
              </TableCell>
              <TableCell align="right">
                {_.get(node, "parcels.totalCount", 0)}
              </TableCell>
              <TableCell align="right">
                {owned && (
                  <IconButton
                    color="secondary"
                    onClick={() => performDeleteCategory(node.id)}
                    size="large"
                  >
                    <Cancel />
                  </IconButton>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          {owned && (
            <TableRow>
              <TableCell colSpan={3} align="right">
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => setDialogOpen(true)}
                >
                  Create Category
                </Button>
              </TableCell>
            </TableRow>
          )}
          <TableRow>
            {(loading || queryLoading) && (
              <TableCell colSpan={3}>
                <LinearProgress data-testid="loading" />
              </TableCell>
            )}
            {!loading && !queryLoading && paginationProps && (
              <TablePagination {...paginationProps} />
            )}
          </TableRow>
        </TableFooter>
      </Table>
      <CreateCategoryDialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        perform={performCreateCategory}
      />
    </TableContainer>
  );
}

CategoriesList.propTypes = {
  query: PropTypes.object,
  slug: PropTypes.string
};

export default CategoriesList;
