import React from "react";
import { useQuery } from "@apollo/client";
import {
  AssistantPhoto,
  GroupWork,
  Map,
  MoveToInbox
} from "@mui/icons-material";
import GlobalSidenav from "ui/components/GlobalSidenav";
import CurrentSalespersonQuery from "ui/sites/acquisitions/queries/CurrentSalespersonQuery.graphql";

function AcquisitionsSidenav({ expanded = false, setExpanded = () => {} }) {
  const { data } = useQuery(CurrentSalespersonQuery);

  const links = [
    {
      Icon: MoveToInbox,
      path: "/acquisitions/inbound-leads",
      text: "Inbound Leads"
    },
    { Icon: AssistantPhoto, path: "/acquisitions", text: "My Leads" }
  ];

  if (data?.currentSalesperson?.isSalesManager) {
    links.push({
      Icon: GroupWork,
      path: "/acquisitions/team-leads",
      text: "My Team Leads"
    });
  }

  if (
    data?.currentSalesperson?.isSalesManager ||
    !data?.currentSalesperson?.salesManager
  ) {
    // sales managers and non-managed salespeople can view the parcel map
    links.push({
      Icon: Map,
      path: "/acquisitions/parcels",
      text: "Parcel Map"
    });
  }

  return (
    <GlobalSidenav
      expanded={expanded}
      setExpanded={setExpanded}
      links={links}
      showLogout={data?.currentSalesperson !== null}
    />
  );
}

export default AcquisitionsSidenav;
