import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  LinearProgress
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import LogEntry from "./LogEntry";

const useStyles = makeStyles(() => ({
  table: { width: "100%" }
}));

function Auditlog({ loading, logEntries, paginationProps, showLinks }) {
  const classes = useStyles();
  return (
    <TableContainer component={Paper} data-testid="auditlog-table">
      <Table
        className={classes.table}
        size="small"
        data-testid="auditlog-table-inner"
      >
        <TableHead>
          <TableRow>
            <TableCell>Timestamp</TableCell>
            <TableCell>Actor</TableCell>
            <TableCell>Action</TableCell>
            <TableCell>Resource</TableCell>
            <TableCell>ID</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {_.map(logEntries, logEntry => (
            <LogEntry logEntry={logEntry} key={logEntry.id} />
          ))}
          <TableRow>
            {loading && (
              <TableCell colSpan={5}>
                <LinearProgress data-testid="loading" />
              </TableCell>
            )}
            {!loading && paginationProps && (
              <TablePagination {...paginationProps} />
            )}
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}

Auditlog.propTypes = {
  logEntries: PropTypes.arrayOf(PropTypes.object).isRequired,
  paginationProps: PropTypes.object
};

export default Auditlog;
