import _ from "lodash";

export function getJSONArrayFromLocalStorage(key) {
  const val = localStorage.getItem(key);

  if (val !== undefined && val !== null && val !== "") {
    try {
      const result = JSON.parse(val);
      if (_.isArray(result)) {
        return result;
      }
    } catch {}
  }
  return [];
}

export function getIntegerFromLocalStorage(key, backoff = 0) {
  const result = _.parseInt(localStorage.getItem(key));
  if (_.isInteger(result)) {
    return result;
  }
  return backoff;
}

export function getStringFromLocalStorage(key) {
  const val = localStorage.getItem(key);
  if (val !== undefined && val !== null) {
    return val;
  }
  return "";
}
