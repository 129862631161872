import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { Formik, Form } from "formik";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Paper,
  Grid,
  LinearProgress,
  InputAdornment,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import PurchaseValueValidation from "./PurchaseValueValidation";

const useStyles = makeStyles(() => ({
  table: { width: "100%" }
}));

function PurchaseValue({ loading, onSubmit, purchaseValue }) {
  const readOnly = onSubmit === null;
  const classes = useStyles();
  const getError = ({ touched, field, errors }) =>
    touched[field] ? errors[field] : undefined;
  return (
    <Formik
      initialValues={{
        rent: _.get(purchaseValue, "rent", ""),
        wholesale: _.get(purchaseValue, "wholesale", ""),
        flip: _.get(purchaseValue, "flip", "")
      }}
      validationSchema={PurchaseValueValidation}
      onSubmit={onSubmit}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleSubmit,
        isSubmitting
      }) => (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Form onSubmit={onSubmit} data-testid="purchase-value-form">
              <TableContainer component={Paper}>
                <Table
                  className={classes.table}
                  size="small"
                  data-testid="purchase-value-table"
                >
                  <TableHead>
                    <TableRow>
                      {["Flip", "Wholesale", "Rent"].map(field => (
                        <TableCell align="right" key={field}>
                          <b>{field}</b>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      {["flip", "wholesale", "rent"].map(field => (
                        <TableCell data-testid={`cell-${field}`} key={field}>
                          {readOnly && values[field]}
                          {!readOnly && (
                            <TextField
                              name={field}
                              id={field}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    $
                                  </InputAdornment>
                                )
                              }}
                              inputProps={{ style: { textAlign: "right" } }}
                              fullWidth
                              onChange={handleChange}
                              value={values[field]}
                              error={Boolean(
                                getError({ field, errors, touched })
                              )}
                              helperText={getError({
                                field,
                                errors,
                                touched
                              })}
                            />
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Form>
          </Grid>
          <Grid item xs={12} container justifyContent="flex-end">
            {loading && (
              <Grid item xs={12}>
                <LinearProgress />
              </Grid>
            )}
            {!loading && !readOnly && (
              <Grid item xs={2}>
                <Button
                  onClick={handleSubmit}
                  type="submit"
                  variant="contained"
                  color="secondary"
                  size="large"
                  fullWidth
                  disabled={errors.length > 0}
                >
                  Submit
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
      )}
    </Formik>
  );
}

PurchaseValue.propTypes = {
  loading: PropTypes.bool,
  onSubmit: PropTypes.func,
  purchaseValue: PropTypes.object
};

PurchaseValue.defaultProps = {
  loading: false,
  onSubmit: null,
  purchaseValue: { rent: 0, wholesale: 0, flip: 0 }
};

export default PurchaseValue;
