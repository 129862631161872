import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import { useQuery, useMutation } from "@apollo/client";
import _ from "lodash";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Chip,
  TextField,
  FormControl,
  LinearProgress
} from "@mui/material";
import { Work } from "@mui/icons-material";
import Autocomplete from "@mui/material/Autocomplete";
import actAndNotify from "ui/lib/actAndNotify";
import NotificationsContext from "ui/contexts/NotificationsContext";
import CurrentUserAllPortfoliosQuery from "ui/queries/CurrentUserAllPortfoliosQuery.graphql";
import ParcelAddToPortfolioMutation from "ui/queries/ParcelAddToPortfolioMutation.graphql";
import ParcelRemoveFromPortfolioMutation from "ui/queries/ParcelRemoveFromPortfolioMutation.graphql";

function ParcelAddToPortfolioAction({ parcel }) {
  const { notify } = useContext(NotificationsContext);
  const [open, setOpen] = useState(false);
  const { data: allData, loading: allLoading } = useQuery(
    CurrentUserAllPortfoliosQuery,
    { variables: { first: 100 } }
  );
  const [parcelAddToPortfolioMutate] = useMutation(
    ParcelAddToPortfolioMutation
  );
  const [parcelRemoveFromPortfolioMutate] = useMutation(
    ParcelRemoveFromPortfolioMutation
  );

  const allPortfolios = _.map(
    _.get(allData, "currentUser.allPortfolios.edges"),
    ({ node }) => node
  );

  async function submitPortfolio(e) {
    e.preventDefault();
    const name = e.currentTarget.portfolio.value;
    const portfolio = _.find(allPortfolios, {
      name
    });
    await actAndNotify(parcelAddToPortfolioMutate, "parcelAddToPortfolio", {
      notify,
      mutateOptions: {
        variables: { portfolioId: portfolio.id, parcelId: parcel.id }
      },
      successMessage: `Added parcel to portfolio "${name}"`
    })();
    setOpen(false);
  }

  const removeFromPortfolio = portfolioId =>
    actAndNotify(parcelRemoveFromPortfolioMutate, "parcelRemoveFromPortfolio", {
      notify,
      mutateOptions: {
        variables: { portfolioId, parcelId: parcel.id }
      },
      successMessage: "Removed Parcel from Portfolio"
    });

  return (
    <>
      <Button color="primary" onClick={() => setOpen(true)}>
        <Work />
      </Button>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Portfolios</DialogTitle>
        <DialogContent>
          <Grid container style={{ width: 300 }}>
            <Grid item xs={12}>
              {_.map(_.get(parcel, "portfolioSet.edges", []), ({ node }) => (
                <Chip
                  key={node.id}
                  label={node.name}
                  onDelete={removeFromPortfolio(node.id)}
                />
              ))}
            </Grid>
            {allLoading && (
              <Grid item xs={12}>
                <LinearProgress />
              </Grid>
            )}
            {!allLoading && (
              <Grid item xs={12} container>
                <FormControl fullWidth>
                  <form onSubmit={submitPortfolio}>
                    <Grid item xs={12}>
                      <Autocomplete
                        id="portfolio-picker"
                        fullWidth
                        options={allPortfolios}
                        getOptionLabel={({ name }) => name}
                        renderInput={params => (
                          <TextField
                            {...params}
                            name="portfolio"
                            label="Choose a Portfolio"
                            variant="outlined"
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="secondary"
                      >
                        Submit
                      </Button>
                    </Grid>
                  </form>
                </FormControl>
              </Grid>
            )}
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
}

ParcelAddToPortfolioAction.propTypes = {
  parcel: PropTypes.object
};

export default ParcelAddToPortfolioAction;
