import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import {
  Paper,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableContainer,
  TableRow,
  TableFooter,
  TablePagination,
  LinearProgress
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Download } from "@mui/icons-material";

const useStyles = makeStyles(() => ({
  table: { width: "100%" }
}));

function ExportsList({ loading, exports, getDownloadUrl, paginationProps }) {
  const classes = useStyles();

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} size="small" data-testid="exports-table">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell align="right">Ready</TableCell>
            <TableCell align="right">Download</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!loading && paginationProps.count === 0 && (
            <TableRow>
              <TableCell align="center" colSpan={3}>
                <b>No Exports!</b>
              </TableCell>
            </TableRow>
          )}
          {_.map(exports, node => (
            <TableRow key={node.id}>
              <TableCell>{node.key.replace("exports/", "")}</TableCell>
              <TableCell align="right">
                {node.completed ? "✅" : "⏳"}
              </TableCell>
              <TableCell align="right">
                {node.completed && (
                  <IconButton size="small" onClick={getDownloadUrl(node.id)}>
                    <Download />
                  </IconButton>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            {loading && (
              <TableCell colSpan={3}>
                <LinearProgress data-testid="loading" />
              </TableCell>
            )}
            {!loading && paginationProps && (
              <TablePagination {...paginationProps} />
            )}
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}

ExportsList.propTypes = {
  loading: PropTypes.bool,
  exports: PropTypes.arrayOf(PropTypes.object),
  paginationProps: PropTypes.object,
  getDownloadUrl: PropTypes.func
};

export default ExportsList;
