import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import {
  Select,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Grid,
  FormControl,
  InputLabel
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { AddCircle } from "@mui/icons-material";
import actAndNotify from "ui/lib/actAndNotify";
import NotificationsContext from "ui/contexts/NotificationsContext";
import LeadStatusChip from "ui/components/LeadStatusChip";
import InviteUserDialog from "ui/components/InviteUserDialog";
import CurrentSalespersonContext from "ui/sites/acquisitions/contexts/CurrentSalespersonContext";
import { useLeadActions } from "ui/sites/acquisitions/contexts/LeadActionsContext";

const useStyles = makeStyles(() => ({
  selector: {
    minWidth: "150px"
  }
}));

function LeadStatusSelector({ lead }) {
  const classes = useStyles();
  const [statusStepOne, setStatusStepOne] = useState(null);
  const [invitingUser, setInvitingUser] = useState(false);
  const { currentSalesperson, salespersonInvite } = useContext(
    CurrentSalespersonContext
  );
  const { generateLead, modifyLead, refetchQueries } = useLeadActions(
    lead.parcel.id
  );
  const { notify } = useContext(NotificationsContext);
  let disabled =
    lead.salesperson && lead.salesperson.id !== currentSalesperson.id;

  if (currentSalesperson.isSalesManager && disabled) {
    disabled = lead?.salesperson?.salesManager?.id !== currentSalesperson.id;
  }

  async function doLeadStatusChange({ status, assignedSalespersonId = null }) {
    const variables = { parcelId: lead.parcel.id, status };
    if (assignedSalespersonId) {
      variables.assignedSalespersonId = assignedSalespersonId;
    }
    const [mutate, accessor, verb] = _.isNil(lead.salesperson)
      ? [generateLead, "leadGenerate", "Claimed"]
      : [modifyLead, "leadModify", "Renewed"];
    await actAndNotify(mutate, accessor, {
      notify,
      successMessage: `${verb} lead with status ${status}`,
      mutateOptions: { variables, refetchQueries }
    })();
    setStatusStepOne(null);
  }

  async function onChangeLeadStatus(e) {
    const status = e.target.value;
    return doLeadStatusChange({ status });
  }

  function onChangeLeadStatusStepOne(e) {
    setStatusStepOne(e.target.value);
  }

  async function onChangeLeadStatusWithSalesperson(e) {
    await doLeadStatusChange({
      status: statusStepOne,
      assignedSalespersonId: e.target.value
    });
  }

  async function inviteSalesperson(email) {
    await actAndNotify(salespersonInvite, "salespersonInvite", {
      notify,
      successMessage: `Successfully invited ${email}!`,
      mutateOptions: { variables: { email } }
    })();
  }

  return (
    <>
      <FormControl style={{ minWidth: "150px" }}>
        <InputLabel id="select-status-label">Select a Status</InputLabel>
        <Select
          className={classes.selector}
          labelId="select-status-label"
          id="select-status"
          data-testid="select-status"
          value={_.defaultTo(lead.status, "")}
          onChange={
            currentSalesperson.isSalesManager
              ? onChangeLeadStatusStepOne
              : onChangeLeadStatus
          }
          disabled={disabled}
          variant="standard"
          fullWidth
        >
          <MenuItem value="" disabled>
            Select a Status...
          </MenuItem>
          <MenuItem value="HOT">
            <LeadStatusChip status="HOT" />
          </MenuItem>
          <MenuItem value="WARM">
            <LeadStatusChip status="WARM" />
          </MenuItem>
          <MenuItem value="CHECK_BACK_30">
            <LeadStatusChip status="CHECK BACK 30" />
          </MenuItem>
          <MenuItem value="CHECK_BACK_YEAR">
            <LeadStatusChip status="CHECK_BACK_YEAR" />
          </MenuItem>
          <MenuItem value="COLD">
            <LeadStatusChip status="COLD" />
          </MenuItem>
        </Select>
      </FormControl>
      {currentSalesperson.isSalesManager && (
        <Dialog
          open={statusStepOne !== null}
          onClose={() => setStatusStepOne(null)}
          maxWidth="sm"
          data-testid="lead-status-selector-dialog"
          fullWidth
        >
          <DialogTitle id="select-salesperson">
            Select a Salesperson
          </DialogTitle>
          <DialogContent>
            <Grid container alignItems="center">
              <Grid item xs={11}>
                <Select
                  data-testid="select-salesperson"
                  id="select-salesperson"
                  onChange={onChangeLeadStatusWithSalesperson}
                  value=""
                  variant="standard"
                  fullWidth
                >
                  <MenuItem value="" disabled>
                    Assign a salesperson...
                  </MenuItem>
                  <MenuItem value={null}>Me</MenuItem>
                  {_.map(
                    _.get(currentSalesperson, "salespersonSet.edges", []),
                    ({ node }) => (
                      <MenuItem key={node.id} value={node.id}>
                        {node.user.email}
                      </MenuItem>
                    )
                  )}
                </Select>
              </Grid>
              <Grid item xs={1}>
                <IconButton
                  aria-label="Add Salesperson"
                  id="add-salesperson-button"
                  onClick={() => setInvitingUser(true)}
                  size="large"
                >
                  <AddCircle />
                </IconButton>
              </Grid>
            </Grid>
            <InviteUserDialog
              open={invitingUser}
              onClose={() => setInvitingUser(false)}
              titleText="Invite a New Salesperson"
              perform={inviteSalesperson}
            />
          </DialogContent>
        </Dialog>
      )}
    </>
  );
}

LeadStatusSelector.propTypes = {
  lead: PropTypes.object.isRequired
};

export default LeadStatusSelector;
