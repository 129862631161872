import React from "react";
import PropTypes from "prop-types";
import { TextField, Box, Button } from "@mui/material";
import { Formik, Form } from "formik";
import PasswordResetFormValidation from "./PasswordResetFormValidation";
import useStyles from "./useStyles";

function PasswordResetForm({ handleSubmit }) {
  const classes = useStyles();

  const getError = ({ touched, field, errors }) =>
    touched[field] ? errors[field] : undefined;

  return (
    <Formik
      initialValues={{
        email: "",
        password: ""
      }}
      validationSchema={PasswordResetFormValidation}
      onSubmit={handleSubmit}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleSubmit,
        isSubmitting
      }) => (
        <Form onSubmit={handleSubmit} data-testid="password-reset-form">
          <Box mt={5}>
            <TextField
              name="email"
              id="email"
              label="Email Address"
              variant="outlined"
              fullWidth
              onChange={handleChange}
              value={values.email}
              disabled={isSubmitting}
              error={Boolean(getError({ field: "email", errors, touched }))}
              helperText={getError({ field: "email", errors, touched })}
            />
          </Box>

          <Box mt={5}>
            <TextField
              id="password"
              name="password"
              type="password"
              label="Password"
              variant="outlined"
              fullWidth
              onChange={handleChange}
              value={values.password}
              disabled={isSubmitting}
              error={Boolean(getError({ field: "password", errors, touched }))}
              helperText={getError({ field: "password", errors, touched })}
            />
          </Box>
          <Box mt={5}>
            <TextField
              id="passwordConfirmation"
              name="passwordConfirmation"
              type="password"
              label="Password Confirmation"
              variant="outlined"
              fullWidth
              onChange={handleChange}
              value={values.passwordConfirmation}
              disabled={isSubmitting}
              error={Boolean(
                getError({ field: "passwordConfirmation", errors, touched })
              )}
              helperText={getError({
                field: "passwordConfirmation",
                errors,
                touched
              })}
            />
          </Box>

          <Button
            className={classes.passwordResetButton}
            variant="contained"
            color="secondary"
            fullWidth
            disabled={isSubmitting}
            id="password-reset-button"
            data-testid="password-reset-button"
            type="submit"
            title="Reset Password"
          >
            Reset Password
          </Button>
        </Form>
      )}
    </Formik>
  );
}

PasswordResetForm.propTypes = {
  handleSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool
};

export default PasswordResetForm;
