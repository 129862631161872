import React, { useContext, useEffect, useState } from "react";
import _ from "lodash";
import { useQuery } from "@apollo/client";

import MapContext from "ui/contexts/MapContext";
import MapLayerContext from "ui/contexts/MapLayerContext";
import CurrentUserCensusTractsQuery from "ui/queries/CurrentUserCensusTractsQuery.graphql";
import showDemographics from "./showDemographics";

import Legend from "./Legend";
import TractInfoDrawer from "./TractInfoDrawer";
import { createOverlay, clearOverlays } from "./overlays";
import { renderDemographics } from "./layers";

window.mapOverlays = {};

function MapLayers({ children }) {
  const {
    demographics,
    setDemographics,
    showCensusTracts,
    demographicSelection,
    mapOverlays
  } = useContext(MapLayerContext);
  const [map, setMap] = useState(null);
  const [lines, setLines] = useState([]);
  const [tract, setTract] = useState(null);
  const { onGoogleApiLoaded, polygon } = useContext(MapContext);

  const { data: censusTractsData } = useQuery(CurrentUserCensusTractsQuery, {
    variables: { polygon }
  });

  const censusTracts = _.map(
    _.get(censusTractsData, "currentUser.censusTracts.edges", []),
    "node"
  );

  function onGoogleApiLoadedWithLayers({ map, maps }) {
    setMap(map);
    onGoogleApiLoaded({ map, maps });
    _.map(mapOverlays, createOverlay(map));
  }

  useEffect(
    () =>
      _.debounce(() => {
        if (map) {
          clearOverlays(mapOverlays);
          _.map(mapOverlays, createOverlay(map));
        }
        if (showCensusTracts) {
          if (_.isEmpty(lines)) {
            setLines(
              _.map(
                _.filter(censusTracts, tract => !_.isEmpty(tract.geoJson)),
                tract => {
                  // eslint-disable-next-line
                  const line = new google.maps.Polyline({
                    path: _.map(tract.geoJson.coordinates[0], ([lng, lat]) => ({
                      lng,
                      lat
                    })),
                    geodesic: true,
                    strokeColor: "#ee1111",
                    strokeOpacity: 1.0,
                    strokeWeight: 1
                  });
                  line.setMap(map);
                  return line;
                }
              )
            );
          } else {
            _.map(lines, line => {
              line.setVisible(true);
            });
          }
        } else {
          _.map(lines, line => {
            line.setVisible(false);
          });
        }

        function newDemographic(
          map,
          tract,
          { fillColor, fillOpacity },
          data,
          showOverlay = showDemographics
        ) {
          // eslint-disable-next-line
          const polygon = new google.maps.Polygon({
            paths: _.map(tract.geoJson.coordinates[0], ([lng, lat]) => ({
              lng,
              lat
            })),
            geodesic: true,
            fillColor,
            fillOpacity,
            fillWeight: 1,
            strokeColor: "#ee1111",
            strokeOpacity: 1,
            strokeWeight: 1
          });
          polygon.setMap(map);
          // eslint-disable-next-line
          const infoWindow = new google.maps.InfoWindow();
          polygon.addListener(
            "click",
            showOverlay(tract, data, infoWindow, map, setTract)
          );
          return { polygon, tract };
        }

        if (demographicSelection) {
          renderDemographics(
            map,
            demographics,
            demographicSelection,
            setDemographics,
            censusTracts,
            newDemographic
          );
        } else {
          _.map(demographics, demographic => {
            demographic.polygon.setVisible(false);
          });
        }
      }, 250)(),
    [
      map,
      censusTracts,
      showCensusTracts,
      demographicSelection,
      demographics,
      setDemographics,
      lines,
      mapOverlays
    ]
  );

  return (
    <div>
      <Legend demographicSelection={demographicSelection} />
      {tract && <TractInfoDrawer tract={tract} setTract={setTract} />}
      <MapLayerContext.Provider
        value={{
          onGoogleApiLoaded: onGoogleApiLoadedWithLayers
        }}
      >
        {children}
      </MapLayerContext.Provider>
    </div>
  );
}

export default MapLayers;
