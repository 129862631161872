import React, { useContext } from "react";
import _ from "lodash";
import { useQuery } from "@apollo/client";
import {
  Grid,
  Select,
  MenuItem,
  OutlinedInput,
  FormControl,
  InputLabel,
  Chip,
  FormGroup,
  FormControlLabel,
  Checkbox
} from "@mui/material";
import { Clear } from "@mui/icons-material";
import MapContext from "ui/contexts/MapContext";
import MapLayerContext from "ui/contexts/MapLayerContext";
import CurrentUserAllMapOverlaysQuery from "ui/queries/CurrentUserAllMapOverlaysQuery.graphql";
import Loader from "ui/components/Loader";
import { availableDemographics } from "ui/lib/demographics";

function MapOverlaySelector({ hideIgnoreMapChanges = false }) {
  const { ignoreMapChanges, setIgnoreMapChanges } = useContext(MapContext);
  const {
    mapOverlays,
    setMapOverlays,
    showCensusTracts,
    setShowCensusTracts,
    demographicSelection,
    setDemographicSelection
  } = useContext(MapLayerContext);
  const { data, loading } = useQuery(CurrentUserAllMapOverlaysQuery);

  const availableOverlays = _.map(
    _.get(data, "currentUser.allMapOverlays.edges", []),
    "node"
  );
  const overlaysByUrl = _.keyBy(availableOverlays, "kmlUrl");

  if (loading) {
    return (
      <Grid item xs={12} container spacing={1}>
        <Grid item xs={3}>
          <Loader testid="overlaysLoading" />
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid item xs={12} container spacing={1}>
      <Grid item xs={3}>
        <FormControl sx={{ m: 1, width: "95%" }}>
          <InputLabel id="map-overlay-selector-label">Map Overlays</InputLabel>
          <Select
            labelId="map-overlay-selector-label"
            id="map-overlay-selector"
            multiple
            value={mapOverlays}
            onChange={event =>
              setMapOverlays([...mapOverlays, ...event.target.value])
            }
            input={<OutlinedInput id="select-overlays" label="Map Overlays" />}
          >
            {_.map(availableOverlays, node => (
              <MenuItem key={node.kmlUrl} value={node.kmlUrl}>
                {node.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={3}>
        {_.map(mapOverlays, overlayUrl => (
          <Chip
            key={overlayUrl}
            label={`${overlaysByUrl[overlayUrl].name}`}
            variant="filled"
            data-testid={`mapoverlay-${overlayUrl}`}
            onDelete={() =>
              setMapOverlays([..._.pull(mapOverlays, overlayUrl)])
            }
            deleteIcon={<Clear />}
          />
        ))}
      </Grid>
      <Grid item xs={3}>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={showCensusTracts}
                onChange={() => setShowCensusTracts(!showCensusTracts)}
              />
            }
            label="Show Census Tracts"
          />
          {!hideIgnoreMapChanges && (
            <FormControlLabel
              control={
                <Checkbox
                  checked={ignoreMapChanges}
                  onChange={() => setIgnoreMapChanges(!ignoreMapChanges)}
                />
              }
              label="Ignore Map Changes"
            />
          )}
        </FormGroup>
      </Grid>
      <Grid item xs={3}>
        <FormControl sx={{ m: 1, width: "95%" }}>
          <InputLabel id="demographics-selector-label">
            Demographics & Income
          </InputLabel>
          <Select
            labelId="demographics-selector-label"
            id="demographics-selector"
            value={demographicSelection}
            onChange={event => setDemographicSelection(event.target.value)}
            input={
              <OutlinedInput
                id="select-demographics"
                label="Demographics & Income"
              />
            }
          >
            <MenuItem key="None" value="">
              No Demographics
            </MenuItem>
            <MenuItem key="summary" value="summary">
              Demographic Summary
            </MenuItem>
            <MenuItem key="ownerOccupied" value="ownerOccupied">
              Owner / Renter Occupied
            </MenuItem>
            {_.map(availableDemographics, ({ title }, slug) =>
              title === "Total" ? null : (
                <MenuItem key={slug} value={slug}>
                  {title}
                </MenuItem>
              )
            )}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
}

export default MapOverlaySelector;
