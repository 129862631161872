import React, { useState, useContext, useRef } from "react";
import PropTypes from "prop-types";
import NotificationsContext from "ui/contexts/NotificationsContext";
import actAndNotify from "ui/lib/actAndNotify";

import { Grid, Typography, TextField, Button } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import ParcelDocument from "./ParcelDocument";

const useStyles = makeStyles(theme => ({
  parcelDocuments: {
    padding: theme.spacing(2, 1)
  }
}));

function ParcelDocuments({ parcel, uploadMutation, deleteMutation }) {
  const classes = useStyles();
  const [description, setDescription] = useState("");
  const { notify } = useContext(NotificationsContext);
  const ref = useRef();

  async function onFileUpload(e) {
    const {
      target: {
        validity,
        files: [file]
      }
    } = e;
    if (validity.valid) {
      await actAndNotify(uploadMutation, "parcelDocumentUpload", {
        notify,
        mutateOptions: {
          variables: { file, parcelId: parcel.id, description }
        },
        successMessage: "Upload succeeded!"
      })();
      setDescription("");
      ref.current.value = null;
      return;
    }
    notify({ variant: "error", message: "There was an error." });
  }

  let inner;
  if ((parcel?.parceldocumentSet?.totalCount || 0) === 0) {
    inner = (
      <Grid item xs={12}>
        <Typography>No Documents!</Typography>
      </Grid>
    );
  } else {
    inner = parcel.parceldocumentSet.edges.map(({ node }) => (
      <ParcelDocument
        parcelDocument={node}
        parcelId={parcel.id}
        key={node.id}
        deleteMutation={deleteMutation}
      />
    ));
  }
  return (
    <Grid container className={classes.parcelDocuments}>
      <Grid item xs={12}>
        <Typography variant="h4" gutterBottom align="center">
          Documents
        </Typography>
      </Grid>
      {inner}
      <Grid item xs={12} container>
        <Grid item xs={8}>
          <TextField
            label="Description"
            value={description}
            fullWidth
            onChange={e => setDescription(e.target.value)}
          />
        </Grid>
        <Grid item xs={4}>
          <input
            id="parcel-document-upload"
            style={{ display: "none" }}
            type="file"
            disabled={!description}
            onChange={onFileUpload}
            ref={ref}
          />
          <label htmlFor="parcel-document-upload">
            <Button
              variant="contained"
              color="primary"
              component="span"
              disabled={!description}
              fullWidth
            >
              Upload Document
            </Button>
          </label>
        </Grid>
      </Grid>
    </Grid>
  );
}

ParcelDocuments.propTypes = {
  parcel: PropTypes.object.isRequired,
  uploadMutation: PropTypes.func.isRequired,
  deleteMutation: PropTypes.func.isRequired
};

export default ParcelDocuments;
