import React from "react";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/client";
import ParcelImages from "ui/components/ParcelImages";
import Loader from "ui/components/Loader";
import { useUnderwritingActions } from "ui/sites/underwriting/contexts/UnderwritingActionsContext";
import CurrentUnderwriterParcelImagesQuery from "ui/sites/underwriting/queries/CurrentUnderwriterParcelImagesQuery.graphql";

function UnderwritingParcelImagesContainer({ parcelId }) {
  const { data, loading } = useQuery(CurrentUnderwriterParcelImagesQuery, {
    variables: { parcelId }
  });
  const { uploadParcelImage, deleteParcelImage } =
    useUnderwritingActions(parcelId);

  if (loading) {
    return <Loader />;
  }
  return (
    <ParcelImages
      parcel={data.currentUnderwriter?.parcel}
      uploadMutation={uploadParcelImage}
      deleteMutation={deleteParcelImage}
    />
  );
}

UnderwritingParcelImagesContainer.propTypes = {
  parcelId: PropTypes.string.isRequired
};

export default UnderwritingParcelImagesContainer;
