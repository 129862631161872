import React from "react";
import _ from "lodash";
import { Grid, TablePagination, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Loader from "ui/components/Loader";
import ParcelCard from "ui/components/ParcelCard";

const useStyles = makeStyles(theme => ({
  parcelCardWrapper: {
    margin: theme.spacing(1, 1.5, 1, 1.5)
  },
  cardContainer: {
    maxHeight: 700,
    overflowY: "scroll"
  },
  noneFound: {
    marginTop: theme.spacing(3)
  }
}));

function ParcelCards({ loading, parcels, paginationProps }) {
  const classes = useStyles();
  if (loading) {
    return <Loader />;
  }

  return (
    <Grid container data-testid="parcel-cards">
      {!loading && paginationProps && paginationProps.count !== 0 && (
        <Grid container item xs={12} style={{ width: "100%" }}>
          <TablePagination
            {...paginationProps}
            style={{ width: "100%" }}
            component="div"
          />
        </Grid>
      )}
      <Grid container item xs={12} className={classes.cardContainer}>
        {paginationProps.count === 0 && (
          <Grid item xs={12} className={classes.noneFound}>
            <Typography variant="h4" align="center">
              No Parcels Found
            </Typography>
          </Grid>
        )}
        {_.map(parcels, parcel => (
          <Grid
            item
            xs={12}
            key={parcel.id}
            className={classes.parcelCardWrapper}
          >
            <ParcelCard parcel={parcel} />
          </Grid>
        ))}
      </Grid>
      {!loading && paginationProps && paginationProps.count !== 0 && (
        <Grid container item xs={12} style={{ width: "100%" }}>
          <TablePagination
            {...paginationProps}
            style={{ width: "100%" }}
            component="div"
          />
        </Grid>
      )}
    </Grid>
  );
}

export default ParcelCards;
