import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { useQuery } from "@apollo/client";
import Loader from "ui/components/Loader";
import ParcelNotes from "ui/components/ParcelNotes";
import { useUnderwritingActions } from "ui/sites/underwriting/contexts/UnderwritingActionsContext";
import CurrentUnderwriterParcelNotesQuery from "ui/sites/underwriting/queries/CurrentUnderwriterParcelNotesQuery.graphql";
function UnderwritingParcelNotesContainer({ parcelId }) {
  const { data, loading } = useQuery(CurrentUnderwriterParcelNotesQuery, {
    variables: { parcelId }
  });
  const { submitNote } = useUnderwritingActions(parcelId);

  if (loading) {
    return <Loader />;
  }
  return (
    <ParcelNotes
      parcelId={parcelId}
      parcelNotes={_.get(
        data,
        "currentUnderwriter.parcel.parcelnoteSet.edges",
        []
      )}
      submitNote={submitNote}
    />
  );
}

UnderwritingParcelNotesContainer.propTypes = {
  parcelId: PropTypes.string.isRequired
};

export default UnderwritingParcelNotesContainer;
