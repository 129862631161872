import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  FormControl,
  Button,
  TextField,
  Grid
} from "@mui/material";

function InviteUserDialog({
  open,
  onClose,
  perform,
  titleText = "Invite User"
}) {
  const [email, setEmail] = useState("");
  const onSubmit = async e => {
    e.preventDefault();
    await perform(email);
    onClose();
  };
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      data-testid="invite-user-dialog"
      fullWidth
    >
      <DialogTitle id="invite-user-title">{titleText}</DialogTitle>
      <DialogContent>
        <form onSubmit={onSubmit}>
          <FormControl fullWidth>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  id="email"
                  name="email"
                  label="Email"
                  value={email}
                  variant="outlined"
                  onChange={e => setEmail(e.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={4} />
              <Grid item xs={4}>
                <Button variant="contained" fullWidth onClick={onClose}>
                  Cancel
                </Button>
              </Grid>
              <Grid item xs={4}>
                <Button
                  data-testid="submit-invite-user"
                  type="submit"
                  variant="contained"
                  fullWidth
                  color="primary"
                >
                  Invite
                </Button>
              </Grid>
            </Grid>
          </FormControl>
        </form>
      </DialogContent>
    </Dialog>
  );
}

InviteUserDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  perform: PropTypes.func,
  titleText: PropTypes.string
};

export default InviteUserDialog;
