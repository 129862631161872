import React from "react";
import PropTypes from "prop-types";
import { Grid, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import AcquisitionsCard from "./AcquisitionsCard";
import UnderwritingCard from "./UndewritingCard";

const useStyles = makeStyles(theme => ({
  selectorContainer: {
    marginTop: theme.spacing(4),
    padding: theme.spacing(1)
  }
}));

function SiteSelector({ isSalesperson, isUnderwriter }) {
  const classes = useStyles();
  const noneAvailable = ![isSalesperson, isUnderwriter].some(Boolean);
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      spacing={4}
      className={classes.selectorContainer}
    >
      {isSalesperson && <AcquisitionsCard />}
      {isUnderwriter && <UnderwritingCard />}
      {noneAvailable && (
        <>
          <Typography
            align="center"
            variant="h3"
            data-testid="no-sites-available"
          >
            No Sites Available
          </Typography>
          <Typography align="center">Please contact suport.</Typography>
        </>
      )}
    </Grid>
  );
}

SiteSelector.propTypes = {
  isSalesperson: PropTypes.bool,
  isUnderwriter: PropTypes.bool
};

export default SiteSelector;
