import React, { useState } from "react";
import AlineaSnackbar from "ui/components/AlineaSnackbar";

const NotificationContext = React.createContext();

export const withNotifications = Component => props => {
  const defaultNotificationSettings = {
    open: false,
    message: "",
    variant: "info",
    closeFn: () => {}
  };
  const [notificationSettings, setNotificationSettings] = useState(
    defaultNotificationSettings
  );
  const resetNotificationSettings = () =>
    setNotificationSettings(defaultNotificationSettings);
  const validAndOpen =
    notificationSettings.open &&
    !!notificationSettings.variant &&
    !!notificationSettings.message;
  return (
    <NotificationContext.Provider
      value={{
        notificationSettings,
        notify: args =>
          setNotificationSettings({
            closeFn: resetNotificationSettings,
            variant: "info",
            open: true,
            ...args
          }),
        resetNotificationSettings
      }}
    >
      <Component {...props} />
      {validAndOpen && <AlineaSnackbar {...notificationSettings} />}
    </NotificationContext.Provider>
  );
};

export default NotificationContext;
