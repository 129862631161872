import React, { useContext } from "react";
import PropTypes from "prop-types";
import { DateTime } from "luxon";
import currency from "currency.js";
import { Link as RouterLink } from "react-router-dom";
import { Checkbox, Chip, Link, TableCell, TableRow } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import CurrentSalespersonContext from "ui/sites/acquisitions/contexts/CurrentSalespersonContext";
import LeadStatusSelector from "ui/sites/acquisitions/components/LeadStatusSelector";
import ParcelAddToPorfolioAction from "ui/components/ParcelAddToPorfolioAction";
import PromoteLeadAction from "./PromoteLeadAction";
import RemoveLeadAction from "./RemoveLeadAction";
import AssignLeadAction from "./AssignLeadAction";

const useStyles = makeStyles(() => ({
  AWAITING_APPROVAL: {
    backgroundColor: "rgba(100, 50, 100, 0.7)"
  },
  AWAITING_ASSIGMENT: {
    backgroundColor: "rgba(100, 100, 100, 0.5)"
  },
  ASSIGNED: {
    backgroundColor: "rgba(50, 100, 100, 0.7)"
  },
  APPROVED: {
    backgroundColor: "rgba(50, 150, 50, 0.7)"
  }
}));

function LeadRow({
  lead,
  noLinks,
  checked,
  withExportSelection = false,
  handleCheckedChange = () => {},
  withPortfolioManagement = false
}) {
  const classes = useStyles();
  const {
    parcel: { id: parcelId, addressFull, city, state, zipcode, lastSalePrice },
    expiresOn,
    salesperson,
    currentRequest
  } = lead;

  const { currentSalesperson } = useContext(CurrentSalespersonContext);

  const isSalespersonLead =
    salesperson && salesperson.id === currentSalesperson.id;

  return (
    <TableRow>
      {withExportSelection && (
        <TableCell>
          <Checkbox checked={checked} onChange={handleCheckedChange} />
        </TableCell>
      )}
      <TableCell>
        <LeadStatusSelector lead={lead} />
      </TableCell>
      <TableCell>
        {noLinks && addressFull}
        {!noLinks && (
          <Link
            component={RouterLink}
            to={`/acquisitions/parcel/${parcelId}`}
            underline="hover"
          >
            {addressFull}
          </Link>
        )}
      </TableCell>
      <TableCell>{city}</TableCell>
      <TableCell>{state}</TableCell>
      <TableCell>{zipcode}</TableCell>
      <TableCell>
        {currency(lastSalePrice, { precision: 0 }).format()}
      </TableCell>
      <TableCell>{DateTime.fromISO(expiresOn).toRelative()}</TableCell>
      <TableCell data-testid="assign-lead-cell">
        <AssignLeadAction lead={lead} />
      </TableCell>
      <TableCell>
        {currentRequest && (
          <Chip
            className={classes[currentRequest.status]}
            label={`${currentRequest.status.replace(
              "_",
              " "
            )} | Asking: ${currency(currentRequest.askingPrice, {
              precision: 0
            }).format()} `}
          />
        )}
        {!currentRequest && isSalespersonLead && (
          <PromoteLeadAction parcelId={parcelId} />
        )}
      </TableCell>
      <TableCell>
        {isSalespersonLead && <RemoveLeadAction parcelId={parcelId} />}
        {withPortfolioManagement && (
          <ParcelAddToPorfolioAction parcel={lead.parcel} />
        )}
      </TableCell>
    </TableRow>
  );
}

LeadRow.propTypes = {
  noLinks: PropTypes.bool,
  lead: PropTypes.object,
  withPortfolioManagement: PropTypes.bool,
  checked: PropTypes.bool,
  handleCheckedChange: PropTypes.func
};

export default LeadRow;
