import React from "react";
export const defaultParcelCursorContextValues = {
  includeClaimed: true,
  search: null,
  after: null,
  lastCursor: [],
  categories: [],
  zoningCategories: [],
  categoryGroupings: [],
  lenders: [],
  lenderIds: [],
  page: 0,
  cursorId: null,
  polygon: null,
  defaultZoom: 12,
  purchaseDateRange: null,
  loanDateRange: null,
  loanMaturityDateRange: null,
  defaultCenter: {
    lat: 33.749,
    lng: -84.388
  },
  mapOverlays: []
};

const ParcelCursorContext = React.createContext({
  ...defaultParcelCursorContextValues
});

export default ParcelCursorContext;
