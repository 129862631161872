import React from "react";
import PropTypes from "prop-types";
import { Chip } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import {
  hot,
  cold,
  checkBack30,
  checkBackYear,
  warm,
  greyText
} from "ui/themes/AlineaTheme/colors";

const useStyles = makeStyles(theme => ({
  // why yes I could just dynamically transform these
  HOT: { backgroundColor: hot },
  WARM: { backgroundColor: warm },
  CHECK_BACK_30: { backgroundColor: checkBack30 },
  CHECK_BACK_YEAR: { backgroundColor: checkBackYear },
  COLD: { backgroundColor: cold, color: "white" },
  EXPIRED: { backgroundColor: greyText, color: "black" },
  chip: { fontWeight: "bold", minWidth: theme.spacing(20) }
}));

function LeadStatusChip({ status, expired }) {
  const classes = useStyles();
  if (!status) {
    return null;
  }
  return (
    <Chip
      className={`${classes[status]} ${classes.chip}`}
      size="small"
      label={status.replace(/_/g, " ")}
    />
  );
}

LeadStatusChip.propTypes = {
  status: PropTypes.string.isRequired
};

export default LeadStatusChip;
