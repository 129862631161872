import React from "react";
import _ from "lodash";
import accounting from "accounting";
import PropTypes from "prop-types";
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper
} from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

accounting.settings = {
  currency: {
    symbol: "$", // default currency symbol is '$'
    format: {
      pos: "%s %v", // for positive values, eg. "$ 1.00" (required)
      neg: "%s (%v)", // for negative values, eg. "$ (1.00)" [optional]
      zero: "%s  -- " // for zero values, eg. "$  --" [optional]
    },
    decimal: ".", // decimal point separator
    thousand: ",", // thousands separator
    precision: 2 // decimal places
  },
  number: {
    precision: 0, // default precision on numbers is 0
    thousand: ",",
    decimal: "."
  }
};

const useStyles = makeStyles(() => ({
  table: { width: "100%" },
  header: {
    backgroundColor: "#333",
    color: "#eee",
    fontWeight: "bold"
  },
  cell: {
    fontFamily: "monospace",
    whiteSpace: "pre"
  }
}));

function RentalPurchaseValueWorksheetTable({ worksheet }) {
  const classes = useStyles();

  if (!_.has(worksheet, "calculatedValues")) {
    return (
      <>
        <Typography variant="h3">No Data</Typography>
        <Typography variant="caption">Please Enter Worksheet Values</Typography>
      </>
    );
  }

  const formattedCalculatedValues = _.map(worksheet.calculatedValues, obj => {
    const pairs = _.toPairs(obj);
    const keys = _.map(pairs, x => x[0]);
    const values = _.map(pairs, x => x[1]);
    const formattedValues = accounting.formatColumn(values, "$");
    return _.fromPairs(_.map(keys, (k, i) => [k, formattedValues[i]]));
  });

  return (
    <TableContainer component={Paper}>
      <Table
        className={classes.table}
        size="small"
        data-testid="worksheet-table"
      >
        <TableHead>
          <TableRow className={classes.header}>
            <TableCell className={classes.header}>Years</TableCell>
            {_.map(_.range(6), i => (
              <TableCell key={i} className={classes.header} align="right">
                {i}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow id="purchase-price">
            <TableCell className={classes.header}>Purchase Price</TableCell>
            <TableCell align="right" className={classes.cell}>
              {formattedCalculatedValues[0].purchasePrice}
            </TableCell>
            {_.map(_.range(1, 6), i => (
              <TableCell key={i} align="right" />
            ))}
          </TableRow>
          <TableRow id="capex">
            <TableCell className={classes.header}>
              CAPEX (Rehab Budget)
            </TableCell>
            <TableCell align="right" className={classes.cell}>
              {formattedCalculatedValues[0].capex}
            </TableCell>
            {_.map(_.range(1, 6), i => (
              <TableCell key={i} align="right" />
            ))}
          </TableRow>
          <TableRow id="rental-income">
            <TableCell className={classes.header}>Rental Income</TableCell>
            {_.map(_.range(0, 6), i => (
              <TableCell key={i} align="right" className={classes.cell}>
                {_.get(formattedCalculatedValues[i], "rentalIncome", "–")}
              </TableCell>
            ))}
          </TableRow>
          <TableRow id="operating-expense">
            <TableCell className={classes.header}>Operating Expense</TableCell>
            {_.map(_.range(0, 6), i => (
              <TableCell key={i} align="right" className={classes.cell}>
                {_.get(formattedCalculatedValues[i], "operatingExpense", "–")}
              </TableCell>
            ))}
          </TableRow>
          <TableRow id="noi">
            <TableCell className={classes.header}>NOI</TableCell>
            {_.map(_.range(0, 6), i => (
              <TableCell key={i} align="right" className={classes.cell}>
                {_.get(formattedCalculatedValues[i], "noi", "–")}
              </TableCell>
            ))}
          </TableRow>
          <TableRow id="depreciation">
            <TableCell className={classes.header}>Depreciation</TableCell>
            {_.map(_.range(0, 6), i => (
              <TableCell key={i} align="right" className={classes.cell}>
                {_.get(formattedCalculatedValues[i], "depreciation", "–")}
              </TableCell>
            ))}
          </TableRow>
          <TableRow id="taxes">
            <TableCell className={classes.header}>Taxes</TableCell>
            {_.map(_.range(0, 6), i => (
              <TableCell key={i} align="right" className={classes.cell}>
                {_.get(formattedCalculatedValues[i], "taxes", "–")}
              </TableCell>
            ))}
          </TableRow>
          <TableRow>
            <TableCell className={classes.header} />
            <TableCell colSpan={6}>&nbsp;</TableCell>
          </TableRow>
          <TableRow id="net-income">
            <TableCell className={classes.header}>Net Income</TableCell>
            {_.map(_.range(0, 6), i => (
              <TableCell key={i} align="right" className={classes.cell}>
                {_.get(formattedCalculatedValues[i], "netIncome", "–")}
              </TableCell>
            ))}
          </TableRow>
          <TableRow>
            <TableCell className={classes.header} />
            <TableCell colSpan={6}>&nbsp;</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.header}>
              Estimated Sale Price
            </TableCell>
            <TableCell colSpan={5} />
            <TableCell align="right" className={classes.cell}>
              {accounting.formatMoney(worksheet.estimatedSalePrice, "$")}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.header} />
            <TableCell colSpan={6}>&nbsp;</TableCell>
          </TableRow>
          <TableRow id="cashflows">
            <TableCell className={classes.header}>Cashflows</TableCell>
            {_.map(_.range(0, 6), i => (
              <TableCell key={i} align="right" className={classes.cell}>
                {_.get(formattedCalculatedValues[i], "cashflows", "–")}
              </TableCell>
            ))}
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}

RentalPurchaseValueWorksheetTable.propTypes = {
  worksheet: PropTypes.object
};

export default RentalPurchaseValueWorksheetTable;
