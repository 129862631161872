import React from "react";
import { useNavigate } from "react-router-dom";
import { Grid, Card, CardHeader, CardActionArea } from "@mui/material";
import { ImageSearch } from "@mui/icons-material";

function AcquisitionsCard() {
  const navigate = useNavigate();
  return (
    <Grid item xs={6} data-testid="acquisitions-card">
      <Card>
        <CardActionArea onClick={() => navigate("/acquisitions")}>
          <CardHeader avatar={<ImageSearch />} title="Acquisitions" />
        </CardActionArea>
      </Card>
    </Grid>
  );
}

export default AcquisitionsCard;
