export const p8Data = {
  P8_004N: {
    title: "African American",
    label:
      " !!Total:!!Population of one race:!!Black or African American alone",
    concept: "RACE",
    predicateType: "int",
    group: "P8",
    limit: 0,
    predicateOnly: true,
    color: "#7A306C",
    universe: "Total population"
  },
  P8_005N: {
    color: "#8E8DBE",
    title: "Native American",
    label:
      " !!Total:!!Population of one race:!!American Indian and Alaska Native alone",
    concept: "RACE",
    predicateType: "int",
    group: "P8",
    limit: 0,
    predicateOnly: true,
    universe: "Total population"
  },
  P8_002N: {
    title: "Total",
    label: " !!Total:!!Population of one race:",
    concept: "RACE",
    predicateType: "int",
    group: "P8",
    limit: 0,
    predicateOnly: true,
    universe: "Total population"
  },
  P8_003N: {
    color: "#A9E4EF",
    title: "White",
    label: " !!Total:!!Population of one race:!!White alone",
    concept: "RACE",
    predicateType: "int",
    group: "P8",
    limit: 0,
    predicateOnly: true,
    universe: "Total population"
  },
  P8_006N: {
    color: "#96F550",
    title: "Asian",
    label: "Annotation of  !!Total:!!Population of one race:!!Asian alone",
    concept: "RACE",
    predicateType: "string",
    group: "P8",
    limit: 0,
    predicateOnly: true,
    universe: "Total population"
  },
  P8_009N: {
    color: "#ee3333",
    title: "Two or More Races",
    label:
      "!!Total:!!Population of two or more races:!!Population of six races:",
    concept: "RACE",
    predicateType: "string",
    group: "P8",
    limit: 0,
    predicateOnly: true,
    universe: "Total population"
  }
};

export const p7Data = {
  P7_001N: {
    title: "Total",
    label: " !!Total races tallied:",
    concept: "HISPANIC OR LATINO ORIGIN BY RACE (TOTAL RACES TALLIED)",
    predicateType: "int",
    group: "P7",
    limit: 0,
    predicateOnly: true,
    universe: "Total races tallied"
  },
  P7_002N: {
    color: "#81F495",
    title: "Not Hispanic",
    label: " !!Total races tallied:!!Not Hispanic or Latino:",
    concept: "HISPANIC OR LATINO ORIGIN BY RACE (TOTAL RACES TALLIED)",
    predicateType: "int",
    group: "P7",
    limit: 0,
    predicateOnly: true,
    universe: "Total races tallied"
  },
  P7_009N: {
    color: "#FFA500",
    title: "Hispanic",
    label: " !!Total races tallied:!!Hispanic or Latino:",
    concept: "HISPANIC OR LATINO ORIGIN BY RACE (TOTAL RACES TALLIED)",
    predicateType: "int",
    group: "P7",
    limit: 0,
    predicateOnly: true,
    universe: "Total races tallied"
  },
  S1901_C01_012E: {
    title: "Median Income",
    label: "Households!!Estimate!!Median income (dollars)",
    concept:
      "INCOME IN THE PAST 12 MONTHS (IN 2016 INFLATION-ADJUSTED DOLLARS)",
    predicateType: "int",
    group: "S1901",
    limit: 0,
    predicateOnly: true
  }
};

export const incomeBrackets = {
  10000: {
    title: "<$10,000",
    color: "#67001f"
  },
  15000: {
    title: "$10,0000 - $14,999",
    color: "#b2182b"
  },
  25000: {
    title: "$15,000 - $24,999",
    color: "#d6604d"
  },
  35000: {
    title: "$25,000 - $34,999",
    color: "#f4a582"
  },
  50000: {
    title: "$35,000 - $49,999",
    color: "#fddbc7"
  },
  75000: {
    title: "$50,000 - $74,999",
    color: "#d1e5f0"
  },
  100000: {
    title: "$75,000 - $99,999",
    color: "#92c5de"
  },
  150000: {
    title: "$100,000 - $149,999",
    color: "#4393c3"
  },
  200000: {
    title: "$150,000 - $199,999",
    color: "#2166ac"
  },
  9999999999: {
    title: "$200,000 or more",
    color: "#053061"
  }
};

export const availableDemographics = { ...p8Data, ...p7Data };

export const summarizableDemographics = [
  "P8_004N",
  "P8_005N",
  "P8_003N",
  "P8_006N",
  "P7_009N",
  "P8_009N"
];
